export const CHANGE_SHOW_NAV = 'change_show_Nav';


export const AUTH_START = 'auth_start';
export const AUTH_SUCCESS = 'auth_success';
export const AUTH_FAIL = 'auth_fail';
export const AUTH_INITIATE_LOGOUT = 'auth_initiate_logout';
export const AUTH_LOGOUT = 'auth_logout';
export const AUTH_CHECK_TIMEOUT = 'auth_check_timeout';
export const AUTH_USER = 'auth_user';
export const AUTH_SET_EMAIL = 'auth_set_email';
export const AUTH_RESET_PASSWORD = 'auth_reset_password';
export const AUTH_CHECK_STATE = 'auth_check_state';


export const ACTIVITY_INIT = 'activity_init';
export const ACTIVITY_SET = 'activity_set';
export const ACTIVITY_SET_INDEX = 'activity_set_Index';
export const ACTIVITY_UPDATED_SUCCESSFULLY = 'activity_update_successfully';
export const ACTIVITY_UPDATED_LOADING = 'activity_update_loading';
export const ACTIVITY_SET_USER_ACTION = 'activity_set_action';
export const ACTIVITY_SET_RISK_USER_ACTION = 'activity_set_risk_action';
export const ACTIVITY_SET_MITIGATION_INDEX = 'activity_set_mitigations_index';
export const ACTIVITY_SET_ACTIVITY_INDEX = 'activity_set_activity_index';
export const ACTIVITY_SET_RISK_INDEX = 'activity_set_risk_index';
export const ACTIVITY_REQUIRE_APPROVED = 'activity_require_approved';
export const ACTIVITY_REQUEST = 'activity_request';
export const ACTIVITY_REQUEST_ERROR = 'activity_request_error';
export const ACTIVITY_REQUEST_COMPLETED = 'activity_request_completed';
export const ACTIVITY_REQUEST_UPDATE = 'activity_request_update';
export const ACTIVITY_SAVED = 'activity_saved';
export const ACTIVITY_SET_JOBID = 'activity_jobId';




export const ANNOTATION_INIT = 'annotation_init';
export const ANNOTATION_SET = 'annotation_set';
export const ANNOTATION_SET_SUCCESSFULLY = 'annotation_set_successfully';
export const ANNOTATION_ADD = 'annotation_add';
export const ANNOTATION_ADD_SUCCESSFULLY = 'annotation_add_successfully';
export const ANNOTATION_UPDATE = 'annotation_update';
export const ANNOTATION_UPDATE_SUCCESSFULLY = 'annotation_update_successfully';
export const ANNOTATION__UPDATE_POSITION = 'annotation_update_remove';
export const ANNOTATION_UPDATE_POSITION_SUCCESSFULLY = 'annotation_update_position_successfully';
export const ANNOTATION_REMOVE = 'annotation_position_remove';
export const ANNOTATION_REMOVE_SUCCESSFULLY = 'annotation_remove_successfully';
export const ANNOTATION_REQUEST = 'annotation_request';
export const ANNOTATION_REQUEST_ERROR = 'annotation_request_error';


export const ASSESSMENT_INIT = 'assessment_init';
export const ASSESSMENT_REQUEST = 'assessment_request';
export const ASSESSMENT_REQUEST_COMPLETED = 'assessment_request_completed';
export const ASSESSMENT_REQUEST_UPDATE_COMPLETED = 'assessment_request_update_completed';
export const ASSESSMENT_REQUEST_ERROR = 'assessment_request_error';
export const ASSESSMENT_SET_SEARCH = 'assessment_set_search';
export const ASSESSMENT_SET_PAGE = 'assessment_set_page';
export const ASSESSMENT_SET_ROW_PER_PAGE = 'assessment_set_row_per_page';
export const ASSESSMENT_SET_FILTER = 'assessment_set_filter';
export const ASSESSMENT_SET_SELECTED = 'assessment_set_selected';
export const ASSESSMENT_SET_SHOW_MODAL_ADD ='assessment_set_show_modal_add';
export const ASSESSMENT_SET_SHOW_DATE_RANGE ='assessment_set_show_date_range';
export const ASSESSMENT_SET_DATE_RANGE_START = 'assessment_set_date_range_start';
export const ASSESSMENT_SET_DATE_RANGE_END = 'assessment_set_date_range_end';
export const ASSESSMENT_UPDATE_STORE = 'assessment_update_store';
export const ASSESSMENT_SAVE_COMMENT = 'assessment_save_comment';
export const ASSESSMENT_SET_PDF_URL = 'assessment_set_pdf_url';
export const ASSESSMENT_DOWNLOADING_FILE = 'assessment_downloading_file';
export const SET_LIST_RECOMMENDATION = 'set_list_recommendation';
export const SET_SAFE_BRIEFING_DOWNLOADING = 'set__safe_briefing_downloading';
export const SET_CHECK_FOR_UPDATES_RAMS = 'set_check_for_updates_rams';
export const ASSESSMENT_UPDATE = 'assessment_update';

export const ONTOLOGY_INIT = 'ontology_init';
export const ONTOLOGY_REQUEST = 'ontology_request';
export const ONTOLOGY_REQUEST_COMPLETED = 'ontology_request_complete';
export const ONTOLOGY_REQUEST_ERROR = 'ontology_request_error';
export const ONTOLOGY_SET_SEARCH = 'ontology_set_search';
export const ONTOLOGY_SET_ROW_PER_PAGE = 'ontology_set_row_per_page';
export const ONTOLOGY_SET_PAGE = 'ontology_set_page';
export const ONTOLOGY_ADD = 'ontology_add';
export const ONTOLOGY_EDIT = 'ontology_edit';
export const ONTOLOGY_UPDATE = 'ontology_update';
export const ONTOLOGY_UPDATE_CHILDREN = 'ontology_update_children';
export const ONTOLOGY_REMOVE = 'ontology_remove';
export const ONTOLOGY_SORT = 'ontology_sort';


export const USERS_INIT = 'users_init';
export const USERS_SET_SEARCH = 'users_set_search';
export const USERS_SET_ROW_PER_PAGE = 'users_set_row_per_page';
export const USERS_SET_PAGE = 'users_set_page';
export const USERS_SET_FILTER = 'users_set_filter';
export const USERS_ADD = 'users_add';
export const USERS_EDIT = 'users_edit';
export const USERS_UPDATE = 'users_update';
export const USERS_REMOVE = 'users_remove';
export const USERS_SORT = 'users_sort';
export const USER_REQUEST = 'users_request';
export const USER_REQUEST_COMPLETED = 'users_request_completed';
export const USER_REQUEST_ERROR = 'users_request_error';
export const USER_SET_CLIENTS = 'users_set_clients';




export const CLIENTS_INIT = 'clients_init';
export const CLIENTS_SET_SEARCH = 'clients_set_search';
export const CLIENTS_SET_ROW_PER_PAGE = 'clients_set_row_per_page';
export const CLIENTS_SET_PAGE = 'clients_set_page';
export const CLIENTS_SET_FILTER = 'clients_set_filter';
export const CLIENTS_ADD = 'clients_add';
export const CLIENTS_EDIT = 'clients_edit';
export const CLIENTS_UPDATE = 'clients_update';
export const CLIENTS_REMOVE = 'clients_remove';
export const CLIENTS_SORT = 'clients_sort';
export const CLIENTS_REQUEST = 'clients_request';
export const CLIENTS_REQUEST_COMPLETED = 'clients_request_completed';
export const CLIENTS_REQUEST_ERROR = 'clients_request_error';


export const ADMIN_INIT = 'administrators_init';
export const ADMIN_REQUEST = 'administrators_request';
export const ADMIN_REQUEST_COMPLETED = 'administrators_request_completed';
export const ADMIN_REQUEST_ERROR = 'administrators_request_error';
export const ADMIN_SET_SEARCH = 'administrators_set_search';
export const ADMIN_SET_ROW_PER_PAGE = 'administrators_set_row_per_page';
export const ADMIN_SET_PAGE = 'administrators_set_page';
export const ADMIN_SET_FILTER = 'administrators_set_filter';
export const ADMIN_ADD = 'administrators_add';
export const ADMIN_EDIT = 'administrators_edit';
export const ADMIN_UPDATE = 'administrators_update';
export const ADMIN_REMOVE = 'administrators_remove';
export const ADMIN_SORT = 'administrators_sort';



export const SHOW_TOAST = 'show_toast';
export const HIDE_TOAST = 'hide_toast';


export const DASHBOARD_GET_TOTAL = 'dashboard_get_total';
export const DASHBOARD_GET_TOTAL__COMPLETED = 'dashboard_get_total_complete';
export const DASHBOARD_GET_TOTAL__ERROR = 'dashboard_get_total_error';


export const SET_ERROR = 'setError';
