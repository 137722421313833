import React, {useState, useRef} from 'react';
import {withRouter} from 'react-router-dom';
import logo from './../../../assets/imgs/intuety-logo.svg';
import {BackdropLoading, ValidatorInput} from "../../components";
import {ValidatorForm} from 'react-material-ui-form-validator';
import {connect} from "react-redux";
import {login, changePassword} from "../../redux/actions";

const Login = (props) => {

    const [isLogin, setIsLogin] = useState(true);
    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const emailRef = useRef();
    const passwordRef = useRef();


    const handleSubmit = () => {

        if (isLogin)
            props.login(formData);
        else
            props.changePassword(formData);

    }

    const handleEmail = (event) => {
        setFormData({...formData, email: event.target.value});
    }


    const handleBlur = event => {
        const {name, value} = event.target;
        if (name == "email") {
            emailRef.current.validate(value, true);
        }

        if (name == "password") {
            passwordRef.current.validate(value, true);
        }
    }


    return (

        <div className="login-wrap-wrap">

            {<BackdropLoading isLoading={props.isLoading}/>}
            <div className="login-wrap">
                <ValidatorForm className="login-box fade-in"
                               onSubmit={handleSubmit}
                               instantValidate={false}
                >


                    <img src={logo} className="App-logo" alt="logo"/>
                    <h4>{isLogin ? 'Log into Intuety' : 'Reset your password?'}</h4>

                    {!isLogin &&

                    <p className="reset-password-text">Enter your email address and we’ll send you instructions on how
                        to reset your password.</p>
                    }
                    <ValidatorInput
                        ref={emailRef}
                        placeholder={"Email"}
                        value={formData.email}
                        onChange={handleEmail}
                        className="custom-input input-login"
                        type='email'
                        name='email'
                        onBlur={handleBlur}
                        validators={['required']}
                        validators={["isEmail", "required"]}
                        errorMessages={["email is not valid", "this field is required"]}
                    />


                    {isLogin &&
                    <ValidatorInput
                        ref={passwordRef}
                        placeholder={"Password"}
                        value={formData.password}
                        onChange={(event) => setFormData({...formData, password: event.target.value})}
                        type={"password"}
                        name={"password"}
                        className="custom-input input-login"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        onBlur={handleBlur}
                    />
                    }

                    <button
                        type={"submit"}
                        className="btn-login btn-primary"
                    >
                        {(isLogin) ? 'Log In' : 'Reset Password'}
                    </button>

                    <a
                        className="link-reset-password"
                        onClick={(event) => {
                            event.preventDefault();
                            setIsLogin(!isLogin);
                        }}>

                        {isLogin ? 'Forgot Password?' : 'Back to Log In'}
                    </a>
                </ValidatorForm>
            </div>

        </div>
    );
}
const mapStateToProps = ({auth}) => {
    const isLoading = auth.loading;

    return {isLoading};
}


export default connect(mapStateToProps, {login, changePassword})(withRouter(Login))