import {
    ACTIVITY_INIT,
    ACTIVITY_SET_INDEX,
    ACTIVITY_REQUEST_COMPLETED,
    ACTIVITY_REQUEST_ERROR,
    ACTIVITY_REQUEST_UPDATE,
    ACTIVITY_REQUEST,
    ACTIVITY_REQUIRE_APPROVED,
    ACTIVITY_SET_MITIGATION_INDEX,
    ACTIVITY_SET_RISK_INDEX,
    ACTIVITY_SET_ACTIVITY_INDEX,
    ACTIVITY_SET_USER_ACTION,
    ACTIVITY_SAVED,
    ACTIVITY_UPDATED_SUCCESSFULLY,
    ACTIVITY_UPDATED_LOADING,
    ACTIVITY_SET_JOBID,
    ACTIVITY_SET,
    ASSESSMENT_UPDATE_STORE,
    ACTIVITY_SET_RISK_USER_ACTION,

} from "../constants";
import {arrayToObject, userRole} from "../../utils/funcAux";
import axios from "../../utils/axios-aurora";
import {handleError} from "./handleHttpError";
import {toastr} from "react-redux-toastr";
import cookie from 'js-cookie';
import message from "../../utils/messages";

export function initActivities() {

    return (dispatch) => {

        dispatch(
            {
                type: ACTIVITY_INIT,
            });
    }
}


export function getActivity() {

    return (dispatch, getState) => {

        const jobId = cookie.get("jobIdSelected");

        if (!jobId) {
            return;
        }


        dispatch(startRequest());

        const {email, role} = getState().auth;

        let url = `author/riskAssessments/restructured?username=` + email + "&jobID=" + jobId;
        if (role === userRole.approver)
            url = `approver/riskAssessments/restructured?username=` + email + "&jobID=" + jobId;

        axios.get(url)
            .then(res => {

                dispatch(setActivities(res.data));

            })
            .catch(function (error) {

                // toastr.error('Error','Error to get data!' );
                dispatch(requestError());

            });
    }
}

const setActivities = (result) => {
    return {
        type: ACTIVITY_SET,
        payload: result.jsonAssessmets ? result.jsonAssessmets : result,
    }
}

export function finishedAndSelectPdf() {

    return (dispatch) => {

        const riskAssessmentID = cookie.get("riskAssessmentID");
        if (riskAssessmentID)
            dispatch(
                {
                    type: ASSESSMENT_UPDATE_STORE,
                    payload: {status: 90, riskAssessmentID: riskAssessmentID}
                }
            )

    }
}


export function updateActivities() {


    return (dispatch, getState) => {

        const {email, role} = getState().auth;

        dispatch(startRequest())

        let url = "author/riskAssessments?username=" + email;
        if (role === userRole.approver)
            url = 'approver/riskAssessements?username=' + email;

        axios.get(url)
            .then(res => {

                dispatch(updateActivitiesSuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                // toastr.error('Error','Error to get data!' );
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }
}


const requestActivitiesSuccessfully = (value) => {

    const val = arrayToObject(value, 'riskAssessmentID');

    return {
        type: ACTIVITY_REQUEST_COMPLETED,
        payload: val,
    }
}


const updateActivitiesSuccessfully = (value) => {

    const val = arrayToObject(value, 'riskAssessmentID');

    return {
        type: ACTIVITY_REQUEST_UPDATE,
        payload: val,
    }
}


const requestError = () => {
    return {
        type: ACTIVITY_REQUEST_ERROR,
    }
}


export function startRequest() {
    return {
        type: ACTIVITY_REQUEST,
    }
}

const getNextElement = (listActivities, assessmentIndex, riskIndex, index) => {

    let i, j, k;
    for (i = assessmentIndex; i < listActivities.length; i++) {
        let _currentActivity = listActivities[i];

        if (!_currentActivity.intuetyToProcess)
            continue;
        if (i != assessmentIndex) {

            const _currRiskIndex = getNextRiskToprocessing(_currentActivity);
            const _currMitIndex = getNextMitigationToprocessing(_currentActivity, _currRiskIndex);


            return {
                assessmentIndex: i,
                riskIndex: _currRiskIndex,
                index: _currMitIndex,
                lastActivityIndex: isLastActivity(isLastActivity, i),
            }
        }


        for (j = riskIndex; j < _currentActivity.Risks.length; j++) {
            let _currentRisk = _currentActivity.Risks[j];
            let _currentMitigations = _currentActivity.Risks[j].Mitigations;

            if (_currentRisk.numOfMitigationsWhichRequireApproval === 0)
                continue;


            if (j != riskIndex && _currentRisk.intuetySuggestion !== "none")
                return {
                    assessmentIndex: i,
                    riskIndex: j,
                    index: -1,
                    lastRiskIndex: isLastElementRisk(_currentActivity, j),
                    lastMitigationsIndex: _currentMitigations.length == 0 ? true : false,
                }


            for (k = (j != riskIndex) ? 0 : index; k < _currentMitigations.length; k++) {


                let _currentMitigation = _currentMitigations[k];
                if (k == -1)
                    continue;

                if (_currentMitigation.intuetySuggestion === "none")
                    continue;


                if (k != index || j != riskIndex) {


                    return {
                        assessmentIndex: i,
                        riskIndex: j,
                        index: k,
                        lastMitigationsIndex: isLastElement(_currentMitigations, k),
                    }
                }

            }
        }
    }


    return {
        assessmentIndex: i,
        riskIndex: j,
        index: k,
        lastActivityIndex: true,
        lastRiskIndex: true,
        lastMitigationsIndex: true,
        isProcessed: true,
    }

}


const isLastActivity = (list, index) => {

    if (!list || list.length == 0 || index === list.length - 1)
        return true;

    for (let i = index + 1; i < list.length; i++) {
        if (list[i].intuetyToProcess)
            return false;
    }

    return true;
}


const isLastElement = (list, index) => {

    if (!list || list.length == 0 || index === list.length - 1)
        return true;

    for (let i = index + 1; i < list.length; i++) {
        if (list[i].intuetySuggestion != "none")
            return false;
    }

    return true;
}


const isLastElementRisk = (list, index) => {

    if (!list || list.length == 0 || index === list.length - 1)
        return true;

    for (let i = index + 1; i < list.length; i++) {
        // if(list[i].intuetySuggestion!="none")
        if (list[i].numOfMitigationsWhichRequireApproval !== 0)
            return false;
    }

    return true;
}


const getNextRiskToprocessing = (activity) => {

    const list = activity.Risks;
    if (!list || list.length == 0)
        return 0;

    for (let i = 0; i < list.length; i++) {
        if (list[i].numOfMitigationsWhichRequireApproval > 0) {
            return i;
        }
    }


    return 0;
}

const getNextMitigationToprocessing = (activity, index) => {


    if (!activity || !activity.Risks || !activity.Risks[index] || !activity.Risks[index].Mitigations)
        return 0;

    const list = activity.Risks[index].Mitigations;

    for (let i = 0; i < list.length; i++) {

        if (list[i].intuetySuggestion !== "none") {
            return (i === 0 && activity.Risks[index].intuetySuggestion !== "none") ? -1 : i;
        }


    }


    return 0;
}

export function setRiskAction(action, key) {


    return (dispatch, getState) => {

        const {listActivities, assessmentIndex, riskIndex, index, requireApproved} = getState().activities;

        let _currentActivity = listActivities[assessmentIndex];
        let _currentRisk = _currentActivity.Risks[riskIndex];
        const jobId = cookie.get("jobIdSelected");

        if (action === "accept") {

            const nextEl = getNextElement(listActivities, assessmentIndex, riskIndex, index);
            dispatch(
                {
                    type: ACTIVITY_SET_INDEX,
                    payload: {...nextEl, requireApproved: requireApproved + 1}
                }
            );
            dispatch(setRiskUserAction(action));
        } else {
            //action when we reject the risk activity, increase the risk index, reset the mitigation index and increase
            // the number of require checked


            const mitigations = _currentRisk.Mitigations;
            const allRequests = [];
            const url = "intuetyApi/feedBackIngestion";

            for (let i = 0; i < mitigations.length; i++) {
                allRequests.push(
                    axios.post(url,
                        {
                            "activity": _currentActivity.activity,
                            "risk": _currentRisk.risk,
                            "mitigation": mitigations[i].description,
                            "vote": action === "accept" ? 1 : -1,
                            "jobId": jobId
                        })
                )
            }
            dispatch(setUpdateLoading(key));


            Promise.all(allRequests)
                .then(res => {

                    dispatch(setUpdateLoading(false));
                    dispatch(setUserAction(action));
                    dispatch(setRiskUserAction(action));
                    dispatch(updateActivitiesIndexRisk(getState().activities));
                    // axios.defaults.baseURL = 'api/v1';

                })
                .catch(function (error) {
                    console.log(error);
                    dispatch(setUpdate(key, true));
                    toastr.error(message.updateMitigation);
                    // axios.defaults.baseURL = 'api/v1';
                    dispatch(setUpdateLoading(false));
                });

        }


    }


}


export function setMitigationAction(action, key, lastMitigationsIndex) {


    return (dispatch, getState) => {


        let url = "intuetyApi/feedBackIngestion";
        const jobId = cookie.get("jobIdSelected");

        const {listActivities, assessmentIndex, riskIndex, index} = getState().activities;

        const _currentActivity = listActivities[assessmentIndex];
        const _currentRisk = _currentActivity.Risks[riskIndex];
        const _mitigations = _currentRisk.Mitigations[index];


        dispatch(setUpdateLoading(key));
        axios.post(url,
            {
                "activity": _currentActivity.activity,
                "risk": _currentRisk.risk,
                "mitigation": _mitigations.description,
                "vote": action === "accept" ? 1 : -1,
                "jobId": jobId
            })
            .then(res => {

                dispatch(setUpdate(key, false));
                dispatch(setUpdateLoading(false));

                setTimeout(() => {

                    const activitiesReducer = getState().activities;
                    dispatch(setUserAction(action));
                    dispatch(updateActivitiesIndexMitiligation(activitiesReducer));
                    dispatch(setUpdate(null, false));

                }, lastMitigationsIndex ? 3000 : 100)

            })
            .catch(function (error) {
                console.log(error);
                dispatch(setUpdate(key, true));
                dispatch(setUpdateLoading(false));
                toastr.error(message.updateMitigation);
                // axios.defaults.baseURL = 'api/v1';
            });

    }

}


const setUpdate = (id, isError) => {

    return {
        type: ACTIVITY_UPDATED_SUCCESSFULLY,
        payload: {updateId: id, updateError: isError,}
    }
}

const setUpdateLoading = (value) => {
    return {
        type: ACTIVITY_UPDATED_LOADING,
        payload: {loading: value}
    }
}


const setUserAction = (action) => {
    return {
        type: ACTIVITY_SET_USER_ACTION,
        payload: {action: action}
    }
}


const updateActivitiesIndexMitiligation = (activitiesReducer) => {
    const {listActivities, assessmentIndex, riskIndex, index, requireApproved} = activitiesReducer;

    const nextEl = getNextElement(listActivities, assessmentIndex, riskIndex, index);

    return {
        type: ACTIVITY_SET_INDEX,
        payload: {...nextEl, requireApproved: requireApproved + 1,}
    }
}

const updateActivitiesIndexRisk = (activitiesReducer) => {


    const {listActivities, assessmentIndex, riskIndex, requireApproved} = activitiesReducer;

    let _currentActivity = listActivities[assessmentIndex];
    let _currentRisk = _currentActivity.Risks[riskIndex];

    const nextEl = getNextElement(listActivities, assessmentIndex, riskIndex, _currentRisk.Mitigations.length - 1);

    return {
        type: ACTIVITY_SET_INDEX,
        payload: {
            ...nextEl,
            requireApproved: requireApproved + _currentRisk.numOfMitigationsWhichRequireApproval + 1,
        }
    }
}


export function setJobIdSelected(jobId) {

    cookie.set("jobIdSelected", jobId, {sameSite: 'lax', secure: true});
    return (
        {
            type: ACTIVITY_SET_JOBID,
            payload: {jobIdSelected: jobId}
        }
    );

}

function setRiskUserAction(action) {


    return (
        {
            type: ACTIVITY_SET_RISK_USER_ACTION,
            payload: {action}
        }
    );

}

export function saveForm(history) {


    return (dispatch, getState) => {

        const {listActivities, activitiesWithSuggestions} = getState().activities;
        const {email} = getState().auth;
        const jobId = cookie.get("jobIdSelected");
        dispatch(startRequest());

        axios.post(
            "author/riskAssessments/store/restructured?username=" + email + "&jobID=" + jobId,
            {
                Activities: listActivities,
                numOfActivitiesWhichRequireApproval: activitiesWithSuggestions
            }
        )
            .then(res => {

                if (history) {
                    dispatch(updateStatus(90, false))
                    history.push('/summary');
                } else
                    toastr.success('Saved', message.savedRiskAssessmentFrom);

                dispatch(
                    {
                        type: ACTIVITY_SAVED,
                    }
                );
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', 'Erroron save data!');
                dispatch(requestError());

            });
    }

}


export function updateStatus(status, loading) {

    return (dispatch, getState) => {

        const {email, role} = getState().auth;
        const jobId = cookie.get("jobIdSelected");
        if (loading)
            dispatch(startRequest());

        const url = `${role === userRole.author ? 'author' : 'approver'}/riskAssessments/updateState?username=` + email;

        axios.post(url,
            {
                jobID: jobId,
                stateID: status
            }
        )
            .then(res => {

                dispatch(requestError());
                dispatch(updateStatusStore(jobId, status));
                if (loading) {
                    if (status == 90)
                        toastr.success('Submit', message.submittedRiskAssessmentSuccess);
                    if (status == 70)
                        toastr.success('Submit', message.approvedSubmittedRiskAssessmentSuccess);

                    if (status == 80)
                        toastr.success('Submit', message.rejectedSubmittedRiskAssessmentSuccess);
                }

            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.submittedRiskAssessmentError);
                dispatch(requestError());

            });
    }

}


export function updateStatusStore(jobId, status) {
    return (
        {
            type: ASSESSMENT_UPDATE_STORE,
            payload: {jobId: jobId, status: status}
        }
    )
}


export function runCron1() {

    return (dispatch, getState) => {

        dispatch(startRequest());

        axios.defaults.baseURL = '';
        axios.get("intuetyCron/uploadRiskAssessments")
            .then(res => {

                toastr.success('Success', 'Cron 1 has been run with success!');
                dispatch(requestError());
                axios.defaults.baseURL = 'api/v1';
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', 'Error on save data!');
                dispatch(requestError());
                axios.defaults.baseURL = 'api/v1';

            });

    }

}


export function runCron2() {

    return (dispatch, getState) => {


        dispatch(startRequest());

        axios.defaults.baseURL = '';
        axios.get("intuetyCron/fetchStatusExtractRecommendations")
            .then(res => {

                toastr.success('Success', 'Cron 2 has been run with success!');
                dispatch(requestError());
                axios.defaults.baseURL = 'api/v1';
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', 'Error on save data!');
                dispatch(requestError());
                axios.defaults.baseURL = 'api/v1';

            });


    }

}


export function setActivityFilter(type, value) {
    // const _value = value==='All'?'':value;

    if (type === 'assessmentIndex') {

        return {
            type: ACTIVITY_SET_ACTIVITY_INDEX,
            payload: {assessmentIndex: value}
        }
    }

    if (type === 'riskIndex') {

        return {
            type: ACTIVITY_SET_RISK_INDEX,
            payload: {riskIndex: value}
        }
    }

    if (type === 'index') {

        return {
            type: ACTIVITY_SET_MITIGATION_INDEX,
            payload: {index: value}
        }
    }

    if (type === 'requireApproved')
        return {
            type: ACTIVITY_REQUIRE_APPROVED,

        }
}
