import {
    ASSESSMENT_INIT,
    ASSESSMENT_SET_SEARCH,
    ASSESSMENT_SET_FILTER,
    ASSESSMENT_SET_PAGE,
    ASSESSMENT_SET_ROW_PER_PAGE,
    ASSESSMENT_REQUEST_COMPLETED,
    ASSESSMENT_REQUEST_UPDATE_COMPLETED,
    ASSESSMENT_REQUEST,
    ASSESSMENT_SET_SHOW_DATE_RANGE,
    ASSESSMENT_SET_DATE_RANGE_START,
    ASSESSMENT_SET_SHOW_MODAL_ADD,
    ASSESSMENT_SET_DATE_RANGE_END,
    ASSESSMENT_REQUEST_ERROR,
    AUTH_LOGOUT, SET_SAFE_BRIEFING_DOWNLOADING, ASSESSMENT_UPDATE,
    ASSESSMENT_SET_SELECTED, SET_LIST_RECOMMENDATION, SET_CHECK_FOR_UPDATES_RAMS,
    ASSESSMENT_UPDATE_STORE, ASSESSMENT_SAVE_COMMENT, ASSESSMENT_SET_PDF_URL, ASSESSMENT_DOWNLOADING_FILE,
} from "../constants";

import dataJson from "../../utils/assessment";

const status = [
    {key: 'All', value: 'All'},
    {key: '10', value: 'Processing'},
    {key: '30', value: 'Processed, ready to proceed'},
    {key: '90', value: 'Complete'},
    {key: '50', value: 'Pending'},
    {key: '80', value: 'Rejected'},
    {key: '70', value: 'Approved'},
    {key: '100', value: 'Couldn\'t be processed'},
];

const initialState = {

    listAssessment: null,
    names: [],
    status: status,
    textSearch: '',
    dataRangeStart: null,
    dataRangeEnd: null,
    filterName: 'All',
    filterStatus: 'All',
    rowsPerPage: 10,
    currentPage: 1,
    loading: false,
    list: [],

    showDateRange: false,
    dateRangeStart: null,
    dateRangeEnd: new Date(),

    showAddAssessmentModal: false,
    assessmentSelected: null,

    methodStatementPdfURL: null,
    downloadingFile: false,
    listRecommendedBriefings: [],
    safetyBriefingsDownloading: null,
    isCheckForUpdates: null,


}

export default function reducer(state = initialState, {type, payload}) {

    switch (type) {

        case ASSESSMENT_INIT: {

            return {
                ...state,
                listAssessment: dataJson,
                // names: getNames(dataJson),
            }
        }

        case ASSESSMENT_REQUEST: {

            return {
                ...state,
                loading: true,
            }
        }

        case ASSESSMENT_REQUEST_COMPLETED: {

            return {
                ...state,
                listAssessment: payload,
                names: getAuthorNames(payload),
                loading: false,
                // names: getNames(dataJson),
            }
        }

        case ASSESSMENT_REQUEST_UPDATE_COMPLETED: {


            let objectList = state.listAssessment;
            if (payload)
                payload.forEach(el => {
                        objectList[el.riskAssessmentID] = el;
                    }
                );

            return {
                ...state,
                listAssessment: objectList,
                loading: false,
                // names: getNames(dataJson),
            }
        }

        case ASSESSMENT_SET_SEARCH: {

            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_FILTER: {

            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_SELECTED: {

            return {
                ...state,
                ...payload,
            }

        }

        case ASSESSMENT_DOWNLOADING_FILE: {

            return {
                ...state,
                ...payload,
            }

        }

        case ASSESSMENT_UPDATE_STORE: {

            const localAssessmentSel = state.assessmentSelected;
            let objectList = state.listAssessment;

            if (localAssessmentSel) {
                localAssessmentSel.stateID = "" + payload.status;
                objectList[localAssessmentSel.riskAssessmentID] = localAssessmentSel;
            }
            if (payload.riskAssessmentID) {
                let obj = objectList[payload.riskAssessmentID];
                if (obj) {
                    obj.stateID = "" + payload.status;
                    objectList[payload.riskAssessmentID] = obj;
                }

            }

            return {
                ...state,
                assessmentSelected: localAssessmentSel,
                listAssessment: objectList,
            }
        }


        case ASSESSMENT_SET_SHOW_DATE_RANGE: {

            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_DATE_RANGE_START: {

            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_DATE_RANGE_END: {

            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_PAGE: {

            return {
                ...state,
                currentPage: payload,
            }
        }

        case ASSESSMENT_SET_ROW_PER_PAGE: {
            return {
                ...state,
                ...payload,
            }
        }

        case ASSESSMENT_SET_SHOW_MODAL_ADD: {
            return {
                ...state,
                ...payload,
            }
        }
        case ASSESSMENT_SET_PDF_URL: {
            return {
                ...state,
                ...payload,
            }
        }

        case SET_LIST_RECOMMENDATION: {
            return {
                ...state,
                ...payload,

            }
        }

        case SET_SAFE_BRIEFING_DOWNLOADING: {
            return {
                ...state,
                ...payload,

            }
        }

        case SET_CHECK_FOR_UPDATES_RAMS: {

            return {
                ...state,
                ...payload,
            }
        }


        case ASSESSMENT_REQUEST_ERROR: {
            return {
                ...state,
                loading: false,
                downloadingFile: false,
            }
        }

        case ASSESSMENT_SAVE_COMMENT: {

            const text = JSON.stringify({...payload});

            return {
                ...state,
                loading: false,
                assessmentSelected: {...state.assessmentSelected, summaryComments: text}
            }
        }

        case AUTH_LOGOUT:
            return {
                ...initialState,
            };


        default: {
            return {
                ...state
            };
        }
    }
}


const getAuthorNames = (listObject, noAll) => {

    let collection = noAll ? [] : ["All"];

    if (!listObject)
        return collection;

    const list = Object.values(listObject);

    list.forEach((item) => {

        if (collection.filter(value => value === item.authorDetails.fullName).length === 0)
            collection.push(item.authorDetails.fullName);

    });

    return collection;
}



