import {createSelector} from 'reselect'
import {compareValues} from "../../utils/funcAux";


export const getAssessments = state => {
    if (!state.assessments.listAssessment)
        return [];

    const list = Object.values(state.assessments.listAssessment);
    return list.sort(compareValues('riskAssessmentID', 'desc'));
}

export const getStatus = state => state.assessments.status;

export const getFilterStatus = state => state.assessments.filterStatus;

export const getFilterName = state => state.assessments.filterName;

export const getKeyword = state => state.assessments.textSearch;

export const getRowsPerPageAssessment = state => state.assessments.rowsPerPage;

export const getShowDateRange = state => state.assessments.showDateRange;

export const getDateRangeStart = state => state.assessments.dateRangeStart;

export const getDateRangeEnd = state => state.assessments.dateRangeEnd;

export const isLoadingAssessment = state => state.assessments.loading;

export const getAssessmentSelected = state => state.assessments.assessmentSelected;

export const getMethodStatementPdfURL = state => state.assessments.methodStatementPdfURL;

export const isDownloadingFile = state => state.assessments.downloadingFile;

export const getListRecommendationToDownload = state => state.assessments.listRecommendedBriefings;

export const getSafetyBriefingsDownloading = state => state.assessments.safetyBriefingsDownloading;

export const getIsCheckForUpdates = state => state.assessments.isCheckForUpdates;
export const getCurrentPage = state => state.assessments.currentPage;

export const getAssessmentSelectedComment = createSelector(
    [getAssessmentSelected],
    (_assessmentSelected) => {

        if (!_assessmentSelected || !_assessmentSelected.summaryComments)
            return ''
        return JSON.parse(_assessmentSelected.summaryComments).text;
    }
);


export const getStateID = state => state.assessments.assessmentSelected ? state.assessments.assessmentSelected.stateID : 0;


export const getQualityScore = state => {

    try {

        if (!state.assessments.assessmentSelected || !state.assessments.assessmentSelected.qualityScore)
            return 0;
        return state.assessments.assessmentSelected.qualityScore.toFixed(0)
    } catch (e) {
        return 0;
    }

}


export const getListReference = createSelector(
    [getAssessments],
    (list,) => {
        let collection = [];
        list && list.forEach((item) => {

            if (collection.filter(value => value === item.reference.toString()).length === 0)
                collection.push(item.reference.toString());
        });
        return collection;
    }
);


export const getListLocation = createSelector(
    [getAssessments],
    (list,) => {
        let collection = [];
        list && list.forEach((item) => {

            if (collection.filter(value => value === item.project).length === 0)
                collection.push(item.project);
        });
        return collection;
    }
);

export const getListTasks = createSelector(
    [getAssessments],
    (list,) => {
        let collection = [];
        list && list.forEach((item) => {
            if (collection.filter(value => value.taskID === item.taskDetails.taskID).length === 0)
                collection.push({task: item.taskDetails.task, taskID: item.taskDetails.taskID});
        });
        return collection;
    }
);


export const getVisibleAssessmentFilteredBYDate = createSelector(
    [getAssessments, getDateRangeStart, getDateRangeEnd],
    (listAssessments, dateRangeStart, dateRangeEnd) => {

        if (!dateRangeStart)
            return listAssessments

        return listAssessments && listAssessments.filter(item => item.created &&
            new Date(item.created) >= dateRangeStart && new Date(item.created) <= new Date(dateRangeEnd.getTime() + ((24 * 3600 * 1000) - 1)))
    }
)


export const getVisibleAssessmentFilteredBYStatus = createSelector(
    [getVisibleAssessmentFilteredBYDate, getFilterStatus],
    (listAssessments, statusFilterValue) => {


        if (statusFilterValue === 'All')
            return listAssessments

        if (statusFilterValue === '10')
            return listAssessments && listAssessments.filter(item => item.stateID === '10' || item.stateID === '20' || (item.stateID === '30' && item.methodStatementStateID != '40'))


        if (statusFilterValue === '30')
            return listAssessments && listAssessments.filter(item => item.stateID === '30' && item.methodStatementStateID == '40')


        return listAssessments && listAssessments.filter(item => item.stateID === statusFilterValue)
    }
)

export const getVisibleAssessmentFilteredBYName = createSelector(
    [getVisibleAssessmentFilteredBYStatus, getFilterName],
    (listAssessments, filterValue) => {
        if (filterValue.toLowerCase() === 'all')
            return listAssessments;

        return listAssessments && listAssessments.filter(item => (item && item.authorDetails && item.authorDetails.fullName) && item.authorDetails.fullName.toLowerCase() === filterValue.toLowerCase())

    }
)

export const getVisibleAssessmentFilteredByKeyword = createSelector(
    [getVisibleAssessmentFilteredBYName, getKeyword],
    (visibleAssessment, keyword) => {
        return visibleAssessment && visibleAssessment.filter(assessments =>
            assessments.reference.toString().indexOf(keyword) > -1 ||
            (assessments.approverDetails && assessments.approverDetails.fullName && assessments.approverDetails.fullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (assessments.taskDetails && assessments.taskDetails.task.toLowerCase().indexOf(keyword.toLowerCase()) > -1) ||
            (assessments.project && assessments.project.toLowerCase().indexOf(keyword.toLowerCase()) > -1)
        )
    }
)


export const getTotalPagesAssessment = createSelector(
    [getVisibleAssessmentFilteredByKeyword, getRowsPerPageAssessment],
    (list, rowsPerPage) =>
        list && Math.ceil(list.length / rowsPerPage)
)

export const getUnapprovedRAMS = createSelector(
    [getVisibleAssessmentFilteredByKeyword, getRowsPerPageAssessment, getCurrentPage],
    (list, rowsPerPage, currentPage) => {
        if (!list)
            return []
        const _list = list.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage)

        return _list.filter(item => item.stateID != "70" && item.stateID != "80" && item.stateID != "100")

    }
)

export const getLastRAMSID = createSelector(
    [getAssessments],
    (list) => {
        let lisId = null;

        if (list && list.length > 0) {
            lisId = list[0].riskAssessmentID
        }
        return lisId;
    }
)
