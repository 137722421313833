import React from 'react';
import {NavBar} from "../index";
import {connect} from "react-redux";

const Layout = (props) => {

    return (

        <div className={(props.menuOpen) ? "mainHome mainHome-open" : "mainHome"}>

            <div className="home-container">

                {props.children}

            </div>

            <aside className={(props.menuOpen) ? "nav-container nav-container-open" : "nav-container"}>
                <NavBar/>
            </aside>


        </div>
    );

}


const mapStateToProps = ({navBar,}) => {
    const {menuOpen} = navBar;
    return {menuOpen};
};

export default connect(mapStateToProps, null)(Layout);

