import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Header} from "../../components";
import Results from "./results/results";
import ControlMeasure from "./controlMeasure/controlMeasure";
import {connect} from "react-redux";
import {saveForm, updateStatus, setJobIdSelected, getActivity, finishedAndSelectPdf} from "../../redux/actions";
import {getMitigationsProgressBar, isMainLoading} from "../../redux/selectors";
import {useSelector, useDispatch} from 'react-redux';
import {BackdropLoading} from "../../components";
import cookie from "js-cookie";

const Activities = ({history, saveForm, updateStatus, isProcessed, location, finishedAndSelectPdf}) => {

    const [containerToShow, setContainerToShow] = useState('results');
    const dispatch = useDispatch();
    const _isLoading = useSelector(state => isMainLoading(state));
    const _containerToShow = cookie.get("containerToShow");

    useEffect(() => {
        if (location.jobId)
            dispatch(setJobIdSelected(location.jobId));     // setJobIdSelected(location.jobId);
        dispatch(getActivity());

        if (_containerToShow) {
            setContainerToShow(_containerToShow);
        }
    }, []);


    return (

        <div className="results-container">

            <Header
                title=""
                buttonRight={(containerToShow === "controlMeasure")}
                buttonRightText="SAVE PROGRESS"
                buttonRightFunc={() => saveForm()}
            />
            {<BackdropLoading isLoading={_isLoading}/>}
            {containerToShow === "results" && <Results/>}
            {containerToShow === "controlMeasure" && <ControlMeasure/>}

            <footer>
                {(containerToShow === 'results') && <>

                    <button
                        className="btn btn-secondary "
                        onClick={
                            () => {

                                history.push('/');
                                cookie.set("containerToShow", 'controlMeasure', {sameSite: 'lax', secure: true})
                            }
                        }
                    >BACK
                    </button>
                    <button
                        className="btn btn-primary"

                        onClick={
                            () => {
                                setContainerToShow('controlMeasure')
                                cookie.set("containerToShow", 'controlMeasure', {sameSite: 'lax', secure: true});
                            }
                        }

                    >PROCEED
                    </button>

                </>

                }


                {(isProcessed && containerToShow === 'controlMeasure') && <>

                    <button
                        className="btn btn-secondary "
                        onClick={
                            () => {
                                setContainerToShow('results')
                                cookie.set("containerToShow", 'controlMeasure', {sameSite: 'lax', secure: true})
                            }
                        }
                    >CANCEL
                    </button>
                    <button
                        className="btn btn-primary"

                        onClick={
                            () => {
                                saveForm(history);
                                finishedAndSelectPdf();

                                cookie.set("containerToShow", 'controlMeasure', {sameSite: 'lax', secure: true});
                            }
                        }

                    >FINISH
                    </button>
                </>
                }
            </footer>

        </div>
    );
}


const mapStateToProps = (state) => {


    const isProcessed = state.activities.isProcessed;
    const progressBarMitigations = getMitigationsProgressBar(state);
    // setJobIdSelected
    return {isProcessed, progressBarMitigations};
};


export default connect(mapStateToProps, {saveForm, finishedAndSelectPdf, updateStatus})(withRouter(Activities));


