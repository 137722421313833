import {createSelector} from 'reselect';
import {compareValues} from "../../utils/funcAux";


export const getClients = state => state.clients.listClients && Object.values(state.clients.listClients);

export const getRoles = state => state.clients.roles;

export const getFilterRole = state => state.clients.filterRole;

export const getFilterName = state => state.clients.filterName;

export const getFilterClient = state => state.clients.filterClient;

export const getKeyword = state => state.clients.textSearch;

export const getUserSort = state => state.clients.sort;

export const getRowsPerPageUser = state => state.clients.rowsPerPage;


export const getPageUser = state => state.clients.currentPage;


export const getNumberTotalClients = createSelector(
    [getClients],
    (listClients) => {

        if (!listClients)
            return 0

        return listClients.length;
    }
)


export const getVisibleClientsFilteredBYStatus = createSelector(
    [getClients, getFilterRole],
    (listClients, statusFilterValue) => {

        if (statusFilterValue === 'All')
            return listClients

        return listClients && listClients.filter(item => item.status === statusFilterValue)
    }
)


export const getVisibleClientsFilteredBYClient = createSelector(
    [getVisibleClientsFilteredBYStatus, getFilterClient],
    (listClients, filterValue) => {
        if (filterValue.toLowerCase() === 'all')
            return listClients

        return listClients;
    }
)


export const getVisibleClientsFilteredBYName = createSelector(
    [getVisibleClientsFilteredBYClient, getFilterName],
    (listClients, filterValue) => {
        if (!filterValue || filterValue.trim() === '')
            return listClients

        return listClients;
    }
)


export const getVisibleClientsFilteredByKeyword = createSelector(
    [getVisibleClientsFilteredBYStatus, getKeyword],
    (visibleClients, keyword) => {

        return visibleClients
            && visibleClients.filter(
                client =>
                    client.clientName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                    client.clientID.toString().toLowerCase().indexOf(keyword.toLowerCase()) > -1
            )
    }
)


export const getClientsSort = createSelector(
    [getVisibleClientsFilteredByKeyword, getUserSort],
    (listClients, sort) => {
        if (!sort.column) {
            return listClients;
        }
        return listClients.sort(compareValues(sort.column, sort.order))
    }
)


export const getTotalPagesClients = createSelector(
    [getClientsSort, getRowsPerPageUser],
    (list, rowsPerPage) =>
        list && Math.ceil(list.length / rowsPerPage)
)


export const getListClients = createSelector(
    [getClientsSort, getPageUser, getRowsPerPageUser],
    (list, page, rowsPerPage) =>
        list && list.slice((page - 1) * rowsPerPage, page * rowsPerPage)
)


export const getListClient = createSelector(
    [getClients],
    (list,) => {
        let collection = [];


        list && list.forEach((item) => {
            if (collection.filter(value => value.clientID === item.clientID).length === 0)
                collection.push({
                    "clientID": item.clientID,
                    "clientName": item.clientName
                })
        });
        return collection;
    }
);

