import cookie from "js-cookie";
import React from "react";
import {
    Route,
    Redirect
} from "react-router-dom";
import {connect} from "react-redux";
import {userRole} from "../utils/funcAux";

const DashboardRoute = ({role, children, ...rest}) => {


    return (
        <Route
            {...rest}
            render={({location}) =>

                role === userRole.admin ? (
                    children
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: {from: location}
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = ({auth}) => {
    const isAuthenticated = auth.isAuthenticated;
    const role = auth.role;

    return {isAuthenticated, role};
}

export default connect(mapStateToProps, {})(DashboardRoute);