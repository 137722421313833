import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';


const CustomSelect = ({list, value, handleChange, type, keyValue}) => {

    return (


        <Select
            className="customSelect"
            value={value}
            onChange={(event) => handleChange(type, event.target.value)}
            input={<Input/>}
            inputProps={{'aria-label': 'Without label'}}
        >


            {!keyValue && list.map((name) => (
                <MenuItem key={name} value={name}>
                    {name}
                </MenuItem>
            ))}


            {keyValue && list.map(({key, value}) => (
                <MenuItem key={key} value={key}>
                    {value}
                </MenuItem>
            ))}

        </Select>


    )
}

export default CustomSelect;