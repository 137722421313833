import React from 'react';
import {
    processing,
    processed,
    complete,
    pending,
    rejected,
    approved,
    triangleUp,
    triangleDown,
    exclamationCircle
} from "../../utils/icons";
import {CustomReactTooltip, ProgressBar} from "../index";
import Moment from "react-moment";
import cookie from "js-cookie";
import Skeleton from '@material-ui/lab/Skeleton';
import {useSelector} from "react-redux";
import {getAuthRole} from "../../redux/selectors";
import {userRole} from "../../utils/funcAux";

const ItemTable = (props) => {


    const {
        reference, project, taskDetails, approvalID, created, remarks, riskAssessmentID, initialQualityScore,
        stateID, qualityScore, job_id, totalMitigations, mitigationsAccepted, pdfDownloaded, methodStatementStateID,
        riskAssessmentApprovedByDetails, methodStatementPdfName, clientID, methodStatementUploaded
    } = props;
    const _userRole = useSelector(state => getAuthRole(state));

    let trend = "-";
    if (qualityScore > 0 && initialQualityScore > 0)
        trend = qualityScore - initialQualityScore;


    const handleMoveToSummary = () => {
        cookie.set("jobIdSelected", job_id, {sameSite: 'lax', secure: true});
        cookie.set("riskAssessmentID", riskAssessmentID, {sameSite: 'lax', secure: true});
        cookie.set("assessmentSelected", {...props}, {sameSite: 'lax', secure: true});
        cookie.set("assessmentSelectedPDFInfo", {
            taskDetails, riskAssessmentID, job_id, pdfDownloaded, methodStatementPdfName,
            methodStatementStateID, clientID, methodStatementUploaded, riskAssessmentApprovedByDetails
        }, {sameSite: 'lax', secure: true});
        props.history.push('/summary');


    }
    const isUnsubmitted = () => (_userRole === userRole.approver && (stateID === "90" || stateID === "30" || stateID === "20" || stateID === "10"));
    const isUnsubmittedAll = () => ((stateID === "90" || stateID === "30" || stateID === "20" || stateID === "10" || stateID === "100"));

    return (

        <tr style={{backgroundColor: (isUnsubmitted()) ? "rgba(234, 235, 235, 0.8)" : "white"}}>
            {/*<tr style={{backgroundColor:(_userRole === userRole.approver && (stateID==="90" || stateID==="30" || stateID==="20" || stateID==="10"))?"rgba(234, 235, 235, 0.8)":"white"}}>*/}
            <td className="ref">{reference}</td>
            <td className="location">{project}</td>
            <td className="project">{taskDetails.task ?? ''}</td>
            <td className="aproval">{approvalID === 0 ? '-' : riskAssessmentApprovedByDetails.fullName}</td>
            <td className="date">
                <span data-tip="Date & time completed">
                    <Moment format="DD/MM/YYYY HH:mm">
                        {created}
            </Moment>
                </span>

            </td>


            <td className="improvement">

                <span className={(totalMitigations <= 0 || isUnsubmittedAll()) ? "first-item" : "first-item exist"}
                      data-tip="Suggestions">{(totalMitigations <= 0 || isUnsubmittedAll()) ? '-' : totalMitigations}</span>

                {(mitigationsAccepted > 0 && !isUnsubmittedAll()) &&
                <span data-tip="Suggestions accepted" className="second-item exist">{`${mitigationsAccepted}`}</span>}
                {(mitigationsAccepted <= 0 || isUnsubmittedAll()) && <span className="second-item-empty">-</span>}

                <div className="third-item">

                    {(trend === "-" || isUnsubmittedAll()) &&
                    <span className="second-item-empty" data-tip="Trend %">{`-`}</span>}

                    {(trend > 0 && !isUnsubmittedAll()) &&
                    <span data-tip="Trend %">

                            <span className="bold">{`${trend.toFixed(0)}%`}</span>
                            <img src={trend > 0 ? triangleUp : triangleDown} alt="triangleUp"/>
                        </span>
                    }
                </div>
            </td>
            <td className="status">


                <div className="progress-bar-container">

                    {
                        (_userRole === userRole.author) ? (stateID != "10" && stateID != "20" && !(stateID === "30" && methodStatementStateID != "40" && !pdfDownloaded)) ?
                            <ProgressBar
                                percentage={(stateID === "30" && methodStatementStateID == "40" && pdfDownloaded) ? 100 : 0}/> :

                            <div className={"progress-bar"}>
                                <Skeleton style={{backgroundColor: "rgba(20, 178, 158, 0.7)"}} variant="rect"
                                          width={'100%'} height={12}/>
                            </div>
                            : null
                    }

                    {(stateID === "10" && _userRole === userRole.author) && <span>{`Uploaded`}</span>}
                    {(stateID === "20" && _userRole === userRole.author) && <span>{`Processing…`}</span>}
                    {(stateID === "30" && (methodStatementStateID != "40" || !pdfDownloaded) && _userRole === userRole.author) &&
                    <span>{`Processing…`}</span>}
                    {(stateID === "30" && methodStatementStateID == "40" && pdfDownloaded && _userRole === userRole.author) &&
                    <span>{`Processed, ready to proceed`}</span>}
                    {stateID === "90" && _userRole === userRole.author && <span>{'Complete, send for approval'}</span>}
                    {isUnsubmitted() && <span>{'Unsubmitted'}</span>}
                    {stateID === "50" && <span>{`Pending approval`}</span>}
                    {stateID === "80" && <span>{`Rejected`}</span>}
                    {stateID === "70" && <span>{`Approved`}</span>}
                    {stateID === "100" && <span>{`Intuety fa`}</span>}


                    <CustomReactTooltip/>

                </div>


                <div>

                    {(stateID === "10" && _userRole === userRole.author) && <img src={processing} alt="triangleUp"/>}
                    {(stateID === "20" && _userRole === userRole.author) && <img src={processing} alt="triangleUp"/>}
                    {(stateID === "30" && (methodStatementStateID != "40" || !pdfDownloaded) && _userRole === userRole.author) &&
                    <img src={processing} alt="triangleUp"/>}


                    {(stateID === "30" && methodStatementStateID == "40" && pdfDownloaded && _userRole === userRole.author) &&
                    <a
                        style={{cursor: "pointer"}}
                        className="anchor"
                        onClick={(event) => {
                            event.preventDefault();
                            //not ready to processed
                            cookie.set("jobIdSelected", job_id, {sameSite: 'lax', secure: true});
                            cookie.set("assessmentSelected", {...props}, {sameSite: 'lax', secure: true});
                            cookie.set("riskAssessmentID", riskAssessmentID, {sameSite: 'lax', secure: true});
                            cookie.set("qualityScore", qualityScore, {sameSite: 'lax', secure: true});
                            cookie.set("assessmentSelectedPDFInfo", {
                                taskDetails,
                                riskAssessmentID,
                                job_id,
                                pdfDownloaded,
                                methodStatementPdfName,
                                methodStatementStateID,
                                clientID,
                                methodStatementUploaded,
                                riskAssessmentApprovedByDetails
                            }, {sameSite: 'lax', secure: true});

                            props.history.push('/activities');

                        }}>

                        <img src={processed} alt="processed"/>
                    </a>
                    }


                    {(stateID === "90" && _userRole === userRole.author) &&
                    <StateIcon img={complete} handleMoveToSummary={handleMoveToSummary} alt="complete"/>}
                    {(isUnsubmitted()) && <div className={"unsubmitted-circle"}/>}
                    {stateID === "50" &&
                    <StateIcon img={pending} handleMoveToSummary={handleMoveToSummary} alt="pending"/>}
                    {stateID === "70" &&
                    <StateIcon img={approved} handleMoveToSummary={handleMoveToSummary} alt="approved"/>}
                    {stateID === "80" &&
                    <StateIcon img={rejected} handleMoveToSummary={handleMoveToSummary} alt="rejected"/>}
                    {stateID === "100" && <img src={exclamationCircle} alt="exclamation-circle" data-tip={remarks}/>}


                </div>

            </td>
        </tr>


    );
}

export default (ItemTable);


const StateIcon = ({img, alt, handleMoveToSummary}) => {


    return (
        <a
            style={{cursor: "pointer"}}
            className="link-reset-password"
            onClick={(event) => {
                event.preventDefault();

                handleMoveToSummary();
            }}>

            <img src={img} alt={alt}/>

        </a>
    )
}
