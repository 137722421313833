import React, {createRef, useState} from 'react';
import {closeModal} from "../../utils/icons";
import {DropzoneUpload, Input} from "../../components";
import {cvsFile, docFile} from "../../utils/icons";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {connect} from "react-redux";
import {
    initAssessment,
    addAssessment,
} from "../../redux/actions";
import {getAssessments, getListReference, getListLocation, getListTasks} from "../../redux/selectors";
import {toastr} from "react-redux-toastr";


const UploadFile = (props) => {

    const {addAssessment, listReferences, listListLocation, listListTask, loading} = props;

    const riskFileDropzoneRef = createRef();

    const methodFileDropzoneRef = createRef();

    const projectRef = createRef();


    const [formData, setFormData] = useState(
        {
            "userEmail": '',
            "taskID": {task: "", taskID: 0},//"",
            "project": '',
            "reference": '',
            'taskName': ''
        },
    );

    const [file1, setFile1] = useState([]);
    const [file2, setFile2] = useState([]);


    const handleClean = () => {
        setFormData({
            "userEmail": '',
            "taskID": {task: "", taskID: 1}, //'',
            "project": '',
            "reference": '',
            'taskName': ''
        })

        setFile1([]);
        setFile2([]);
    }

    const handleSubmit = (event) => {

        event.preventDefault();

        if (file1.length == 0) {
            toastr.warning('File is require!', 'Risk assessment file is require!');
            return;
        }
        if (file2.length == 0) {
            toastr.warning('File is require', 'Method Statement file is require!');
            return;
        }

        if (formData.taskID.taskID == 0) {
            toastr.warning('Project is require', 'Project field is require!');
            return;
        }

        formData.taskName = formData.taskID.task;
        formData.taskID = formData.taskID.taskID;


        addAssessment({...formData, riskFile: file1[0], methodFile: file2[0]}, submitSuccess);


    }


    const submitSuccess = () => {

        handleClean();
        props.setShowModal(false);
    }


    return (

        <div className="uploadFileWrapper">

            <button className="icon-menu-modal" onClick={() => submitSuccess()}>
                <img src={closeModal} alt="menu"/>
            </button>


            <h2>File Upload </h2>


            <form className="uploadFile-container" onSubmit={handleSubmit}>

                <div className={"fields-wrapper"}>

                    <Autocomplete
                        id="free-solo-demo"
                        // color={"red"}
                        className={"autocomplete"}
                        freeSolo
                        options={listReferences}
                        value={formData.reference}
                        onChange={(event, newValue) => {
                            setFormData({...formData, reference: newValue})
                        }}

                        onInputChange={(event, newInputValue) => {
                            setFormData({...formData, reference: newInputValue})
                        }}

                        renderInput={(params) => (
                            <TextField {...params} label="Reference" className={"textField"} size={"small"}
                                       margin="normal" variant="outlined"/>
                        )}
                    />


                    <Autocomplete
                        id="free-solo-demo2"
                        className={"autocomplete"}
                        freeSolo
                        options={listListLocation}
                        value={formData.project}
                        onChange={(event, newValue) => {
                            setFormData({...formData, project: newValue})
                        }}

                        onInputChange={(event, newInputValue) => {
                            setFormData({...formData, project: newInputValue})
                        }}
                        renderInput={(params) => (
                            <TextField {...params} label="Project" className={"textField"} size={"small"}
                                       margin="normal" variant="outlined"/>
                        )}
                    />

                    <Autocomplete
                        ref={projectRef}
                        id="free-solo-demo3"
                        className={"autocomplete"}
                        freeSolo


                        // value={(option) => option }
                        value={formData.taskID}
                        options={listListTask}
                        getOptionLabel={(option) => option.task ?? ''}

                        onChange={(event, newValue) => {
                            let task = newValue == null ? {task: "", taskID: 0} : newValue;
                            setFormData({...formData, taskID: task, taskName: newValue})
                        }}

                        onInputChange={(event, newInputValue) => {

                            let task = newInputValue == null ? {task: "", taskID: 0} : {
                                task: newInputValue,
                                taskID: -1
                            };
                            setFormData({...formData, taskID: task, taskName: newInputValue})
                        }}


                        renderInput={(params) => (
                            <TextField {...params} label="Task" className={"textField"} size={"small"}
                                       margin="normal" variant="outlined"/>
                        )}

                    />
                </div>
                <div className="dropzone-container">

                    <div>
                        <p className="sub-header">Risk Assessment</p>
                        <DropzoneUpload
                            title={"Risk Assessment file"}
                            icon={cvsFile}
                            myFiles={file1}
                            setMyFiles={(value) => setFile1(value)}

                            key={'riskFile'}
                            dropzoneRef={riskFileDropzoneRef}
                        />
                    </div>

                    <div>
                        <p className="sub-header">Method Statement</p>
                        <DropzoneUpload
                            title={"Method Statement file "}
                            icon={docFile}
                            myFiles={file2}
                            setMyFiles={(value) => setFile2(value)}
                            key={'methodFile'}
                            dropzoneRef={methodFileDropzoneRef}
                        />
                    </div>

                </div>

                <hr/>

                <div className="uploadFile-button-container">

                    <button className="btn-secondary"
                            type={'button'}
                            onClick={submitSuccess}
                    >CANCEL
                    </button>
                    {!loading ? <button className="btn-primary " type={'submit'}>UPLOAD</button>
                        : <button className="btn-secondary updloading" type={'button'}>UPLOADING..</button>
                    }

                </div>

            </form>

        </div>


    );
}


const mapStateToProps = (state) => {

    const listAssessments = getAssessments(state);
    const listReferences = getListReference(state);
    const listListLocation = getListLocation(state);
    const listListTask = getListTasks(state);
    const loading = state.assessments.loading;

    return {listAssessments, listReferences, listListLocation, loading, listListTask}
}

export default connect(mapStateToProps,
    {initAssessment, addAssessment})(UploadFile);


