import React, {useEffect} from 'react';
import {Card, ProgressAnimated, Header, Icon, CardComment, BackdropLoading, CustomReactTooltip} from "../../components";
import {triangleUp} from "../../utils/icons";
import {CircleProgress} from "../../components/circleProgress";
import {easeQuadInOut} from "d3-ease";
import UseWindowDimensions from "../../utils/useWindowDimensions";
import {Link} from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux';
import {
    getAssessmentSelected, getAuthFullName, getQualityScore,
    getTotalActivity, getActivitiesToPrint,
    getTotalMitigation, getTotalMitigationRequireApproval, getTotalMitigationRequireApproved,
    getTotalRisk,
    getTotalRiskRequireApproval, getTotalRiskRequireApproved, isLoadingAssessment, isMainLoading
} from "../../redux/selectors";
import {getActivity, getAssessment} from "../../redux/actions";

const Summary = (props) => {


    const {width} = UseWindowDimensions();
    const smallDevice = 730;
    const dispatch = useDispatch();

    const activitiesToPrint = useSelector(state => getActivitiesToPrint(state));
    const _author = useSelector(state => getAuthFullName(state));
    const numberTotalRisk = useSelector(state => getTotalRisk(state));
    const numberTotalRiskRequireApproval = useSelector(state => getTotalRiskRequireApproval(state));
    const numberTotalRiskRequireApproved = useSelector(state => getTotalRiskRequireApproved(state));
    const numberTotalActivities = useSelector(state => getTotalActivity(state));
    const numberTotalMitigation = useSelector(state => getTotalMitigation(state));
    const numberTotalMitigationRequireApproval = useSelector(state => getTotalMitigationRequireApproval(state));
    const numberTotalMitigationRequireApproved = useSelector(state => getTotalMitigationRequireApproved(state));
    const _assessmentSelected = useSelector(state => getAssessmentSelected(state));
    const _isLoadingActivity = useSelector(state => isMainLoading(state));
    const _isLoadingAssessment = useSelector(state => isLoadingAssessment(state));
    const qualityScore = useSelector(state => getQualityScore(state));


    useEffect(() => {
        dispatch(getActivity());
        dispatch(getAssessment(true));
    }, []);


    const isLoading = () => _isLoadingActivity || _isLoadingAssessment;

    const getQualityScoreDiff = () => {
        if (isLoading())
            return 0;
        const val = !_assessmentSelected ? 0 : _assessmentSelected.qualityScore ?? 0;
        const valInit = !_assessmentSelected ? 0 : _assessmentSelected.initialQualityScore ?? 0;

        const _val = val - valInit;
        return _val ? (_val).toFixed(0) : 0;
    }
    return (

        <div className="summary-container fade-in">
            <Header title="Risk Assessment Recommendation Report"/>
            <BackdropLoading isLoading={isLoading()}/>
            <div className="summary-content">

                <div className="summary-content-header">

                    <div
                        className={(width < smallDevice) ? "circle-progress-container small" : "circle-progress-container"}>

                        <div>
                            <CircleProgress
                                percentage={100}
                                strokeWidth={(width < smallDevice) ? 10 : 15}
                                width={(width < smallDevice) ? width / 3.5 : 230}
                                primaryColor={['#ed7cb1', '#ed7cb1']}
                                secondaryColor={"#e3e4e2"}

                                circleElement={
                                    (
                                        <SecondaryCircleElement
                                            value={isLoading() ? 0 : numberTotalRisk}
                                            suggested={numberTotalRiskRequireApproval}
                                            accepted={numberTotalRiskRequireApproved}
                                            isRisk
                                            mobile={width < smallDevice}
                                        />
                                    )}

                            />

                            {(width < smallDevice) && < SecondaryCircleElement

                                suggested={numberTotalRiskRequireApproval}
                                accepted={numberTotalRiskRequireApproved}
                                isRisk
                                subTitleBold
                                // subTitleFull
                            />
                            }

                        </div>

                        <div>


                            <ProgressAnimated
                                valueStart={0}
                                valueEnd={qualityScore}
                                duration={1.4}
                                easingFunction={easeQuadInOut}
                            >
                                {percentage => (

                                    <CircleProgress
                                        percentage={percentage}
                                        strokeWidth={(width < smallDevice) ? 10 : 18}
                                        width={(width < smallDevice) ? width / 3.5 : 280}
                                        primaryColor={['#14b29e', '#4DAAF1']}
                                        secondaryColor={"#e3e4e2"}

                                        circleElement={(
                                            <MainCircleElement
                                                percentage={percentage ? percentage.toFixed() : 0}
                                                activities={numberTotalActivities}
                                                qualityScore={getQualityScoreDiff()}
                                                mobile={width < smallDevice}
                                                // mobile
                                            />)}

                                    />

                                )}
                            </ProgressAnimated>

                            {(width < smallDevice) &&
                            <MainCircleElement
                                activities={numberTotalActivities}
                                qualityScore={getQualityScoreDiff()}
                                subTitleBold
                            />
                            }

                        </div>

                        <div>
                            <CircleProgress
                                percentage={100}
                                strokeWidth={(width < smallDevice) ? 10 : 15}
                                width={(width < smallDevice) ? width / 3.5 : 230}
                                primaryColor={['#ed7cb1', '#ed7cb1']}
                                secondaryColor={"#e3e4e2"}

                                circleElement={(
                                    <SecondaryCircleElement
                                        value={isLoading() ? 0 : numberTotalMitigation}
                                        suggested={numberTotalMitigationRequireApproval}
                                        accepted={numberTotalMitigationRequireApproved}
                                        mobile={width < smallDevice}
                                    />)}
                            />

                            {(width < smallDevice) && <SecondaryCircleElement
                                suggested={numberTotalMitigationRequireApproval}
                                accepted={numberTotalMitigationRequireApproved}
                                // isRisk
                                subTitleBold
                                subTitleFull
                            />
                            }

                        </div>

                    </div>

                    <div style={{order: (width < smallDevice) ? -1 : 1}}>
                        <h4>{`Author: ${_author}`}</h4>
                    </div>

                </div>

                <CardComment/>


                {activitiesToPrint && activitiesToPrint.map((activity, index) => <CardMain
                    key={index} {...activity} ></CardMain>)}


                <div className="summary-content-footer">
                    <Link className={"btn-continue btn-primary"} to={"/method-statement"}>CONTINUE TO METHOD
                        STATEMENT</Link>
                </div>
            </div>

        </div>
    );
}


export default Summary;


const CardMain = ({activity, Risks}) => {


    return (


        <div className="card-main">


            <div className="card-values-wrapper">


                {
                    Risks.map((risk, index) =>

                        <div key={"card-value" + index} className="card-values">

                            {index == 0 && <Card className="card-description">
                                <h5 className="activities-title">DESCRIPTION OF ACTIVITY</h5>
                                <p className="activities-text">{activity}</p>
                            </Card>}

                            {index !== 0 && <div className="card-description empty">

                            </div>}
                            <CardRisk risk={risk}/>
                            {/*<CardValue isRisk list={risk.risk} suggested = {suggested===1} />*/}
                            <CardValue id={"card-value-card" + index + activity} list={risk.Mitigations}/>
                        </div>
                    )
                }


            </div>

        </div>
    )

}


const CardValue = ({list, id}) => {


    return (
        <Card className="card-value" id={id}>

            {list.length > 0 && <h5 id={"control" + id} className="activities-title">{'CONTROL MEASURE'}</h5>}

            {
                list.map((item, index) =>
                    <div key={"key" + item.description + index} className="activities-text-container">

                        {
                            (item.intuetySuggestion != "none") ?

                                < div
                                    className={(item.intuetySuggestion === "remove" && item.userAction === 'accept') ? "oval oval-removed" : "oval"}></div> :
                                < div className={"oval oval-removed"}></div>


                        }


                        {
                            (item.intuetySuggestion === "remove" && item.userAction === 'accept') ?
                                <p className="activities-text removed">{item.description + "(removed)"}</p> :
                                <p className={"activities-text"}>{item.description}</p>
                        }
                    </div>
                )
            }


        </Card>
    )
}


const CardRisk = ({risk}) => {


    return (
        <Card className="card-value" id={"car-risk-key" + risk.risk.toString()}>

            <h5 className="activities-title">{'HAZARD & ASSOCIATED RISK '}</h5>


            <div key={risk.risk.toString()} className="activities-text-container">
                <CustomReactTooltip/>


                {
                    risk.intuetySuggestion != "none" ?
                        < div className="oval" data-tip="Intuety recommendation"></div> :
                        < div className={"oval oval-removed"}></div>}

                <p className="activities-text">{risk.risk.toString()}</p>
            </div>


        </Card>
    )
}


const MainCircleElement = ({percentage, activities, qualityScore, mobile, subTitleBold,}) => {

    let content = (<TextCircleElement value={percentage} perc/>);

    if (!mobile) {
        content = (
            <div className={"circle-element"}>

                {percentage && <h1>{`${percentage}%`}</h1>}
                <span className={(subTitleBold) ? "title bold" : "title"}>Quality Score</span>
                <div className={"title bold"}>
                    <span>(<Icon path={triangleUp} alt={"icon-triangleUp"}/>{`${qualityScore}%`})</span>
                </div>
                <span className={"title"}>Activities <b>{activities}</b></span>
            </div>
        )
    }

    return content;
}


const SecondaryCircleElement = (
    {
        isRisk,
        value,
        suggested,
        accepted,
        mobile,
        subTitleBold,
        subTitleFull
    }) => {

    let content = (<TextCircleElement value={value}/>);

    if (!mobile) {
        content = (
            <div className={"circle-element"}>

                {(isRisk) && <span className={subTitleBold ? "sub-title bold" : "sub-title"}>Risks</span>}
                {(!isRisk && subTitleFull) &&
                <span className={subTitleBold ? "sub-title bold" : "sub-title"}>Control Measures</span>}
                {(!isRisk && !subTitleFull) &&

                <>
                    <span className={"sub-title multiple-line"}>Control</span>

                    <span className={"sub-title multiple-line"}>Measures</span>
                </>
                }
                {value && <h2>{value}</h2>}
                <div className={"title"}>
                    <span>Suggested  <b>{suggested}</b></span>
                </div>
                <span className={"title"}>Accepted  <b>{accepted}</b></span>
            </div>
        )
    }

    return content;
}


const TextCircleElement = ({value, perc}) => {

    return (
        <div className={"circle-element"}>
            <h1 className={"big-title"}>{`${value}${(perc) ? '%' : ''}`}</h1>
        </div>
    )

}