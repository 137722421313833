import {CHANGE_SHOW_NAV} from "../constants";

export default function reducer(state = {

    menuOpen: false,

}, action) {

    switch (action.type) {

        case CHANGE_SHOW_NAV: {

            return {
                ...state,
                menuOpen: !state.menuOpen
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}