import {

    ANNOTATION_INIT,
    ANNOTATION_SET_SUCCESSFULLY,
    ANNOTATION_UPDATE_SUCCESSFULLY,
    ANNOTATION_REQUEST_ERROR,
    ANNOTATION_REQUEST,
    ANNOTATION_REMOVE_SUCCESSFULLY,
    ANNOTATION_ADD_SUCCESSFULLY,
    ANNOTATION_UPDATE_POSITION_SUCCESSFULLY,

} from "../constants";
import axios from "../../utils/axios-aurora";
import {handleError} from "./handleHttpError";
import {toastr} from "react-redux-toastr";
import cookie from 'js-cookie';
import message from '../../utils/messages';

export function initAnnotation() {

    return (dispatch) => {

        dispatch(
            {
                type: ANNOTATION_INIT,
            });
    }
}


export function setAnnotation() {

    return (dispatch) => {

        const jobId = cookie.get("jobIdSelected");

        if (!jobId) {
            toastr.error('Error', message.getAnnotationError);
            return;
        }


        axios.get('methodStatement/retrieveComments?jobID=' + jobId)
            .then(res => {

                if (Object.keys(res.data).length === 0)
                    dispatch(requestAnnotationSuccessfully([]))
                else
                    dispatch(requestAnnotationSuccessfully(res.data));

            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });


    }
}


export function addAnnotation(highlight) {

    return (dispatch) => {

        dispatch(addAnnotationSuccessfully(highlight));
        const jobId = cookie.get("jobIdSelected");


        axios.post('methodStatement/storeComments',
            {
                "jobID": jobId,
                "action": "add",
                "updateJson": highlight,
            })
            .then(res => {

            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.addAnnotationError);
                dispatch(removeAnnotationSuccessfully(highlight.id));
                dispatch(requestError());
            });
    }
}

export function updateAnnotation(highlight, text) {

    return (dispatch, getState) => {


        const {email, fullName} = getState().auth;

        const highlightAux = {
            ...highlight,
            comment: {
                emoji: "",
                text: text,
            },
            createAt: new Date(),
            approvalID: email,
            approvalName: fullName
        }

        dispatch(updateAnnotationSuccessfully(highlightAux));

        const jobId = cookie.get("jobIdSelected");
        axios.post('methodStatement/storeComments',
            {
                "jobID": jobId,
                "commentID": highlightAux.id,
                "action": "update",
                "updateJson": highlightAux,
            })
            .then(res => {

                // dispatch(addAnnotationSuccessfully(highlight));

            })

            .catch(function (error) {
                console.log(error);
                // toastr.error('Error','Error to get data!' );
                toastr.error('Error', message.editAnnotationError);
                dispatch(requestError());
                dispatch(updateAnnotationSuccessfully(highlight));
                // dispatch(handleError(error));
            });


    }
}

export function updateAnnotationPosition(highlight, position, content) {

    return (dispatch, getState) => {

        const {email, fullName} = getState().auth;
        dispatch(updateAnnotationPositionSuccessfully(highlight.id, position, content));

        const highlightAux = {
            ...highlight,

            position: {
                ...highlight.position,
                ...position
            },
            content: {
                ...highlight.content,
                ...content
            },
            createAt: new Date(),
            approvalID: email,
            approvalName: fullName
        }


        const jobId = cookie.get("jobIdSelected");
        axios.post('methodStatement/storeComments',
            {
                "jobID": jobId,
                "commentID": highlightAux.id,
                "action": "update",
                "updateJson": highlightAux,
            })
            .then(res => {

                // dispatch(updateAnnotationSuccessfully(highlightAux));


            })

            .catch(function (error) {
                console.log(error);
                // toastr.error('Error','Error to get data!' );
                dispatch(updateAnnotationSuccessfully(highlight));
                dispatch(requestError());
                // dispatch(handleError(error));
            });


    }
}


export function removeAnnotation(highlightId) {

    return (dispatch) => {

        const jobId = cookie.get("jobIdSelected");
        axios.post('methodStatement/storeComments',
            {
                "jobID": jobId,
                "commentID": highlightId,
                "action": "delete",

            })
            .then(res => {
                dispatch(removeAnnotationSuccessfully(highlightId));
            })

            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
            });
    }
}


const requestAnnotationSuccessfully = (value) => {

    return {
        type: ANNOTATION_SET_SUCCESSFULLY,
        payload: value,
    }
}

const addAnnotationSuccessfully = (annotation) => {


    return {
        type: ANNOTATION_ADD_SUCCESSFULLY,
        payload: annotation,
    }
}


const updateAnnotationSuccessfully = (highlightAux) => {

    return {
        type: ANNOTATION_UPDATE_SUCCESSFULLY,
        payload: highlightAux,
    }
}

const updateAnnotationPositionSuccessfully = (highlightId, position, content) => {

    return {
        type: ANNOTATION_UPDATE_POSITION_SUCCESSFULLY,
        payload: {highlightId, position, content},
    }
}


const removeAnnotationSuccessfully = (id) => {

    return {
        type: ANNOTATION_REMOVE_SUCCESSFULLY,
        payload: id,
    }
}

const requestError = () => {
    return {
        type: ANNOTATION_REQUEST_ERROR,
    }
}


export function startRequest() {
    return {
        type: ANNOTATION_REQUEST,
    }
}

