import React, {useCallback, useEffect, useState} from 'react';
import {DataRangeCalendar, Header, Icon, PaginationTable, Select} from "../../../components";
import {withRouter} from 'react-router-dom';
import {
    getTotalPagesClients,
    getListClients,
    getNumberTotalClients

} from "../../../redux/selectors";
import {connect} from "react-redux";
import {
    setClientSearch, setClientFilter, setClientRowsPerPage,
    editClient, setSortClients, setClientPage, startClients
} from "../../../redux/actions";

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {arrowsAlt, check, editAdmin, remove, search, times, user} from "../../../utils/icons";
import FormatDate from "../../../components/formatDate";


const ClientsTable = (props) => {


    const {
        listClients, listStatus, listNames, totalPages, rowsPerPage, page,
        setClientRowsPerPage, editClient, setSortClients, setClientPage,
        setShowDialogBox, setShowModal, columns, startClients, totalClients
    } = props;


    const handleAdministratorFetch = useCallback(async () => {
        await startClients();
    }, []);


    useEffect(() => {
        handleAdministratorFetch();
    }, []);


    const handleChangePage = (event, newPage) => {
        setClientPage(newPage);
    };


    return (


        <div className="main-container fade-in">


            <div className="container-table-filters ">
                <div className="filters filter-by-client-left">
                    <div className="filter-title">
                        <span>SEARCH BY</span>
                    </div>
                    <div className="filter-content">
                        <input type="text"
                               placeholder={"Type to search"}
                               onChange={(event) => props.setClientSearch(event.target.value)}
                        />
                        <img className="search" src={search} alt="search-icons"/>

                    </div>

                </div>


                <div className="filters filter-by-client-right">

                    <div className="filter-title">
                        <img className="search" src={arrowsAlt} alt="arrowsAlt-icons"/>
                        <span>Status</span>
                    </div>
                    <div className="filter-content">

                        <Select list={listStatus}
                                value={props.filterStatus}
                                type="status"
                                handleChange={props.setClientFilter}
                                keyValue
                        />

                    </div>


                </div>


            </div>


            <div className="table-wrapper">

                <Paper className="paper-container">
                    <TableContainer className={"table-container"}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="table-row-header">
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >

                                            {
                                                column.isButton ?
                                                    <CustomButton _onClick={() => setSortClients(column.id)}
                                                                  text={column.label}/> :
                                                    column.label

                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listClients && listClients.map(({clientID, clientName, roleName, created, status}) => {


                                    let row = createData(clientID, clientName, roleName, created, status);

                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.clientID}
                                                  className={!row.status ? 'row removed' : 'row'}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {column.id === 'edit' ?
                                                            <ActionsComponent id={row.clientID}
                                                                              editClient={editClient}
                                                                              setShowModal={setShowModal}
                                                                              setShowDialogBox={setShowDialogBox}
                                                                              key={`actions-${column.id}`}/> :
                                                            column.id === 'status' ?
                                                                <StatusComponent
                                                                    key={`status-${column.id}`}
                                                                    value={value}/> :

                                                                column.id === 'created' ?
                                                                    <FormatDate
                                                                        key={`created-${column.id}`}
                                                                        date={value}/> :

                                                                    value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Paper>

                <PaginationTable
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setClientRowsPerPage}
                    totalPages={totalPages}
                    page={page}
                    handleChangePage={handleChangePage}
                    totalItem={totalClients}
                />

            </div>

        </div>


    );
}


const mapStateToProps = (state) => {
    const listClients = getListClients(state);

    const listStatus = state.clients.status;
    const listNames = state.clients.names;
    const filterStatus = state.clients.filterStatus;
    const filterName = state.clients.filterName;
    const rowsPerPage = state.clients.rowsPerPage;
    const page = state.clients.currentPage;
    const totalPages = getTotalPagesClients(state);
    const totalClients = getNumberTotalClients(state);
    return {
        listClients, listStatus, filterStatus, listNames, filterName,
        rowsPerPage, page, totalPages, totalClients
    };
};


export default connect(mapStateToProps,
    {
        startClients, setClientSearch, setClientFilter, setClientRowsPerPage,
        editClient, setSortClients, setClientPage
    })(withRouter(ClientsTable));


function createData(clientID, clientName, roleName, created, status) {
    const edit = 4565;
    return {clientID, clientName, roleName, created, status, edit};
}


const CustomButton = ({text, _onClick}) => {
    return (
        <button style={{color: 'white', fontWeight: 500}}
                onClick={_onClick}
        >{text}</button>
    )
}

const ActionsComponent = ({id, editClient, setShowModal, setShowDialogBox}) => {

    const handleEdit = () => {
        editClient(id);
        setShowModal(true);

    }

    return (
        <div className="actions-container">
            <button className="btn" onClick={handleEdit}>
                <Icon path={editAdmin} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
            <button className="btn" onClick={() => setShowDialogBox(id)}>
                <Icon path={remove} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
        </div>
    );
}


const StatusComponent = ({value}) => {

    if (value)
        return (<Icon path={check} alt="checked-icon" iconclass={"icon-check"}/>);
    else
        return (<Icon path={times} alt="remove-icon" iconclass={"icon-times-removed"}/>);

}
