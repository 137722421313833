
import {
    AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_INITIATE_LOGOUT,
    AUTH_LOGOUT, AUTH_CHECK_TIMEOUT, AUTH_USER, AUTH_SET_EMAIL, AUTH_RESET_PASSWORD
} from "../constants";
import * as axiosDefault from 'axios';
import {toastr} from "react-redux-toastr";
import cookie from 'js-cookie';
import {generateString} from "../../utils/funcAux";
import message from "../../utils/messages";
import axios from "../../utils/axios-aurora";

const urlPrefix =``
export const login = (formLogin) => {

    const csrfToken = generateString();
    axiosDefault.defaults.headers.common['X-CSRF-TOKEN']=csrfToken;
    cookie.set("CSRF-TOKEN", csrfToken,{ sameSite: 'lax', secure: true } );

    return (dispatch) => {
        dispatch(authStart());

        // axiosDefault.post(`/aurora/authenticate`,
        axiosDefault.post(`authenticate`,
            {
                "username":formLogin.email,
                "password": formLogin.password,
            },

        )
            .then(res => {

                if(res.status == 200 && res.data && res.data.isloggedIn){
                   // alert(formLogin.email)
                    dispatch(authSuccess("res.data.jwt", formLogin.email, res.data.fullName, res.data.role));

                }

                else{
                    dispatch(authFail());
                }

            })
            .catch(function (error) {
                console.log(error);
                dispatch(authFail());
            });
    }

};


export const changePassword = (formLogin) => {

    const csrfToken = generateString();
    axiosDefault.defaults.headers.common['X-CSRF-TOKEN']=csrfToken;
    cookie.set("CSRF-TOKEN", csrfToken, { sameSite: 'lax', secure: true } );

    return (dispatch) => {
        dispatch(authStart());

            axiosDefault.get(`${urlPrefix}resetPassword?email=${formLogin.email}`,
        )
            .then(res => {

                dispatch({type:AUTH_RESET_PASSWORD});
                toastr.success('Success',message.resetPasswordSuccess );

            })
            .catch(function (error) {
                toastr.error('Error',message.resetPasswordError );
                console.log(error);
                // dispatch(authFail());
            });


    }

};



export const logoutUser = () => {


    return (dispatch, getState) => {

        const { email } = getState().auth;
        // dispatch(startRequest())

        axios.post(`logout?username=${email}`)
            .then(res => {
                dispatch(logoutSucceed());
            })
            .catch(function (error) {
                console.log(error);
                // dispatch(requestError());
                // dispatch(handleError(error));
            });
    }

};

export const IsValidUser = () => {

    console.log("check if is ValidUser");

    return (dispatch, getState) => {

        const { email } = getState().auth;
        // dispatch(startRequest())

        axios.post(urlPrefix+`validUser?username=${email}`)
            .then(res => {
                console.log(res);
                // dispatch(logoutSucceed());
            })
            .catch(function (error) {
                console.log(error);
                // dispatch(requestError());
                // dispatch(handleError(error));
            });
    }

};

export const authStart = () => {
    return {
        type: AUTH_START
    };
};


export const setEmail = (email) => {
    return {
        type: AUTH_SET_EMAIL,
        payload: {email:email}
    };
};

export const authSuccess = (token, email, fullName, role) => {


    cookie.set('role', role, { sameSite: 'lax',secure: true });


    return {
        type: AUTH_SUCCESS,
        payload: {token, email, fullName, role},
    };
};

export const authFail = error => {
    toastr.error('Error',message.loginError );
    return {
        type: AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    return {
        type: AUTH_INITIATE_LOGOUT
    };
};


export const logoutSucceed = () => {

    // delete axios.defaults.headers.common['Authorization'] ;

    // cookie.remove("jwt");
    // cookie.remove("XSRF-TOKEN");
    // cookie.remove("CSRF-TOKEN");
    // cookie.remove("role");
    // cookie.remove("email");
    // cookie.remove("fullName");
    // cookie.remove("isAuthenticated");
    // cookie.remove("assessmentSelectedPDFInfo");
    // cookie.remove("assessmentSelected");

    Object.keys(cookie.get()).forEach(function(cookieName) {
        cookie.remove(cookieName);

    });

    return {
        type: AUTH_LOGOUT
    };

};

export const checkAuthTimeout = expirationTime => {
    return {
        type: AUTH_CHECK_TIMEOUT,
        expirationTime: expirationTime
    };
};

export const auth = (email, password, ) => {

    return {
        type: AUTH_USER,
        email: email,
        password: password,

    };
};


export const authCheckState = () => {

    axios.defaults.withCredentials = true;

    return (dispatch) => {
        dispatch(authStart());

            axios.post(`validUserWithToken`)
                .then(res => {

                    if(res.status == 200){

                        const {email, fullName, isloggedIn, role} =res.data;

                        if(!isloggedIn)
                            dispatch(logoutSucceed())
                        else{
                            dispatch(authSuccess("token", email, fullName, role));
                        }
                    }
                    else
                    {
                        dispatch(logoutSucceed())
                    }

                })
                .catch(() => {
                    dispatch(logoutSucceed());
                });

    };


};

