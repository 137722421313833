import React, {useEffect, useState} from "react";
import ProgressBar from "../../../components/progressBar";
import {CardMeasure, ProgressAnimated} from "../../../components";
import {connect, useDispatch} from "react-redux";
import {getActivity, initActivities} from "../../../redux/actions";
import {
    getActivities, getProgressBarTotal, getMitigationsProgressBar, getCurrentActivity,
    getCurrentRisk, getCurrentMitigations, currentMitigationsIndex, getActivitiesLength, getCurrentMitigationIndexNone
} from "../../../redux/selectors";
import {easeQuadInOut} from "d3-ease";

const ControlMeasure = (props) => {


    const [setNextActivity] = useState(false);

    const {
        listActivities,
        index, riskIndex, assessmentIndex, progressBarTotalIndex, progressBarMitigations, _currentMitigationIndexNone,
        currentActivity, currentRisk, listMitigations, mitigationsIndex, numberActivitiesToProcess
    } = props;
    const dispatch = useDispatch();


    useEffect(() => {
        if (!listActivities)
            dispatch(getActivity());
    }, []);


    return (


        <main className={"control-measure"}>

            <div className="percentage-container">
                <div className='percentage-content'>
                    <span className={"start-end-value"}>0</span>

                    <ProgressAnimated
                        valueStart={0}
                        valueEnd={progressBarMitigations}
                        duration={1.4}
                        easingFunction={easeQuadInOut}
                    >
                        {percentage => (

                            <ProgressBar percentage={percentage.toFixed()} percentage2={progressBarTotalIndex} circle/>

                        )}
                    </ProgressAnimated>

                    <span className={"start-end-value"}>100</span>
                </div>
            </div>


            {currentActivity &&
            <div className={"control-measure-container"}
                 key={currentActivity.activity}
            >

                <CardMeasure
                    key={currentActivity.activity + "card"}
                    subtitle={`DESCRIPTION OF ACTIVITY ${assessmentIndex + 1} / ${numberActivitiesToProcess}`}
                    title={currentActivity.activity}
                    level={0}
                    isDescriptionOfActivity
                />

                {
                    currentRisk &&
                    <CardMeasure
                        key={currentRisk.risk}
                        id={"risk-" + riskIndex}
                        subtitle={`HAZARDS & ASSOCIATED RISKS ${riskIndex + 1} / ${getNumberOfRisksToProcessing(currentActivity)}`}
                        title={currentRisk.risk}
                        level={1}
                        isDescriptionOfActivity={false}
                        isRisks={true}
                        index={-1}
                        lastIndex={false}
                        noControlMeasures={currentRisk.Mitigations && currentRisk.Mitigations.length === 0}
                        removed={currentRisk.intuetySuggestion === "remove"}
                        elementUserAction={currentActivity.userAction + " " + "ASAS"}

                    />
                }


                {(currentRisk.riskUserAction != "" || currentRisk.intuetySuggestion === "none") &&
                mitigationsIndex != -1 && listMitigations && listMitigations.map((item, i) =>


                    (i + _currentMitigationIndexNone <= index) && <CardMeasure
                        key={i + item.description}
                        subtitle={`CONTROL MEASURE ${i + 1} / ${currentRisk.numOfMitigationsWhichRequireApproval}`}
                        title={item.description}
                        level={2}
                        isDescriptionOfActivity={false}
                        isRisks={false}

                        index={3}
                        lastIndex={(index === listMitigations.length - 1) ||
                        listMitigations.length === 0
                        }
                        lastRisk={false}

                        setNextActivity={setNextActivity}

                        mitigationsIndex={i}

                        removed={item.intuetySuggestion === "remove"}
                        id={"mitigation-" + i}
                        elementUserAction={item.userAction}

                    />
                )
                }


            </div>
            }


            <div>


            </div>

        </main>


    );


}


const mapStateToProps = (state) => {
    const listActivities = getActivities(state);
    const assessmentIndex = state.activities.assessmentIndex;
    const riskIndex = state.activities.riskIndex;
    const index = state.activities.index;
    const progressBarTotalIndex = getProgressBarTotal(state);
    const progressBarMitigations = getMitigationsProgressBar(state);
    const currentActivity = getCurrentActivity(state);
    const currentRisk = getCurrentRisk(state);
    const listMitigations = getCurrentMitigations(state);
    const mitigationsIndex = currentMitigationsIndex(state);
    const numberActivitiesToProcess = getActivitiesLength(state);
    const _currentMitigationIndexNone = getCurrentMitigationIndexNone(state);

    return {
        listActivities,
        index,
        riskIndex,
        assessmentIndex,
        progressBarTotalIndex,
        numberActivitiesToProcess
        ,
        progressBarMitigations,
        currentActivity,
        currentRisk,
        listMitigations,
        mitigationsIndex,
        _currentMitigationIndexNone
    };
};


const getNumberOfRisksToProcessing = (activity) => {

    const _risks = activity.Risks
    if (!_risks || _risks.length === 0)
        return 0;

    return _risks.filter(risk => risk.numOfMitigationsWhichRequireApproval > 0).length;

}

export default connect(mapStateToProps, {initActivities})(ControlMeasure);


