import {createSelector} from 'reselect';
import {compareValues} from "../../utils/funcAux";

export const getUsers = state => state.users.listUsers && Object.values(state.users.listUsers);

export const getRoles = state => state.users.roles;

export const getClients = state => state.users.clients;

export const getFilterRole = state => state.users.filterRole;

export const getFilterName = state => state.users.filterName;

export const getFilterClient = state => state.users.filterClient;

export const getKeyword = state => state.users.textSearch;

export const getUserSort = state => state.users.sort;

export const getRowsPerPageUser = state => state.users.rowsPerPage;

export const getPageUser = state => state.users.currentPage;

export const getNumberTotalUsers = createSelector(
    [getUsers],
    (listUsers) => {

        if (!listUsers)
            return 0

        return listUsers.length;
    }
)


export const getVisibleUsersFilteredBYStatus = createSelector(
    [getUsers, getFilterRole],
    (listUsers, statusFilterValue) => {

        if (statusFilterValue === 'All')
            return listUsers

        return listUsers && listUsers.filter(item => item.roleId === statusFilterValue)
    }
)


export const getVisibleUsersFilteredBYClient = createSelector(
    [getVisibleUsersFilteredBYStatus, getFilterClient],
    (listUsers, filterValue) => {
        if (filterValue.toLowerCase() === 'all')
            return listUsers

        return listUsers && listUsers.filter(item => item.clientName.toLowerCase() === filterValue.toLowerCase())
    }
)


export const getVisibleUsersFilteredBYName = createSelector(
    [getVisibleUsersFilteredBYClient, getFilterName],
    (listUsers, filterValue) => {
        if (!filterValue || filterValue.trim() === '')
            return listUsers

        return listUsers && listUsers.filter(item => item.fullName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)

        // return listUsers && listUsers.filter(item => item.name.toLowerCase() === filterValue.toLowerCase())
    }
)


export const getVisibleUsersFilteredByKeyword = createSelector(
    [getVisibleUsersFilteredBYName, getKeyword],
    (visibleUsers, keyword) => {

        return visibleUsers && visibleUsers.filter(
            user => user.fullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                user.clientName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                user.userId.toString().indexOf(keyword.toLowerCase()) > -1
        )
    }
)


export const getUsersSort = createSelector(
    [getVisibleUsersFilteredByKeyword, getUserSort],
    (listUsers, sort) => {
        if (!sort.column) {
            return listUsers;
        }
        return listUsers.sort(compareValues(sort.column, sort.order))
    }
)


export const getTotalPagesUsers = createSelector(
    [getUsersSort, getRowsPerPageUser],
    (list, rowsPerPage) =>
        list && Math.ceil(list.length / rowsPerPage)
)


export const getListUsers = createSelector(
    [getUsersSort, getPageUser, getRowsPerPageUser],
    (list, page, rowsPerPage) =>
        list && list.slice((page - 1) * rowsPerPage, page * rowsPerPage)
)


export const getListClient = createSelector(
    [getClients],
    (list,) => {
        let collection = [];

        list && list.forEach((item) => {
            if (collection.filter(value => value.clientID === item.clientID).length === 0)
                collection.push({
                    "clientID": item.clientID,
                    "clientName": item.clientName
                })
        });
        return collection;
    }
);

