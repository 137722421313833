import React from "react";
import {

    BrowserRouter as Router,
    // HashRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    Redirect
} from "react-router-dom";


import Login from "../templates/login/login";
import Home from "../templates/home";
import UploadFile from "../templates/uploadFile";
import Activities from "../templates/activities";
import Layout from "../components/layout/layout";
import Summary from "../templates/summary/summary";
import IntuetyGuidance from "../templates/intuetyGuidance";
import MethodStatement from "../templates/methodStatement/methodStatement";
import {Dashboard, Users, Administrators, Ontology, Clients} from "../templates/dashboard";
import {connect} from "react-redux";
import {BackdropLoading} from "../components";
import DashboardRoute from "./dashboardRoute";
import {userRole} from "../utils/funcAux";
import AuroraRoute from "./auroraRoute";
import CronTester from "../templates/activities/cronTester";


function AppRouter({isAuthenticated, role}) {
    return (

        <Router>
            <Layout>

                <Switch>


                    {isAuthenticated === null &&
                    <Route path="/">
                        <BackdropLoading isLoading={true}/>
                    </Route>
                    }


                    {isAuthenticated === false &&
                    <Route path="/">
                        <Login/>
                    </Route>
                    }

                    {isAuthenticated === true && (role === userRole.author || role === userRole.approver) &&
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    }

                    {isAuthenticated === true && (role === userRole.admin) &&
                    <Route exact path="/">
                        <Dashboard/>
                    </Route>
                    }
                    {isAuthenticated === true && (role === userRole.ontology) &&
                    <Route exact path="/">
                        <Ontology/>
                    </Route>
                    }


                    <AuroraRoute path="/activities">
                        <Activities/>
                    </AuroraRoute>

                    <AuroraRoute path="/cron">
                        <CronTester/>
                    </AuroraRoute>

                    <AuroraRoute path="/upload">
                        <UploadFile/>
                    </AuroraRoute>

                    <AuroraRoute path="/summary">
                        <Summary/>
                    </AuroraRoute>

                    <AuroraRoute path="/intuety-guidance">
                        <IntuetyGuidance/>
                    </AuroraRoute>

                    <DashboardRoute path="/dashboard">
                        <Dashboard/>
                    </DashboardRoute>


                    <DashboardRoute path="/administrators">
                        <Administrators/>
                    </DashboardRoute>

                    <DashboardRoute path="/ontology">
                        <Ontology/>
                    </DashboardRoute>


                    <DashboardRoute path="/users">
                        <Users/>
                    </DashboardRoute>

                    <DashboardRoute path="/clients">
                        <Clients/>
                    </DashboardRoute>

                    <AuroraRoute path="/method-statement">
                        <MethodStatement/>
                    </AuroraRoute>


                    <Route path="*">
                        <Redirect from='*' to='/'/>
                    </Route>


                </Switch>
            </Layout>
        </Router>

    );
}


const mapStateToProps = ({auth}) => {
    const isAuthenticated = auth.isAuthenticated;
    const role = auth.role;

    return {isAuthenticated, role};
}

export default connect(mapStateToProps, {})(AppRouter);