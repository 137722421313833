import {
    ASSESSMENT_INIT,
    ASSESSMENT_SET_SEARCH,
    ASSESSMENT_SET_FILTER,
    ASSESSMENT_SET_PAGE,
    ASSESSMENT_SET_ROW_PER_PAGE,
    ASSESSMENT_REQUEST_COMPLETED,
    ASSESSMENT_REQUEST_UPDATE_COMPLETED,
    ASSESSMENT_REQUEST_ERROR,
    ASSESSMENT_REQUEST,
    ASSESSMENT_SET_SHOW_DATE_RANGE,
    ASSESSMENT_SET_DATE_RANGE_START,
    ASSESSMENT_SET_DATE_RANGE_END,
    ASSESSMENT_SET_SHOW_MODAL_ADD, SET_CHECK_FOR_UPDATES_RAMS,
    ASSESSMENT_SET_SELECTED, SET_SAFE_BRIEFING_DOWNLOADING,
    ASSESSMENT_SAVE_COMMENT, SET_LIST_RECOMMENDATION,
    ASSESSMENT_SET_PDF_URL, ASSESSMENT_DOWNLOADING_FILE,
} from "../constants";
import axios from "../../utils/axios-aurora";
import {handleError} from "./handleHttpError";
import {arrayToObject, userRole} from "../../utils/funcAux";
import {toastr} from "react-redux-toastr";
import cookie from "js-cookie";
import message from "../../utils/messages";
import React from "react";
import {
    getUnapprovedRAMS,getLastRAMSID,
} from "../selectors";

const interval_timer = 3 * 60 * 1000; //3min

export function startRequest() {
    return {
        type: ASSESSMENT_REQUEST,
    }
}

export function setDownloading(value) {
    return {
        type: ASSESSMENT_DOWNLOADING_FILE,
        payload: {downloadingFile: value}
    }
}


export function setShowDateRange(value) {
    return {
        type: ASSESSMENT_SET_SHOW_DATE_RANGE,
        payload: {showDateRange: value},
    }
}


export function setDateRangeStart(value) {
    return {
        type: ASSESSMENT_SET_DATE_RANGE_START,
        payload: {dateRangeStart: value},
    }
}


export function setDateRangeEnd(value) {
    // console.log('date: '+value)
    return {
        type: ASSESSMENT_SET_DATE_RANGE_END,
        payload: {dateRangeEnd: value},
    }
}


export function getAssessment(newRequest) {


    return (dispatch, getState) => {

        const id = cookie.get("riskAssessmentID");

        if (!id) {
            dispatch(requestError());
        } else {

            dispatch(startRequest())
            const {email, role} = getState().auth;

            let url = `author/riskAssessments/${id}?username=${email}`
            if (role === userRole.approver)
                url = `approver/riskAssessments/${id}?username=${email}`;

            axios.get(url)
                .then(res => {
                    dispatch(getAssessmentSuccessfully(res.data));

                })
                .catch(function (error) {
                    dispatch(requestError());
                });
            // }
        }
    }
}


const getAssessmentSuccessfully = (value) => {
    return {
        type: ASSESSMENT_SET_SELECTED,
        payload: {assessmentSelected: value[0], loading: false},
    }
}

export function getAssessments(update) {


    return (dispatch, getState) => {

        const {email, role} = getState().auth;

        dispatch(startRequest())

        let url = "author/riskAssessments?username=" + email;
        if (role === userRole.approver)
            url = 'approver/riskAssessements?username=' + email;

        axios.get(url)
            .then(res => {
                dispatch(requestAssessmentSuccessfully(res.data));
                dispatch(setUpdateViewAssessments())

            })
            .catch(function (error) {
                console.log(error);
                // toastr.error('Error','Error to get data!' );
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }
}

export function setUpdateViewAssessments() {

    return (dispatch, getState) => {
        const {isCheckForUpdates} = getState().assessments;
        if (isCheckForUpdates)
            clearInterval(isCheckForUpdates);

        const timer = setInterval(() => dispatch(getUpdateAssessments()), interval_timer);
        dispatch(setCheckForUpdates(timer));
    }
}


export function clearCheckForUpdatesInterval() {

    return (dispatch, getState) => {
        const {isCheckForUpdates} = getState().assessments;
        if (isCheckForUpdates)
            clearInterval(isCheckForUpdates);

        dispatch(setCheckForUpdates(null))
    }
}


export function getUpdateAssessments() {

    return (dispatch, getState) => {

        const {email, role} = getState().auth;
        const listAssessments = getUnapprovedRAMS(getState());
        const allRequests = [];

        let url = "author/checkUpdate/";
        if (role === userRole.approver)
            url = 'approver/checkUpdate/';

        for (let i = 0; i < listAssessments.length; i++) {
            const assessment = listAssessments[i];
            const _url = url + assessment.riskAssessmentID;

            allRequests.push(
                axios.post(_url,
                    {
                        "username": email,
                        "raStatus": assessment.stateID, //stateID
                        "msStatus": assessment.methodStatementStateID, //methodStatementStateID
                        "pdfDownloaded": (assessment.pdfDownloaded) ? 1 : 0 //pdfDownloaded
                    }
                )
            )
        }


        const riskAssessmentID = getLastRAMSID(getState());
        if (role === userRole.approver && riskAssessmentID){
            allRequests.push(
                axios.post(`approver/checkLatest/${riskAssessmentID}?username=${email}`)
            )
        }


        Promise.all(allRequests)
            .then(res => {
                if (res) {
                    res.forEach(item => {
                        // console.log(item)
                        if (item.data) {
                            dispatch(requestUpdateAssessmentSuccessfully(item.data))
                        }
                    })
                }

            })
            .catch(function (error) {
                // console.log(error);
            });


    }
}


const requestAssessmentSuccessfully = (value) => {

    const val = arrayToObject(value, 'riskAssessmentID');

    return {
        type: ASSESSMENT_REQUEST_COMPLETED,
        payload: val,
    }
}

const setCheckForUpdates = (value) => {

    return {
        type: SET_CHECK_FOR_UPDATES_RAMS,
        payload: {isCheckForUpdates: value},
    }
}


const requestUpdateAssessmentSuccessfully = (value) => {

    return {
        type: ASSESSMENT_REQUEST_UPDATE_COMPLETED,
        payload: value,
    }
}


export function saveCommentAssessment(textComment, hideModal) {


    return (dispatch, getState) => {

        dispatch(startRequest())
        const {email} = getState().auth;
        const jobId = cookie.get("jobIdSelected");


        axios.post(
            `approver/storeSummaryComments?username=${email}&jobID=${jobId}`,
            {text: textComment})

            .then(res => {

                dispatch({
                    type: ASSESSMENT_SAVE_COMMENT,
                    payload: {text: textComment},
                })
                hideModal();

            })
            .catch(function (error) {

                dispatch(requestError());
                toastr.error(message.riskAssessmentComment);
                // dispatch(handleError(error));
            });

    }

}


export function downloadAssessment(fileName, riskAssessmentID) {


    return (dispatch, getState) => {

        // dispatch(startRequest());
        dispatch(setDownloading(true));
        const {email} = getState().auth;

        axios.get(`author/download/riskAssessment/${riskAssessmentID}?username=${email}`, {responseType: 'blob'})
            .then(res => {

                // dispatch(requestError());
                dispatch(setDownloading(false));
                //Create blob link to download
                //  const data = new Blob([res.data], {type: '.docx'});
                const url = window.URL.createObjectURL(new Blob([res.data], {type: '.docx'}));

                // console.log("downloadAssessment url");
                // console.log(url);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(function (error) {

                dispatch(requestError());
                // dispatch(handleError(error));
            });
    }
}


export function getFilesURL(fileName, _riskAssessmentID) {


    const assessmentSelectedString = cookie.get("assessmentSelectedPDFInfo");

    return (dispatch) => {

        if (!assessmentSelectedString) {
            dispatch({
                type: ASSESSMENT_SET_PDF_URL,
                payload: {methodStatementPdfURL: null}
            });
            return;
        }

        const _id = cookie.get("riskAssessmentID");
        const _assessmentSelected = JSON.parse(assessmentSelectedString);
        const {methodStatementPdfName, clientID, riskAssessmentID, methodStatementUploaded} = _assessmentSelected;


        if (methodStatementUploaded && _id == riskAssessmentID) {

            dispatch(setDownloading(true));
            axios.get(`methodStatement/downloadMethodStatementPdf/${riskAssessmentID}/${clientID}/${methodStatementPdfName}`, {responseType: 'blob'})
                // axios.get(`methodStatement/downloadMethodStatementPdf/61/1/0b0eabc68e5b5d7e715b345c1017b39d.pdf`, {responseType: 'blob'})
                .then(res => {

                    dispatch(setDownloading(false));

                    // //Create blob link to download
                    // //  const data = new Blob([res.data], {type: '.docx'});
                    const url = window.URL.createObjectURL(new Blob([res.data], {type: '.pdf'}));

                    dispatch({
                        type: ASSESSMENT_SET_PDF_URL,
                        payload: {methodStatementPdfURL: url}
                    });

                })
                .catch(function (error) {


                    dispatch(requestError());
                    dispatch({
                        type: ASSESSMENT_SET_PDF_URL,
                        payload: {methodStatementPdfURL: null}
                    });
                    // dispatch(handleError(error));
                });
        } else {
            dispatch({
                type: ASSESSMENT_SET_PDF_URL,
                payload: {methodStatementPdfURL: null}
            });
        }


    }
}

export function addAssessment(data, submitSuccess) {


    return (dispatch, getState) => {
        const {token, email} = getState().auth;

        if (!token || !email) {
            dispatch(requestError());
            return;
        }


        dispatch(startRequest());


        let form = new FormData();
        form.append("riskFile", data.riskFile);
        form.append("methodFile", data.methodFile);
        form.append("userName", email);
        form.append("taskID", data.taskID);
        form.append("project", data.project);
        form.append("reference", data.reference);
        form.append("taskName", data.taskName);


        axios({
            method: 'post',
            url: 'author/addRiskAssesment',
            data: form,
        })
            .then(response => {
                //handle success

                if (response.status === 200) {

                    toastr.success(message.addRiskAssessmentSuccess);
                    dispatch(getAssessments());
                    submitSuccess();
                } else {
                    toastr.error(message.addRiskAssessmentError);
                    dispatch(requestError());
                }
            })
            .catch(response => {
                toastr.error(message.addRiskAssessmentError);
                dispatch(requestError());
            });


    }
}

export function getSafetyBriefing() {

    return (dispatch, getState) => {

        const {email, role} = getState().auth;
        const id = cookie.get("jobIdSelected");
        const url = `safetyBriefing/fetchRecommendations?username=${email}&jobID=${id}`
        //id = 65c39f49-26a0-4656-a05c-260a6a1211e5

        axios.get(url,)
            .then(res => {
                if (res.data && res.data.recommended_briefings)
                    dispatch(setListRecommendationsBriefings(res.data.recommended_briefings));
            })
            .catch(function (error) {
                console.log(error);
            })
    }
}

const setListRecommendationsBriefings = (value) => {


    return {
        type: SET_LIST_RECOMMENDATION,
        payload: {listRecommendedBriefings: value},
    }
}


const setSafeBriefingsDownloading = (value) => {

    return {
        type: SET_SAFE_BRIEFING_DOWNLOADING,
        payload: {safetyBriefingsDownloading: value},
    }
}


export function downloadFileSafetyBriefing(id, fileName) {


    return (dispatch, getState) => {
        dispatch(setSafeBriefingsDownloading(id));
        const {email} = getState().auth;

        axios.get(`safetyBriefing/file?username=${email}&id=${id}`, {responseType: 'blob'})
            .then(res => {
                const url = window.URL.createObjectURL(new Blob([res.data], {type: '.pdf'}));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${fileName ? fileName : 'SafetyBriefingDoc'}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                dispatch(setSafeBriefingsDownloading(null));

            })
            .catch(function (error) {
                console.log(error);
                dispatch(setSafeBriefingsDownloading(null));
            })
    }
}


export const showModalAddAssessment = (value) => {
    return {
        type: ASSESSMENT_SET_SHOW_MODAL_ADD,
        payload: {showAddAssessmentModal: value}
    }
}


const requestError = () => {
    return {
        type: ASSESSMENT_REQUEST_ERROR,
    }
}


export function initAssessment() {
    return {
        type: ASSESSMENT_INIT,
    }
}

export function setAssessmentSearch(value) {
    return {
        type: ASSESSMENT_SET_SEARCH,
        payload: {textSearch: value, currentPage: 1}
    }
}

export function setAssessmentFilter(type, value) {

    const _value = value === 'All' ? 'All' : value;

    if (type === 'name')
        return {
            type: ASSESSMENT_SET_FILTER,
            payload: {filterName: _value, currentPage: 1}
        }

    return {
        type: ASSESSMENT_SET_FILTER,
        payload: {filterStatus: _value, currentPage: 1}
    }
}


export function setPageAssessment(value) {

    return (dispatch) => {

        dispatch({
            type: ASSESSMENT_SET_PAGE,
            payload: value
        })
        dispatch(getUpdateAssessments())
    }

}


export function setAssessmentRowsPerPage(value) {

    return {
        type: ASSESSMENT_SET_ROW_PER_PAGE,
        payload: {rowsPerPage: value}
    }
}
