import React, {useEffect} from 'react';
import './App.scss';
import {connect} from 'react-redux'
import ReduxToastr from 'react-redux-toastr'
import AppRouter from "./js/router/router";
import {authCheckState} from './js/redux/actions'

function AppWrapper(props) {

    useEffect(() => {
        props.authCheckState();
    })
    return (

        <div>
            <ReduxToastr
                timeOut={3000}
                newestOnTop={false}
                preventDuplicates
                position="top-right"
                getState={(state) => state.toastr} // This is the default
                transitionIn="fadeIn"
                transitionOut="fadeOut"
            />


            <AppRouter/>
        </div>

    );
}


export default connect(null, {authCheckState})(AppWrapper);
