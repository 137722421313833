import React, {useEffect, useState} from 'react';
import {BackdropLoading, Header} from "../../components";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {getTotalDashboard,} from "../../redux/actions";


const Dashboard = (props) => {


    const {getTotalDashboard, userTotal, adminTotal, ontologyTotal, totalClients, isLoading, fullName} = props


    useEffect(() => {

        getTotalDashboard();

    }, []);


    return (


        <div className="dashboard-container">

            <Header/>

            {adminTotal === 0 && <BackdropLoading isLoading={isLoading}/>}
            <div className="main-container fade-in">

                <div className="main-wrapper">

                    <h4>Intuety Administrators </h4>

                    <p>Welcome back {fullName ?? ''}</p>

                    <div
                        className="btn-container"

                    >
                        <button className="btn btn-secondary"
                                onClick={() => props.history.push('/users')}>
                            <span>Users</span>
                            <span className="badge">{userTotal}</span>
                        </button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => props.history.push('/administrators')}
                        >
                            <span>Administrators</span>
                            <span className="badge">{adminTotal}</span>
                        </button>


                        <button
                            className="btn btn-secondary"
                            onClick={() => props.history.push('/clients')}
                        >
                            <span>Client</span>
                            <span className="badge">{totalClients}</span>
                        </button>

                        <button
                            className="btn btn-secondary"
                            onClick={() => props.history.push('/ontology')}
                        >
                            <span>Ontology</span>
                            <span className="badge">{ontologyTotal}</span>
                        </button>
                    </div>
                </div>

            </div>


        </div>


    );
}


const mapStateToProps = ({dashboard, auth}) => {


    const adminTotal = dashboard.adminTotal;
    const totalClients = dashboard.totalClients;
    const ontologyTotal = dashboard.ontologyTotal;
    const userTotal = dashboard.userTotal;
    const isLoading = dashboard.isLoading;
    const fullName = auth.fullName;

    return {adminTotal, ontologyTotal, totalClients, userTotal, isLoading, fullName};
};


export default connect(mapStateToProps,
    {getTotalDashboard,})(withRouter(Dashboard));


