import {createSelector} from 'reselect';
import {compareValues} from "../../utils/funcAux";

export const getAdministrators = state => state.administrators.listAdministrators && Object.values(state.administrators.listAdministrators);

export const getStatus = state => state.administrators.status;

export const getFilterStatus = state => state.administrators.filterStatus;

export const getFilterName = state => state.administrators.filterName;

export const getFilterOrganisation = state => state.administrators.filterOrganisation;

export const getKeyword = state => state.administrators.textSearch;

export const getAdministratorSort = state => state.administrators.sort;

export const getRowsPerPageAdministrator = state => state.administrators.rowsPerPage;


export const getPageAdministrator = state => state.administrators.currentPage;

export const getNumberTotalAdministrators = createSelector(
    [getAdministrators],
    (listAdmins) => {

        if (!listAdmins)
            return 0

        return listAdmins.length;
    }
)


export const getVisibleAdministratorsFilteredBYStatus = createSelector(
    [getAdministrators, getFilterStatus],
    (listAdministrators, statusFilterValue) => {

        if (!statusFilterValue || statusFilterValue.toLowerCase() === 'all')
            return listAdministrators

        return listAdministrators && listAdministrators.filter(item => item.active === statusFilterValue)
    }
)


export const getVisibleAdministratorsFilteredBYOrganisations = createSelector(
    [getVisibleAdministratorsFilteredBYStatus, getFilterOrganisation],
    (listAdministrators, filterValue) => {


        if (!filterValue || filterValue.toLowerCase() === 'all')
            return listAdministrators

        return listAdministrators && listAdministrators.filter(item => item.organisation.toLowerCase() === filterValue.toLowerCase())
    }
)


export const getVisibleAdministratorsFilteredBYName = createSelector(
    [getVisibleAdministratorsFilteredBYOrganisations, getFilterName],
    (listAdministrators, filterValue) => {


        if (!filterValue || filterValue.trim() === '')
            return listAdministrators

        return listAdministrators && listAdministrators.filter(item => item.fullName.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)
    }
)


export const getVisibleAdministratorsFilteredByKeyword = createSelector(
    [getVisibleAdministratorsFilteredBYName, getKeyword],
    (visibleAdministrators, keyword) => {


        return visibleAdministrators && visibleAdministrators.filter(
            administrator => administrator.fullName.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                administrator.organisation.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
                administrator.userId.toString().indexOf(keyword.toLowerCase()) > -1
        )
    }
)


export const getAdministratorsSort = createSelector(
    [getVisibleAdministratorsFilteredByKeyword, getAdministratorSort],
    (listAdministrators, sort) => {
        if (!sort.column) {
            return listAdministrators;
        }
        return listAdministrators.sort(compareValues(sort.column, sort.order))
    }
)


export const getTotalPagesAdministrators = createSelector(
    [getAdministratorsSort, getRowsPerPageAdministrator],
    (list, rowsPerPage) =>
        list && Math.ceil(list.length / rowsPerPage)
)


export const getListAdministrators = createSelector(
    [getAdministratorsSort, getPageAdministrator, getRowsPerPageAdministrator],
    (list, page, rowsPerPage) =>
        list && list.slice((page - 1) * rowsPerPage, page * rowsPerPage)
)


export const getListOrganisation = createSelector(
    [getAdministrators],
    (list => {
            const field = 'organisation';
            let collection = [];
            list.forEach((item) => {

                if (collection.filter(value => value.organisation === item[field]).length === 0)
                    collection.push(item);

            });

            return collection;
        }
    )
);

