import React, {useEffect} from 'react';
import {
    Header,
    ItemTable,
    Modal,
    Select,
    DataRangeCalendar,
    PaginationTable,
    BackdropLoading
} from "../../components";
import {search, arrowsAlt, user, arrowDown, calendar} from "../../utils/icons";
import UploadFile from "../uploadFile";
import {format} from 'date-fns'
import {
    getStatus,
    getTotalPagesAssessment,
    getVisibleAssessmentFilteredByKeyword, getShowDateRange, getDateRangeStart, getDateRangeEnd,
} from "../../redux/selectors";
import {connect} from "react-redux";
import {
    initAssessment, setAssessmentSearch, setAssessmentFilter, setShowDateRange, clearCheckForUpdatesInterval,
    setAssessmentRowsPerPage, setPageAssessment, getAssessments, showModalAddAssessment, setUpdateViewAssessments
} from "../../redux/actions";
import cookie from "js-cookie";
import {withRouter} from 'react-router-dom';


const Home = (props) => {

    const {
        listAssessments, listStatus, listNames, totalPages, rowsPerPage, page, clearCheckForUpdatesInterval,
        setPageAssessment, setAssessmentRowsPerPage, isLoading, setShowDateRange, textSearch,
        showDateRange, dateRangeStart, dateRangeEnd, showModalAddAssessment, showModal
    } = props;

    useEffect(() => {

        if (!listAssessments || listAssessments.length == 0)
            props.getAssessments();
        else
            props.setUpdateViewAssessments();
        cookie.set("containerToShow", 'results', {sameSite: 'lax', secure: true})

        return () => {
            clearCheckForUpdatesInterval();
        }

    }, []);

    //

    const getDateString = () => {
        if (dateRangeStart && dateRangeEnd) {
            return format(dateRangeStart, 'dd-MM-yyyy') + " / " + format(dateRangeEnd, 'dd-MM-yyyy')
        }
        return "All"
    }

    return (


        <div className="home-container">


            <Header
                openModal={() => showModalAddAssessment(true)}
                title="Your Risk Assessments"
            />
            {<BackdropLoading isLoading={isLoading}/>}

            <div className="container-table fade-in">


                <Modal show={showModal} modalClosed={() => showModalAddAssessment(false)}>
                    <UploadFile setShowModal={showModalAddAssessment}/>
                </Modal>


                <div className="container-table-filters ">
                    <div className="filters filter-by-ref">
                        <div className="filter-title">
                            <span>SEARCH BY</span>
                        </div>
                        <div className="filter-content">
                            <input type="text"
                                   placeholder={"Contract/Region reference"}
                                   value={textSearch}
                                   onChange={(event) => props.setAssessmentSearch(event.target.value)}
                            />
                            <img className="search" src={search} alt="search-icons"/>

                        </div>

                    </div>

                    <div className="filters filter-by-name">
                        <div className="filter-title">
                            <img className="search" src={user} alt="user-icons"/>
                            <span>name</span>
                        </div>
                        <div className="filter-content">
                            <Select
                                list={listNames}
                                value={props.filterName}
                                type="name"
                                handleChange={props.setAssessmentFilter}
                            />
                        </div>

                    </div>

                    <div className="filters filter-by-status">

                        <div className="filter-title">
                            <img className="search" src={arrowsAlt} alt="arrowsAlt-icons"/>
                            <span>status</span>
                        </div>
                        <div className="filter-content">
                            <Select list={listStatus}
                                    value={props.filterStatus}
                                    type="status"
                                    handleChange={props.setAssessmentFilter}
                                    keyValue
                            />

                        </div>

                    </div>

                    <div className="filters filter-by-date">

                        <div className="filter-title">
                            <img className="search" src={calendar} alt="arrowsAlt-icons"/>
                            <span>DATE RANGE</span>
                        </div>
                        <button className="filter-content" onClick={() => setShowDateRange(!showDateRange)}>
                            <span>{getDateString()}</span>
                            <img src={arrowDown} className={showDateRange ? 'rotation' : ''} alt="arrow-down-icons"/>
                        </button>
                        {showDateRange && <DataRangeCalendar/>}

                    </div>

                </div>


                <table id="main-table">
                    <tr>
                        <th>REF.</th>
                        <th>PROJECT</th>
                        <th>TASK</th>
                        <th>APPROVAL BY</th>
                        <th>DATE/TIME</th>
                        <th>IMPROVEMENT</th>
                        <th className="status">STATUS</th>
                    </tr>

                    {
                        listAssessments && listAssessments.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((item, i) =>
                            <ItemTable key={item.riskAssessmentID} history={props.history} {...item}  />
                        )
                    }

                </table>


                <PaginationTable

                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setAssessmentRowsPerPage}
                    totalPages={totalPages}
                    totalItem={listAssessments && listAssessments.length}
                    page={page}
                    handleChangePage={(event, newPage) => setPageAssessment(newPage)}
                />

            </div>


        </div>


    );
}


const mapStateToProps = (state) => {

    const listAssessments = getVisibleAssessmentFilteredByKeyword(state);

    const listStatus = getStatus(state);
    const listNames = state.assessments.names;
    const filterStatus = state.assessments.filterStatus;
    const filterName = state.assessments.filterName;
    const textSearch = state.assessments.textSearch;

    const totalPages = getTotalPagesAssessment(state);
    const page = state.assessments.currentPage;
    const rowsPerPage = state.assessments.rowsPerPage;
    const isLoading = state.assessments.loading;
    const showModal = state.assessments.showAddAssessmentModal;


    const showDateRange = getShowDateRange(state);
    const dateRangeStart = getDateRangeStart(state);
    const dateRangeEnd = getDateRangeEnd(state);

    return {
        listAssessments, isLoading, listStatus, filterStatus, listNames, filterName, textSearch,
        totalPages, rowsPerPage, page, showDateRange, dateRangeStart, dateRangeEnd, showModal
    };
};


export default connect(mapStateToProps,
    {
        initAssessment, setAssessmentSearch, setAssessmentFilter, setPageAssessment,
        setAssessmentRowsPerPage, getAssessments, setShowDateRange, setUpdateViewAssessments,
        clearCheckForUpdatesInterval, showModalAddAssessment
    })(withRouter(Home));

