
export const getAuthEmail = state => state.auth.email;

export const getAuthRole = state => state.auth.role;

export const getAuthFullName = state => state.auth.fullName;

export const getAuthLoading = state => state.auth.loading;



