import {
    CLIENTS_INIT,
    CLIENTS_SET_SEARCH,
    CLIENTS_SET_ROW_PER_PAGE,
    CLIENTS_SET_FILTER,
    CLIENTS_ADD, CLIENTS_SET_PAGE,
    CLIENTS_EDIT, CLIENTS_REMOVE,
    CLIENTS_UPDATE, CLIENTS_SORT,
    CLIENTS_REQUEST_COMPLETED, CLIENTS_REQUEST,
    CLIENTS_REQUEST_ERROR,

} from "../constants";
import dataJson from "../../utils/users";

export default function reducer(state = {

    listClients: null,
    textSearch: '',
    rowsPerPage: 10,
    currentPage: 1,
    clientToEdit: null,
    names: [],
    clients: [],
    status: status,
    filterName: '',
    filterClient: 'All',
    filterRole: 'All',
    loading: false,

    sort: {
        column: null,
        order: null,
    },


}, {type, payload}) {

    switch (type) {

        case CLIENTS_INIT: {

            return {
                ...state,
                listClients: dataJson,
                names: getValue(dataJson, 'name', true),
                clients: getValue(dataJson, 'clientName'),
            }
        }

        case CLIENTS_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case CLIENTS_REQUEST_ERROR: {
            return {
                ...state,
                loading: false,
            }
        }

        case CLIENTS_REQUEST_COMPLETED: {

            // console.log("payload")
            // console.log(payload)
            return {
                ...state,
                loading: false,
                listClients: payload,
                names: getValue(payload, 'clientName', true),
                clients: getValue(payload, 'clientName'),
            }
        }


        case CLIENTS_ADD: {


            return {
                ...state,
                loading: false,
                listClients: {[payload.userId]: payload, ...state.listClients,}
            }
        }

        case CLIENTS_EDIT: {

            return {
                ...state,
                clientToEdit: state.listClients[payload],
            }
        }

        case CLIENTS_REMOVE: {

            delete state.listClients[payload];

            return {
                ...state,
                loading: false,
            }
        }

        case CLIENTS_UPDATE: {

            const users = {...state.listClients, [payload.userId]: payload};
            return {
                ...state,
                listClients: users,
                loading: false, userToEdit: null,
                names: getValue(Object.values(users, true), 'fullName'),
            }
        }

        case CLIENTS_SET_SEARCH: {

            return {
                ...state,
                ...payload,
            }
        }

        case CLIENTS_SET_FILTER: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }


        case CLIENTS_SET_ROW_PER_PAGE: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }

        case CLIENTS_SET_PAGE: {

            return {
                ...state,
                ...payload,
            }
        }


        case CLIENTS_SORT:

            let order = 'asc';

            if (payload.sort.column === state.sort.column) {
                if (state.sort.order === 'asc')
                    order = 'desc';

            }

            return {
                ...state,
                sort: {
                    column: payload.sort.column,
                    order: order,
                },
            }

        default: {
            return {
                ...state
            };
        }
    }
}


const getValue = (listObject, field, noAll) => {

    let collection = noAll ? [] : ["All"];


    if (!listObject)
        return collection;

    const list = Object.values(listObject);

    list.forEach((item) => {

        if (collection.filter(value => value === item[field]).length === 0)
            collection.push(item[field]);

    });

    return collection;
}

const status = [
    {key: 'All', value: 'All'},
    {key: 1, value: 'Yes'},
    {key: 0, value: 'No'},
];