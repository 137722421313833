import {
    USERS_INIT,
    USERS_SET_SEARCH,
    USERS_SET_ROW_PER_PAGE,
    USERS_SET_FILTER,
    USERS_ADD, USERS_SET_PAGE,
    USERS_EDIT, USERS_REMOVE,
    USERS_UPDATE, USERS_SORT,
    USER_REQUEST_COMPLETED, USER_REQUEST,
    USER_REQUEST_ERROR, USER_SET_CLIENTS,

} from "../constants";
import dataJson from "../../utils/users";

export default function reducer(state = {

    listUsers: null,
    textSearch: '',
    rowsPerPage: 10,
    currentPage: 1,
    userToEdit: null,
    names: [],
    clients: [],
    clientsListName: [],
    status: status,
    filterName: '',
    filterClient: 'All',
    filterRole: 'All',
    loading: false,

    sort: {
        column: null,
        order: null,
    },


}, {type, payload}) {

    switch (type) {

        case USERS_INIT: {


            return {
                ...state,
                listUsers: dataJson,
                names: getValue(dataJson, 'name', true),
            }
        }

        case USER_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }

        case USER_REQUEST_ERROR: {
            return {
                ...state,
                loading: false,
            }
        }

        case USER_REQUEST_COMPLETED: {


            return {
                ...state,
                loading: false,
                listUsers: payload,
                names: getValue(payload, 'fullName', true),
            }
        }


        case USER_SET_CLIENTS: {
            return {
                ...state,
                loading: false,
                // clients: payload,
                clientsListName: getValue(payload, 'clientName'),
                clients: payload,
            }
        }

        case USERS_ADD: {

            return {
                ...state,
                loading: false,
                listUsers: {[payload.userId]: payload, ...state.listUsers,}
            }
        }

        case USERS_EDIT: {

            return {
                ...state,
                userToEdit: state.listUsers[payload],
            }
        }

        case USERS_REMOVE: {

            delete state.listUsers[payload];

            return {
                ...state,
                loading: false,
            }
        }

        case USERS_UPDATE: {

            const users = {...state.listUsers, [payload.userId]: payload};
            return {
                ...state,
                listUsers: users,
                loading: false, userToEdit: null,
                names: getValue(Object.values(users, true), 'fullName'),
            }
        }

        case USERS_SET_SEARCH: {

            return {
                ...state,
                ...payload,
            }
        }

        case USERS_SET_FILTER: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }


        case USERS_SET_ROW_PER_PAGE: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }

        case USERS_SET_PAGE: {

            return {
                ...state,
                ...payload,
            }
        }


        case USERS_SORT:

            let order = 'asc';

            if (payload.sort.column === state.sort.column) {
                if (state.sort.order === 'asc')
                    order = 'desc';

            }

            return {
                ...state,
                sort: {
                    column: payload.sort.column,
                    order: order,
                },
            }

        default: {
            return {
                ...state
            };
        }
    }
}


const getValue = (listObject, field, noAll) => {

    let collection = noAll ? [] : ["All"];


    if (!listObject)
        return collection;

    const list = Object.values(listObject);

    list.forEach((item) => {

        if (collection.filter(value => value === item[field]).length === 0)
            collection.push(item[field]);

    });

    return collection;
}

const status = [
    {key: 'All', value: 'All'},
    {key: 3, value: 'Author'},
    {key: 2, value: 'Approver'},
];