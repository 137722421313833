import {
    DASHBOARD_GET_TOTAL,
    DASHBOARD_GET_TOTAL__COMPLETED,
    DASHBOARD_GET_TOTAL__ERROR,
} from "../constants";


export default function reducer(state = {

    adminTotal: 0,
    ontologyTotal: 0,
    userTotal: 0,
    totalClients: 0,
    isLoading: false,

}, {type, payload}) {

    switch (type) {

        case DASHBOARD_GET_TOTAL__COMPLETED: {

            return {
                ...state,
                ...payload,
                isLoading: false,

            }
        }


        case DASHBOARD_GET_TOTAL: {

            return {
                ...state,
                isLoading: true,
            }
        }

        case DASHBOARD_GET_TOTAL__ERROR: {

            return {
                ...state,
                isLoading: false,
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}