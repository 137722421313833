import {
    CLIENTS_INIT,
    CLIENTS_SET_SEARCH,
    CLIENTS_SET_ROW_PER_PAGE,
    CLIENTS_SET_FILTER,
    CLIENTS_EDIT, CLIENTS_REMOVE,
    CLIENTS_UPDATE, CLIENTS_ADD,
    CLIENTS_SORT,
    CLIENTS_SET_PAGE, CLIENTS_REQUEST, CLIENTS_REQUEST_COMPLETED, CLIENTS_REQUEST_ERROR,
} from "../constants";
import {toastr} from "react-redux-toastr";
import axios from "../../utils/axios-aurora";
import {handleError} from "./handleHttpError";
import {arrayToObject} from "../../utils/funcAux";
import message from "../../utils/messages";

export function startRequest() {
    return {
        type: CLIENTS_REQUEST,
    }
}

export function startClients() {

    return (dispatch, getState) => {

        const {auth} = getState();

        dispatch(startRequest());

        axios.get(`admin/client/clients?username=${auth.email}`)
            .then(res => {
                dispatch(requestClientsSuccessfully(res.data));
            })
            .catch(function (error) {
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


const requestClientsSuccessfully = (value) => {

    const val = arrayToObject(value, 'clientID');

    return {
        type: CLIENTS_REQUEST_COMPLETED,
        payload: val,
    }
}


export function initClient() {
    return {
        type: CLIENTS_INIT,
    }
}


export function addClient(data) {


    return (dispatch, getState) => {


        const {auth} = getState();
        dispatch(startRequest());

        axios.post(`admin/client/add/?username=${auth.email}&clientName=${data.clientName}&clientStatus=${data.status}`,
        )
            .then(res => {
                // dispatch(addClientSuccessfully(res.data));
                toastr.success(message.addClientSuccess);
                dispatch(startClients());
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                // dispatch(handleError(error));
            });


    }
}


export function addClientSuccessfully(data) {


    toastr.success(message.addClientSuccess);

    return {
        type: CLIENTS_ADD,
        payload: data
    }
}


export function editClient(id) {

    return {
        type: CLIENTS_EDIT,
        payload: id
    }
}


export function removeClient(id) {

    return (dispatch, getState) => {

        const {auth} = getState();
        dispatch(startRequest());

        axios.delete(`admin/client/delete/${id}?username=${auth.email}`)
            .then(res => {
                dispatch(removeClientSuccessfully(id));
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


export function removeClientSuccessfully(id) {

    toastr.success(message.removeClientSuccess);

    return {
        type: CLIENTS_REMOVE,
        payload: id
    }
}


export function updateClient(data) {


    return (dispatch, getState) => {

        const {auth} = getState();
        dispatch(startRequest());


        axios.post(`admin/client/update/${data.clientID}?username=${auth.email}&clientName=${data.clientName}&clientStatus=${data.status}`)
            .then(res => {
                dispatch(updateClientSuccessfully(data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.updateClientError);
                dispatch(requestError());
                // dispatch(handleError(error));
            });
    }

}


export function updateClientSuccessfully(data) {

    toastr.success(message.updateClientSuccess)
    return {
        type: CLIENTS_UPDATE,
        payload: data
    }
}

export function setClientSearch(value) {
    return {
        type: CLIENTS_SET_SEARCH,
        payload: {textSearch: value}
    }
}

export function setClientRowsPerPage(value) {

    return {
        type: CLIENTS_SET_ROW_PER_PAGE,
        payload: {rowsPerPage: value}
    }
}

export function setClientPage(value) {

    return {
        type: CLIENTS_SET_PAGE,
        payload: {currentPage: value}
    }
}

export function setClientFilter(type, value) {

    const _value = value === 'All' ? 'All' : value;

    if (type === 'clientName')
        return {
            type: CLIENTS_SET_FILTER,
            payload: {filterName: _value}
        }

    if (type === 'client')
        return {
            type: CLIENTS_SET_FILTER,
            payload: {filterClient: _value}
        }


    return {
        type: CLIENTS_SET_FILTER,
        payload: {filterRole: _value}
    }
}


export const setSortClients = (_column) => {

    return {
        type: CLIENTS_SORT,
        payload: {
            sort: {
                column: _column,
            },
        }
    };
};


const requestError = () => {
    return {
        type: CLIENTS_REQUEST_ERROR,
    }
}



