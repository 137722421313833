import React, {useState} from 'react';
import PropTypes from 'prop-types';

const Card = ({style,children, className, id}) => {

    return (

        <div key={id} style={style} className={`card ${className}`}>
            {children}
        </div>
    );
}

export default Card;

Card.propsTypes ={
    children: PropTypes.element.isRequired
}