import React from 'react';
import {iconsSolidCheck, iconsSolidTimes} from "../../../utils/icons";
import {Icon} from "../../../components";

const BestPracticeSection = () => {

    return (
        <div className="best-practice-section">
            <div className={"best-practice-content"}>
                <h2 className={"section-title"}>Best Practice</h2>
                <p className={"subtitle"}>Be specific and provide detail, this supports higher quality assessments and
                    better recommendations from your AI collaboration tool </p>
                <p className={"subtitle-left"}>Example activity description and good practice</p>
                <div className={"input-wrapper"}>
                    <div className={"inputs-col"}>
                        <div className={"input-item"}>
                            <span>Cutting</span>
                            <Icon path={iconsSolidTimes} alt="time-icon" iconclass={"icon-check"}/>
                        </div>
                        <div className={"input-item"}>
                            <span>Excavating</span>
                            <Icon path={iconsSolidTimes} alt="time-icon" iconclass={"icon-check"}/>
                        </div>
                    </div>
                    <div className={"inputs-col"}>
                        <div className={"input-item correct"}>
                            <span>Cutting Concrete pipe 450mm dia</span>
                            <Icon path={iconsSolidCheck} alt="plus-circle-icon" iconclass={"icon-check"}/>
                        </div>
                        <div className={"input-item correct"}>
                            <span>Excavating 2 meters deep in clay</span>
                            <Icon path={iconsSolidCheck} alt="plus-circle-icon" iconclass={"icon-check"}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BestPracticeSection;
