import React, {useState, useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import {Card, CustomReactTooltip, Header} from "../../components";

import {connect} from "react-redux";
import {getActivities, getMitigationsProgressBar, isMainLoading} from "../../redux/selectors";
import {useSelector,} from 'react-redux';
import {BackdropLoading} from "../../components";

const CronTester = ({history}) => {


    const _isLoading = useSelector(state => isMainLoading(state));
    const activities = useSelector(state => getActivities(state));

    useEffect(() => {


    }, []);


    return (

        <div className="results-container">

            <Header
                title="Run Cron tester "
            />
            {<BackdropLoading isLoading={_isLoading}/>}


            <div>

                <div className="summary-container fade-in">
                    <div className={"summary-content"}>
                        {activities && activities.map((activity, index) => <CardMain
                            key={index} {...activity} ></CardMain>)}
                    </div>
                </div>
            </div>

            <footer>

                <button
                    className="btn btn-secondary "
                    onClick={
                        () => {
                            history.push('/');
                        }
                    }
                >Home
                </button>


            </footer>

        </div>
    );
}


const mapStateToProps = (state) => {


    const lastActivity = state.activities.isProcessed;
    const progressBarMitigations = getMitigationsProgressBar(state);
    // setJobIdSelected
    return {lastActivity, progressBarMitigations};
};


export default connect(mapStateToProps, {})(withRouter(CronTester));


const CardMain = ({activity, Risks}) => {


    return (


        <div className="card-main">


            <div className="card-values-wrapper">


                {
                    Risks.map((risk, index) =>

                        <div key={"card-value" + index} className="card-values">

                            {index == 0 && <Card className="card-description">
                                <h5 className="activities-title">DESCRIPTION OF ACTIVITY</h5>
                                <p className="activities-text">{activity}</p>
                            </Card>}

                            {index !== 0 && <div className="card-description empty">

                            </div>}
                            <CardRisk risk={risk}/>
                            {/*<CardValue isRisk list={risk.risk} suggested = {suggested===1} />*/}
                            <CardValue list={risk.Mitigations}/>
                        </div>
                    )
                }


            </div>


        </div>
    )

}

const CardValue = ({list}) => {


    return (
        <Card className="card-value">

            {list.length > 0 && <h5 className="activities-title">{'CONTROL MEASURE'}</h5>}

            {
                list.map((item, index) =>
                    <div key={item.description} className="activities-text-container">

                        {
                            (item.intuetySuggestion != "none") &&
                            < div className={item.userAction === 'reject' ? "oval oval-removed" : "oval"}></div>

                        }


                        {
                            item.userAction === 'reject' ?
                                <p className="activities-text removed">{item.description + "(removed)"}</p> :
                                <p className={"activities-text"}>{item.description}</p>
                        }
                    </div>
                )
            }


        </Card>
    )
}

const CardRisk = ({risk}) => {


    return (
        <Card className="card-value">

            <h5 className="activities-title">{'HAZARD & ASSOCIATED RISK '}</h5>


            <div key={risk.risk.toString()} className="activities-text-container">
                <CustomReactTooltip/>

                {risk.intuetySuggestion != "none" && < div className="oval" data-tip="Intuety recommendation"></div>}

                <p className="activities-text">{risk.risk.toString()}</p>
            </div>


        </Card>
    )
}

