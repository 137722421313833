import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import Card from "./card";
import {BackdropLoading, Icon, Modal} from "../index";
import {closeModal, edit} from "../../utils/icons"
import logo from "../../../assets/imgs/intuety-logo.svg";
import {useDispatch, useSelector} from "react-redux";
import {getAssessmentSelectedComment, getAuthRole, isLoadingAssessment} from "../../redux/selectors";
import {saveCommentAssessment} from "../../redux/actions";
import CircularProgress from '@material-ui/core/CircularProgress';
import {userRole} from "../../utils/funcAux";


const CardComment = () => {

    const [showModal, setShowModal] = useState(false);


    const dispatch = useDispatch();
    const _userRole = useSelector(state => getAuthRole(state));
    const _summaryComments = useSelector(state => getAssessmentSelectedComment(state));
    const _isLoadingAssessment = useSelector(state => isLoadingAssessment(state));
    const [comments, setComments] = useState(_summaryComments);


    useEffect(() => {
        setComments(_summaryComments);


    }, [_isLoadingAssessment, _summaryComments]);


    const isApprover = () => _userRole == userRole.approver;

    let content = (
        <>

            <Modal show={showModal} modalClosed={() => setShowModal(false)}>

                <div className={"card-comment-modal"}>

                    <div className={"card-comment-modal-header"}>
                        <img src={logo} className="intuety-logo" alt="logo"/>

                        <button className="icon-menu-modal" onClick={() => setShowModal(false)}>
                            <img src={closeModal} alt="close-icon"/>
                        </button>

                    </div>

                    <div className={"card-comment-modal-content"}>
                        <h2>Add a Comment: Method Statement</h2>

                        <div className={"card-comment-modal-content-body"}>

                                <textarea value={comments} onChange={(event) => setComments(event.target.value)}>

                                </textarea>

                            <hr/>

                            <div className={"btn-container"}>

                                <button className={"btn btn-secondary"} onClick={() => setShowModal(false)}>CANCEL
                                </button>
                                <button disabled={_isLoadingAssessment} className={"btn btn-primary"} onClick={() => {

                                    dispatch(
                                        saveCommentAssessment(
                                            comments,
                                            () => setShowModal(false)))
                                }
                                }>
                                    {_isLoadingAssessment ?
                                        <CircularProgress size={18} color="white"/> :
                                        <span>SAVE</span>
                                    }

                                </button>

                            </div>
                        </div>
                    </div>

                </div>

            </Modal>


            {
                (comments.length == 0) ?


                    <> {isApprover() && <div className={"card-comment-empty"}>

                        <button onClick={() => setShowModal(true)}>
                            <Icon alt={"icon-edit"} path={edit}/>
                            <span>ADD A COMMENT</span>
                        </button>

                    </div>}
                    </>

                    :

                    <Card className={"card-comment"}>

                        <div className={"card-comment-header"}>
                            <h4>APPROVERS COMMENTS</h4>

                            {isApprover() &&
                            <button onClick={() => setShowModal(true)} data-tip="Edit Comments">
                                <Icon alt={"icon-edit"} path={edit}/>
                            </button>
                            }

                        </div>

                        <textarea
                            className={"card-comment-container"}
                            value={comments}
                            readOnly
                        />

                    </Card>

            }

        </>
    );


    return content;
}

export default CardComment;


CardComment.propsTypes = {
    children: PropTypes.element.isRequired
}