import React, {useState, Fragment} from 'react';
import hazard from "../../../../assets/imgs/hazard-infographic.png";
import lighblub from "../../../../assets/imgs/lightbulb-infographic.png";
import {useSelector, useDispatch} from 'react-redux';
import {getActivitiesIdentified, getActivitiesWithSuggestions, isLoading,} from "../../../redux/selectors";
import cookie from "js-cookie";


const Results = ({jobId}) => {


    const identified = useSelector(state => getActivitiesIdentified(state));
    const suggested = useSelector(state => getActivitiesWithSuggestions(state));
    const _qualityScore = cookie.get("qualityScore") ? JSON.parse(cookie.get("qualityScore")) : 0;

    return (

        <main className="results-body">


            <div className="score-container">
                <span>Risk assessment quality score: <b>{_qualityScore ? _qualityScore.toFixed(0) : 0}%</b></span>
            </div>

            <div className="activities-container">

                <div className="activities-content">
                    <h5>
                        <span>ACTIVITIES</span> <br/>
                        <span>IDENTIFIED</span>
                    </h5>

                    <img src={hazard} className="App-logo" alt="hazard-infographic"/>

                    <div className="result-value hazard">
                        <span>{identified}</span>
                    </div>
                </div>


                <div className="line"/>


                <div className="activities-content">

                    <h5>
                        <span>ACTIVITIES</span> <br/>
                        <span>WITH SUGGESTIONS</span>
                    </h5>

                    <img src={lighblub} className="App-logo" alt="lightbulb-infographic"/>

                    <div className="result-value lightbulb">
                        <span>{suggested}</span>
                    </div>

                </div>

            </div>

        </main>
    );
}

export default Results;

