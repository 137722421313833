import React, {useEffect, useState} from 'react';
import Card from "./card";
import {Icon} from "../index";
import {
    check,
    times,
    plusCircle,
    minusCircle,
    checkRed,
    checkAccept,
    checkAcceptGray,
    timesRed
} from "../../utils/icons";
import {getActivities} from "../../redux/selectors";
import {connect} from "react-redux";
import {setActivityFilter, setRiskAction, setMitigationAction} from "../../redux/actions";
import CircularProgress from '@material-ui/core/CircularProgress';

const CardMeasure = (props) => {

    const [state, setState] = useState(1);
    const [userAction, setUserAction] = useState(0);
    const [userActionSeleted, setUserActionSeleted] = useState(null);


    const {
        title, subtitle, level, isRisks, removed, isDescriptionOfActivity, elementUserAction,
        index, lastMitigationsIndex, updateId, updateError, id, loading
    } = props;
    const [showAction, setShowAction] = useState(!isDescriptionOfActivity);


    useEffect(() => {
        if (elementUserAction && (elementUserAction == "accept" || elementUserAction == "reject")) {
            setShowAction(false);
            if (!isRisks)
                setState(4);
        }

        if (isRisks && index != -1) {
            setShowAction(false);
        }

    }, []);


    useEffect(() => {


        if (updateId === id && !showAction) {
            if (updateError) {
                setTimeout(() => {
                    setShowAction(true);
                    setUserActionSeleted(null);
                }, 300);
            } else {
                setState(userAction);
                setTimeout(() => setState(4), 2000);
            }
        }


    }, [updateId, showAction]);

    const getStyle = () => {

        if (state === 1)
            return {marginLeft: `${level * 30}px`};

        if (state === 2)
            return {
                marginLeft: `${level * 30}px`,
                background: "rgba(219, 238, 235, 0.7)",
                border: "solid 1px #59d6c6"
            }

        if (state === 3)
            return {
                marginLeft: `${level * 30}px`,
                background: "rgba(255, 160, 122, 0.2)",
                border: "solid 1px #FFA07A"
            }

        if (state === 4)
            return {
                marginLeft: `${level * 30}px`,
                background: "#ebecec",
                border: "solid 1px #c9c9ca"
            }

    }

    const getTextColor = () => {

        if (state === 1)
            return "#002855";

        if (state === 2)
            return "#14b29e";

        if (state === 3)
            return "#FFA07A";

        if (state === 4)
            return "#808285";

    }

    const isLoading = () => loading && loading === id;

    // when do the button next will fixed the current bug - reset index in next
    const handleUpdateState = (value) => {


        setShowAction(false);

        if (isRisks) {
            props.setRiskAction(value == 2 ? "accept" : "reject", id);
        } else {

            setUserAction(value);
            props.setMitigationAction(value == 2 ? "accept" : "reject", id, lastMitigationsIndex);

            setUserActionSeleted(value == 2 ? "accept" : "reject");




        }


    }
    return (


        <Card style={getStyle()} key={`${title}-${state}`} className={"fade-in"}>
            <div className={"card-content"}
                 style={{borderRight: (!isDescriptionOfActivity && showAction || isLoading()) ? 'solid 1px #e3e4e2' : ''}}>
                {state === 1 && <h6 className={"subtitle"}>{subtitle}</h6>}

                <div style={{color: getTextColor()}} className={`state-${state}`}>
                    <span>{title}</span>
                    {(state === 2) && <Icon path={checkAccept} alt="plus-circle-icon" iconclass={"icon-check"}/>}
                    {(state === 3) && <Icon path={timesRed} alt="plus-circle-icon" iconclass={"icon-check"}/>}
                    {(state === 4) &&
                    <Icon path={(userActionSeleted == "accept") ? checkAcceptGray : times} alt="plus-circle-icon"
                          iconclass={"icon-check"}/>}
                </div>
            </div>

            {
                showAction &&
                <div className={"card-btns"}>


                    <Icon path={(removed) ? minusCircle : plusCircle} alt="plus-circle-icon" iconclass={"icon-plus "}/>

                    <div className={"card-btns-actions "}>
                        <button onClick={() => handleUpdateState(2)}>
                            <Icon path={(removed) ? checkRed : check} alt="plus-circle-icon" iconclass={"icon-check"}/>
                        </button>

                        <button onClick={() => handleUpdateState(3)}>
                            <Icon path={times} alt="plus-circle-icon " iconclass={"icon-close"}/>
                        </button>
                    </div>

                    {loading && <CircularProgress size={24} className={"buttonProgress"}/>}

                </div>
            }


            {

                (isLoading() && !showAction) &&
                <div className={"card-btns"}>


                    <Icon path={(removed) ? minusCircle : plusCircle} alt="plus-circle-icon"
                          iconclass={"icon-plus disable"}/>

                    <div className={"card-btns-actions disable"}>
                        <div>
                            <Icon path={(removed) ? checkRed : check} alt="plus-circle-icon" iconclass={"icon-check"}/>
                        </div>

                        <div>
                            <Icon path={times} alt="plus-circle-icon disable" iconclass={"icon-close"}/>
                        </div>
                    </div>

                    {loading && <CircularProgress size={24} className={"buttonProgress"}/>}

                </div>
            }
        </Card>
    );
}


const mapStateToProps = (state) => {
    const listActivities = getActivities(state);

    const assessmentIndex = state.activities.assessmentIndex;
    const riskIndex = state.activities.riskIndex;
    const index = state.activities.index;
    const updateId = state.activities.updateId;
    const updateError = state.activities.updateError;
    const lastMitigationsIndex = state.activities.lastMitigationsIndex;
    const loading = state.activities.loading;
    return {listActivities, assessmentIndex, riskIndex, index, lastMitigationsIndex, updateId, updateError, loading};
};


export default connect(mapStateToProps, {setActivityFilter, setRiskAction, setMitigationAction})(CardMeasure);




