import Moment from 'react-moment';
import React from "react";
import PropTypes from "prop-types";


 const  FormatDate = ({date}) => (
    <Moment format="D MMM YYYY" withTitle>{date}</Moment>
)

export default FormatDate;


FormatDate.propsTypes ={
    date:PropTypes.string.isRequired
}