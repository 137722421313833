import React, {useEffect, useState } from 'react';
import {BackdropLoading, Header, Icon} from "../../../components";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {
    initUsers, setUserSearch, setUserFilter, setUserRowsPerPage,
    editUser, setSortUser
} from "../../../redux/actions";

import AddEditModal from "./addEdit";
import DialogBox from "./dialogBox";
import UsersTable from "./usersTable";
const Users = (props) => {


    const [showModal, setShowModal] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(0);

    const {editUser, isLoading} = props;


    const handleAddTerm = () => {
        editUser(null);
        setShowModal(true)
    }


    return (


        <div className="dashboard-table">

            <Header
                title="Users"
                buttonRight
                buttonRightText="Add User"
                buttonRightFunc={handleAddTerm}
            />

            {showModal && <AddEditModal open={showModal} setOpen={setShowModal}/>}
            {showDialogBox !== 0 && <DialogBox open={showDialogBox} setOpen={setShowDialogBox}/>}
            {<BackdropLoading isLoading={isLoading} />}


            <UsersTable setShowModal={setShowModal} setShowDialogBox={setShowDialogBox}/>

        </div>

    );
}


const mapStateToProps = (state) => {

    const isLoading = state.users.loading;

    return { isLoading };
};

export default connect(mapStateToProps,
    {
        initUsers, setUserSearch, setUserFilter, setUserRowsPerPage,
        editUser, setSortUser
    })(withRouter(Users));


