import React from 'react';



const ProgressBar = (props) => {

    return (

        <div
            className={(props.percentage>0)?"progress-bar":"progress-bar progress-bar-empty"}>
            <div className="filler" style={{ width: `${props.percentage}%` }} />
            {props.percentage2 &&<div className="filler2" style={{ width: `${props.percentage2}%` }} />}
            {props.circle &&<div className="circle"  style={{ marginLeft: `${props.percentage-1}%` }} />}
            {props.circle &&<div className="percentage-box"  style={{ marginLeft: `${props.percentage}%` }}>{props.percentage}%</div>}
        </div>


    );
}

export default ProgressBar;


