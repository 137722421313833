import React from 'react';
import ReactTooltip from "react-tooltip";


const CustomReactTooltip = (props) => {


    return (

        <ReactTooltip effect="solid"
            delayHide={200}
            delayShow={100}
            className="react-tooltip"
            backgroundColor="#8899a4"
            offset="{'top': -5, 'left': 0}"
            />
    );
}

export default CustomReactTooltip;

