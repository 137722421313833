import {createSelector} from "reselect";

export const getActivities = state => state.activities.listActivities;

export const currentMitigationsIndex = state => state.activities.index;

export const currentRiskIndex = state => state.activities.riskIndex;

export const currentActivityIndex = state => state.activities.assessmentIndex;

export const currentRequireApproved = state => state.activities.requireApproved;


export const isActivityEnd = state => state.activities.lastActivityIndex && state.activities.lastRiskIndex && state.activities.lastMitigationsIndex;

export const getJobIdSelected = state => state.activities.jobIdSelected;

export const isLoading = state => state.activities.loading;

export const isMainLoading = state => state.activities.mainLoading;

export const getActivitiesIdentified = state => state.activities.activitiesIdentified;

export const getActivitiesWithSuggestions = state => state.activities.activitiesWithSuggestions;


export const getCurrentActivity = createSelector(
    [getActivities, currentActivityIndex],
    (list, currentActivity) => {


        if (!list || list.length == 0 || currentActivity < 0)
            return [];

        return list[currentActivity];
    }
)

export const getActivitiesToPrint = createSelector(
    [getActivities],
    (listActivities) => {
        let list = [];
        let added;
        if (listActivities)
            for (let i = 0; i < listActivities.length; i++) {
                let listRisks = listActivities[i].Risks;
                let _activity = listActivities[i];
                added = false;
                let risk;
                let _listRisks = [];
                if (listRisks)
                    for (let j = 0; j < listRisks.length; j++) {
                        risk = listRisks[j];

                        if (risk.riskUserAction === "accept" || risk.intuetySuggestion === "none") {
                            added = true;
                            // break;
                            let _listMitigation = [];
                            for (let k = 0; k < risk.Mitigations.length; k++) {
                                const mitigation = risk.Mitigations[k];

                                if (mitigation.userAction === "accept" || mitigation.intuetySuggestion === "none") {
                                    _listMitigation.push(mitigation)
                                }
                            }
                            if (_listMitigation.length > 0) {
                                risk.Mitigations = _listMitigation;
                                _listRisks.push(risk);
                            }

                        }

                    }

                _activity.Risks = _listRisks;
                if (added)
                    list.push(_activity);
            }


        return list;
    }
)


export const getActivitiesLength = createSelector(
    [getActivities, currentActivityIndex],
    (list) => {


        let sum = 0;
        if (list) {
            for (let i = 0; i < list.length; i++) {
                if (list[i].intuetyToProcess)
                    sum++;
            }
        }


        return sum;
    }
)

export const getNumOfRisksWhichRequireApproval = createSelector(
    [getActivities],
    (list) => {

        let total = 0;
        if (!list)
            return total;

        for (let i = 0; i < list.length; i++) {
            const activity = list[i];

            if (activity.intuetyToProcess === false)
                continue;


            const _listRisks = activity.Risks;
            const listNumRiskstoApprover = _listRisks.filter(__risk => __risk.numOfMitigationsWhichRequireApproval > 0 && __risk.intuetySuggestion !== 'none');
            total += listNumRiskstoApprover.length ?? 0;


            for (let j = 0; j < activity.Risks.length; j++) {

                const risks = activity.Risks[j];

                total += risks.numOfMitigationsWhichRequireApproval
            }
        }


        return total;
    }
)


export const getCurrentRisk = createSelector(
    [getCurrentActivity, currentRiskIndex],
    (list, currentRisk) => {


        if (!list || list.length == 0 || currentRisk < 0)
            return [];


        return list.Risks[currentRisk];
    }
)


export const getCurrentMitigationIndexNone = createSelector(
    [getCurrentRisk, currentMitigationsIndex],
    (list, _currentMitigationIndex) => {


        if (!list || !list.Mitigations || list.Mitigations.length == 0)
            return 0;

        let count = 0;

        if (_currentMitigationIndex !== "-1")
            for (let i = 0; i < list.Mitigations.length; i++)
                if (i <= _currentMitigationIndex && list.Mitigations[i].intuetySuggestion === "none")
                    count++
        return count;
    }
)


export const getCurrentMitigations = createSelector(
    [getCurrentRisk],
    (list) => {

        if (!list || !list.Mitigations || list.Mitigations.length == 0)
            return [];

        return list.Mitigations && list.Mitigations.filter(mitigation => mitigation.intuetySuggestion != 'none');
    }
)


export const getProgressBarTotal = state => {
    const totalActivities = getActivities(state);
    const _currentActivity = currentActivityIndex(state);

    if (!totalActivities || totalActivities.length == 0 || _currentActivity < 0)
        return 0;


    const list = getActivities(state);


    let total = 0;

    for (let i = 0; i < list.length && i <= _currentActivity; i++) {
        const activity = list[i];

        if (activity.intuetyToProcess === false)
            continue;

        const _listRisks = activity.Risks;
        const listNumRiskstoApprover = _listRisks.filter(__risk => __risk.numOfMitigationsWhichRequireApproval > 0 && __risk.intuetySuggestion !== 'none');
        total += listNumRiskstoApprover.length ?? 0;

        for (let j = 0; j < activity.Risks.length; j++) {

            const risks = activity.Risks[j];

            total += risks.numOfMitigationsWhichRequireApproval

        }
    }

    const totalRiskApprover = getNumOfRisksWhichRequireApproval(state);

    const res = total / totalRiskApprover * 100;


    return (!res && res != 0) ? 100 : res;
}


export const getMitigationsProgressBar = createSelector(
    [getActivities, currentRequireApproved, getNumOfRisksWhichRequireApproval],
    (list, _currentRiskApprover, _numRisksWhichRequireApproval) => {

        if (!list || list.length == 0)
            return 0;

        const res = _currentRiskApprover / _numRisksWhichRequireApproval * 100;

        return (!res && res != 0) ? 100 : res;
    }
)

export const getTotalRisk = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.reduce((total, activities) => total + activities.Risks.length, 0);
    }
)

export const getTotalRiskRequireApproval = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.reduce((total, activities) => total + activities.numOfRisksWhichRequireApproval ?? 0, 0);
    }
)


export const getTotalRiskRequireApproved = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;


        return list.reduce(getSumTotalRiskRequireApproved, 0);
    }
);

const getSumTotalRiskRequireApproved = (total, activities) => {

    let _total = 0;
    activities.Risks.forEach(risk => {
        _total += risk.riskUserAction === "accept" ? 1 : 0;
    });

    return total + _total;
};


export const getTotalMitigation = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.reduce(getSumTotalMitigation, 0);
    }
);

const getSumTotalMitigation = (total, activities) => {

    let _total = 0;
    activities.Risks.forEach(item => {
        _total += item.Mitigations.length ?? 0;
    });

    return total + _total;
};

export const getTotalMitigationRequireApproval = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.reduce(getSumTotalMitigationRequireApproval, 0);
    }
);

const getSumTotalMitigationRequireApproval = (total, activities) => {

    let _total = 0;
    activities.Risks.forEach(item => {
        // if(item.intuetySuggestion !== "none")
        _total += item.numOfMitigationsWhichRequireApproval ?? 0;
    });

    return total + _total;
};


export const getTotalMitigationRequireApproved = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;
        return list.reduce(getSumTotalMitigationRequireApproved, 0);
    }
);


const getSumTotalMitigationRequireApproved = (total, activities) => {

    let _total = 0;
    activities.Risks.forEach(risk => {

        risk.Mitigations.forEach(mitigations => {
            _total += mitigations.userAction === "accept" ? 1 : 0;
        })
    });

    return total + _total;
};


export const getTotalActivity = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.length;
    }
);


export const getTotalActivityRequireApproval = createSelector(
    [getActivities,],
    (list) => {

        if (!list)
            return 0;

        return list.reduce((total, activities) => total + (activities.intuetyToProcess ? 1 : 0), 0);
    }
);

