import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';


const BackdropLoading = ({isLoading}) => {

    return (

        <Backdrop className={"backdropLoading"} open={isLoading} style={{zIndex: 9999}}>
            <CircularProgress color="#5EBFB2"/>
        </Backdrop>

    );
}

export default BackdropLoading;


BackdropLoading.defaultProps = {
    isLoading: false
}


BackdropLoading.propsTypes = {
    isLoading: PropTypes.bool.isRequired
}