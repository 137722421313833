import React, {Component, useEffect, useState} from 'react';
import {connect} from "react-redux";
import {
    updateAnnotationPosition,
    addAnnotation,
    setAnnotation,
    removeAnnotation,
    updateAnnotation,
    getFilesURL
} from "../../redux/actions";

import {
    PdfLoader,
    PdfHighlighter,
    Tip,
    Highlight,
    Popup,
    AreaHighlight
} from "react-pdf-highlighter";

import Spinner from "./Spinner";
import Sidebar from "./Sidebar";

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {Link} from "react-router-dom";
import logo from "../../../assets/imgs/intuety-logo.svg";
import {close, menu, plus} from "../../utils/icons";
import {getAuthEmail, getAuthFullName, getAuthRole, getMethodStatementPdfURL} from "../../redux/selectors";
import {userRole} from "../../utils/funcAux";


const getNextId = () => String(Math.random()).slice(2);

const parseIdFromHash = () =>
    document.location.hash.slice("#highlight-".length);

const resetHash = () => {
    document.location.hash = "";
};

const HighlightPopup = ({comment}) =>
    comment.text ? (
        <div className="Highlight__popup">
            <span> {comment.emoji} {comment.text}</span>
        </div>
    ) : null;


class PDFHighlighter extends Component {


    constructor(props) {
        super(props);
        this.state = {showSideBar: props.role == userRole.author ? false : true};
    }

    scrollViewerTo = (highlight) => {
    };

    scrollToHighlightFromHash = () => {
        const highlight = this.getHighlightById(parseIdFromHash());

        if (highlight) {
            this.scrollViewerTo(highlight);
        }
    };

    componentDidMount() {
        window.addEventListener(
            "hashchange",
            this.scrollToHighlightFromHash,
            false
        );

    }


    changeSidebarStatus() {
        this.setState({
            showSideBar: !this.state.showSideBar,
        })
    }

    getHighlightById(id) {
        const {highlights} = this.props;
        return highlights.find(highlight => highlight.id === id);
    }

    addHighlight(highlight) {
        const {highlights, fullName, email} = this.props;


        highlight.time = new Date();
        highlight.approvalName = fullName;
        highlight.approvalID = email;

        this.props.addAnnotation({...highlight, id: getNextId()});
    }

    updateHighlight = (highlight, position, content) => {
        // console.log("Updating highlight", highlight, position, content);
        if (this.isApprover())
            this.props.updateAnnotationPosition(highlight, position, content);
    }

    isApprover() {
        return this.props.role == userRole.approver;
    }


    render() {
        const {highlights, highlightModal, setHighlightModal, pdfURL} = this.props;
        const {showSideBar} = this.state;


        return (

            <div
                className={"method-statement-modal"}
            >

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    open={highlightModal}
                    onClose={() => setHighlightModal(false)}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={highlightModal}>
                        <div
                            className={"method-statement-modal-main"}
                        >

                            <ModalHeader _handleClose={() => setHighlightModal(false)}/>
                            <div className="App" className={"method-statement-modal-content"}>


                                <div
                                    style={{
                                        height: "100vh",
                                        width: showSideBar ? '75vw' : '88vw',
                                        overflowY: "scroll",
                                        position: "relative",
                                        transition: "width 140ms ease-in",
                                    }}
                                >

                                    <PdfLoader url={pdfURL} beforeLoad={<Spinner/>}>
                                        {pdfDocument => (
                                            <PdfHighlighter
                                                pdfDocument={pdfDocument}
                                                enableAreaSelection={event => event.altKey}
                                                onScrollChange={resetHash}
                                                scrollRef={scrollTo => {

                                                    this.scrollViewerTo = scrollTo;

                                                    this.scrollToHighlightFromHash();
                                                }}
                                                onSelectionFinished={(
                                                    position,
                                                    content,
                                                    hideTipAndSelection,
                                                    transformSelection
                                                ) => (

                                                    this.isApprover() ? <Tip
                                                        onOpen={transformSelection}
                                                        onConfirm={comment => {
                                                            this.addHighlight({content, position, comment});
                                                            hideTipAndSelection();
                                                        }}
                                                    /> : <span onUpdate={() => {
                                                    }}></span>
                                                )}

                                                highlightTransform={(
                                                    highlight,
                                                    index,
                                                    setTip,
                                                    hideTip,
                                                    viewportToScaled,
                                                    screenshot,
                                                    isScrolledTo
                                                ) => {
                                                    const isTextHighlight = !Boolean(
                                                        highlight.content && highlight.content.image
                                                    );

                                                    const component = isTextHighlight ? (
                                                        <Highlight
                                                            isScrolledTo={isScrolledTo}
                                                            position={highlight.position}
                                                            comment={highlight.comment}
                                                        />
                                                    ) : (
                                                        <AreaHighlight
                                                            highlight={highlight}


                                                            onChange={boundingRect => {

                                                                this.updateHighlight(
                                                                    highlight,
                                                                    {boundingRect: viewportToScaled(boundingRect)},
                                                                    {image: screenshot(boundingRect)}
                                                                );

                                                            }}
                                                        />
                                                    );


                                                    return (
                                                        <Popup
                                                            popupContent={<HighlightPopup {...highlight} />}
                                                            onMouseOver={popupContent =>
                                                                setTip(highlight, highlight => popupContent)
                                                            }
                                                            onMouseOut={hideTip}
                                                            key={index}
                                                            children={component}
                                                        />
                                                    );
                                                }}
                                                highlights={highlights}
                                            />
                                        )}
                                    </PdfLoader>
                                </div>

                                <div className={"sidebar-main"} style={{width: showSideBar ? '25vw' : '12vw'}}>
                                    <button
                                        className={"title-sidebar"}
                                        onClick={() => this.changeSidebarStatus()}
                                    >
                                        {/*<img className="icon-menu" src={commentImage}  alt="comment"/>*/}
                                        <div className={'number-highlights-container'}>
                                            <span>{highlights && highlights.length}</span>
                                        </div>
                                        <div>COMMENT ADDED</div>
                                    </button>


                                    <Sidebar
                                        highlights={highlights}
                                        showSideBar={showSideBar}
                                        editable={this.isApprover()}/>
                                </div>


                            </div>
                        </div>
                    </Fade>
                </Modal>

            </div>


        );
    }
}


const mapStateToProps = (state) => {


    const role = getAuthRole(state);
    const fullName = getAuthFullName(state);
    const email = getAuthEmail(state);
    const pdfURL = getMethodStatementPdfURL(state);

    return {role, fullName, email, pdfURL};
};


export default connect(mapStateToProps,
    {
        addAnnotation,
        setAnnotation,
        removeAnnotation,
        updateAnnotation,
        updateAnnotationPosition,
        getFilesURL
    })(PDFHighlighter);


const ModalHeader = ({_handleClose}) => {

    return (

        <header id="nav" className="header-container header-modal">
            <nav className="bounds nav">
                <Link to="/">
                    <img src={logo} className="header-logo" alt="logo"/>
                </Link>
                <h2 className="header-title">{"Method Statement"}</h2>
                <div>

                    <button
                        className="btn-menu"
                        onClick={() => _handleClose()}
                    >
                        <div>
                            <span>Close</span>
                            <img className="icon-menu" src={close} alt="menu"/>
                        </div>

                    </button>
                </div>
            </nav>

        </header>
    );
}