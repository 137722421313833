import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import {ValidatorComponent} from 'react-form-validator-core';


export default class ValidatorInput extends ValidatorComponent {


    render() {
        /* eslint-disable no-unused-vars */
        const {
            error,
            errorMessages,
            validators,
            requiredError,
            helperText,
            validatorListener,
            withRequiredValidator,
            onBlur,
            placeholder,
            // required,
            className,
            value,
            type,
            handle,
            ...rest
        } = this.props;
        const {isValid} = this.state;


        return (
            <TextField
                {...rest}
                error={!isValid || error}
                helperText={(!isValid && this.getErrorMessage()) || helperText}
                value={value}
                label={placeholder}
                type={type ?? 'text'}
                variant="filled"
                fullWidth
                size={"small"}
                className={className}
                onBlur={onBlur}

            />
        );
    }
}

ValidatorInput.propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    error: PropTypes.bool,
    id: PropTypes.string,
    className: PropTypes.string,
    handle: PropTypes.func,

}