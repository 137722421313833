import React from 'react';
import {
    Header,
    Modal,
} from "../../components";
import UploadFile from "../uploadFile";
import BestPracticeSection from "./BestPracticeSection/bestPracticeSection";
import TableSection from "./TableSection/tableSection";
import UploadDocumentSection from "./UploadDocumentSection/uploadDocumentSection";
import {connect} from "react-redux";
import {
    initAssessment, setAssessmentSearch, setAssessmentFilter, setShowDateRange, clearCheckForUpdatesInterval,
    setAssessmentRowsPerPage, setPageAssessment, getAssessments, showModalAddAssessment, setUpdateViewAssessments
} from "../../redux/actions";
import {withRouter} from 'react-router-dom';


const Home = (props) => {

    const {showModalAddAssessment, showModal} = props;

    return (

        <div className="intuety-guidance">


            <Header
                openModal={() => showModalAddAssessment(true)}
                title="Intuety Guidance"
            />

            <div className=" fade-in">

                <Modal show={showModal} modalClosed={() => showModalAddAssessment(false)}>
                    <UploadFile setShowModal={showModalAddAssessment}/>
                </Modal>

                <div>
                    <TableSection></TableSection>
                    <UploadDocumentSection></UploadDocumentSection>
                    <BestPracticeSection></BestPracticeSection>
                </div>


            </div>

        </div>

    );
}


const mapStateToProps = (state) => {

    const isLoading = state.assessments.loading;
    const showModal = state.assessments.showAddAssessmentModal;

    return {isLoading, showModal};
};


export default connect(mapStateToProps,
    {
        initAssessment, setAssessmentSearch, setAssessmentFilter, setPageAssessment,
        setAssessmentRowsPerPage, getAssessments, setShowDateRange, setUpdateViewAssessments,
        clearCheckForUpdatesInterval, showModalAddAssessment
    })(withRouter(Home));

