import React from 'react';
import tableImg from "../../../../assets/imgs/safety_risk_assessment.png";

const TableSection = () => {

    return (

        <div className="table-section">
            <h2 className={"section-title"}>Risk Assessment Document Structure Requirements</h2>

            <div className={"table-content"}>

                <img src={tableImg}/>
            </div>
        </div>
    );
}

export default TableSection;

