import React, {useEffect} from 'react';
import {logout} from "../../utils/icons";
import {
    Link,
    withRouter
} from 'react-router-dom'
import {connect} from "react-redux";
import {changeNav, logoutUser} from "../../redux/actions";
import {userRole} from "../../utils/funcAux";


const NavBar = ({history, changeNav, logoutUser, role}) => {

    const isCurrentPath = value => {
        if (history && history.location && history.location.pathname)
            return history.location.pathname === value;
        return false
    }
    return (

        <div className="nav-content">
            <div className="nav-item-header">
                <button
                    onClick={() => {
                        changeNav();
                        setTimeout(() => {
                            logoutUser();
                            history.push('/');
                        }, 300)
                    }}
                >

                    <span>Log Out</span>
                    <img src={logout} className="logout-logo" alt="logout-icon"/>
                </button>
            </div>

            {(role === userRole.author || role === userRole.approver) &&
            <NavItem to="/" changeNav={changeNav} active={isCurrentPath('/')} text="Your Risk Assessments"/>}
            {(role === userRole.author || role === userRole.approver) &&
            <NavItem to="/intuety-guidance" changeNav={changeNav} active={isCurrentPath('/intuety-guidance')}
                     text="Intuety Guidance"/>}
            {(role === userRole.admin) &&
            <NavItem to="/dashboard" changeNav={changeNav} active={isCurrentPath('/dashboard')} text="Dashboard"/>}
            {(role === userRole.admin) &&
            <NavItem to="/users" changeNav={changeNav} active={isCurrentPath('/users')} text="Users"/>}
            {(role === userRole.admin) &&
            <NavItem to="/administrators" changeNav={changeNav} active={isCurrentPath('/administrators')}
                     text="Administrators"/>}
            {(role === userRole.admin) &&
            <NavItem to="/clients" changeNav={changeNav} active={isCurrentPath('/clients')} text="Clients"/>}
            {(role === userRole.admin) &&
            <NavItem to="/ontology" changeNav={changeNav} active={isCurrentPath('/ontology')} text="Ontology"/>}


        </div>
    );
}


const mapStateToProps = (state) => {

    const role = state.auth.role;
    return {role};

};

export default connect(mapStateToProps, {changeNav, logoutUser})(withRouter(NavBar));


const NavItem = ({text, to, active, changeNav}) => {

    return (
        <Link className={(active) ? "nav-item active" : "nav-item"} onClick={changeNav} to={to}>
            {text}
        </Link>


    )
}
