import React, {useState} from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';


const PaginationTable = ({rowsPerPage, setRowsPerPage, totalPages, page, handleChangePage, totalItem}) => {


    return (

        <div className="pagination-wrapper">


            <div>
                <span className={"selector-label"} style={{marginRight: '20px'}}>Total : {totalItem ?? 0}</span>

                <span className={"selector-label"}>Num per page</span>

                <Select
                    value={rowsPerPage}
                    onChange={(event) => setRowsPerPage(event.target.value)}
                    displayEmpty
                    inputProps={{'aria-label': 'Without label'}}
                >

                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                </Select>


            </div>

            <Pagination count={totalPages} page={page} onChange={handleChangePage}/>

        </div>
    );
}

export default PaginationTable;
