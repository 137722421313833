import axios from 'axios';
import cookie from "js-cookie";
import {generateString} from "./funcAux";

const axiosInstance = axios.create({

    // baseURL: 'aurora/api/v1/',     //production
    baseURL: 'api/v1/',   //localholst

    xsrfCookieName: 'CSRF-TOKEN',  // default: XSRF-TOKEN
    xsrfHeaderName: 'X-CSRF-TOKEN',   // default: X-XSRF-TOKEN

})


axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {


        if (error.response.status === 401) {
// dispatch something to your store
        }

        if (error.response.status === 404) {
// dispatch something to your store
        }

        return Promise.reject(error);
    }
)


axiosInstance.interceptors.request.use(
    (request) => {

        if(request.method =='post' || request.method =='put' || request.method =='patch' || request.method =='delete'){
            const csrfToken = generateString();
            // axiosInstance.defaults.headers.common['X-CSRF-TOKEN']=csrfToken;
            cookie.set("CSRF-TOKEN", csrfToken, { sameSite: 'lax', secure: true } );
        }
         return  Promise.resolve(request);
    },
    (error) => {

        return Promise.reject(error);
    }
)

axiosInstance.defaults.headers.common = {};
axiosInstance.defaults.headers.common.accept = 'application/json';
axiosInstance.defaults.withCredentials = true;

export default axiosInstance;