import {combineReducers} from "redux"
import {reducer as toastrReducer} from 'react-redux-toastr'

import Navigation from "./Navigation"
import NavBar from "./navBar"
import Activities from "./activities"
import Assessment from "./assessment"
import Ontologies from "./ontologies"
import Users from "./users"
import Administrators from "./administrators"
import Clients from "./clients"
import Dashboard from "./dashboard"
import Auth from "./auth"
import Annotation from "./annotation"


export default combineReducers({
    auth: Auth,
    navigation: Navigation,
    navBar: NavBar,
    activities: Activities,
    assessments: Assessment,
    ontologies: Ontologies,
    users: Users,
    administrators: Administrators,
    dashboard: Dashboard,
    clients: Clients,
    annotations: Annotation,

    toastr: toastrReducer //

})
