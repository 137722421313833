import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {closeModal, cvsFile, docFile} from "../../../utils/icons";
import {ValidatorInput} from "../../../components";
import {ValidatorForm} from "react-material-ui-form-validator";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import {addAdministrator, updateAdministrator} from "../../../redux/actions";
import {
    getListOrganisation,
} from "../../../redux/selectors";

const initDate = {
    fullName: '',
    organisationID: '',
    roleID: 1,
    password: '',
    email: '',
    active: '',
}

function AddEditModal(props) {
    const classes = useStyles();
    const {open, setOpen, addAdministrator, administratorToEdit, updateAdministrator, listOrganisations} = props;


    const handleClose = () => {
        setOpen(false);
    };


    const [formData, setFormData] = useState(administratorToEdit ? administratorToEdit : initDate);
    const nameRef = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();


    const handleSubmit = () => {


        if (administratorToEdit) {

            updateAdministrator(
                {
                    ...administratorToEdit,
                    ...formData
                }
            )
        } else
            addAdministrator(formData);

        setOpen(false);

    }


    const handleStatus = (event) => {
        setFormData({...formData, active: event.target.value});
    }


    const handleOrganisation = (event) => {
        setFormData({...formData, organisationID: event.target.value});
    }


    const handleBlur = event => {
        const {name, value} = event.target;
        if (name == "name") {
            nameRef.current.validate(value, true);
        }

        if (name == "email") {
            emailRef.current.validate(value, true);
        }

        if (name == "password") {
            passwordRef.current.validate(value, true);
        }
    }


    return (


        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className="form-create-edit-Wrapper">

                        <button className="icon-menu-modal" onClick={() => setOpen(false)}>
                            <img src={closeModal} alt="menu"/>
                        </button>


                        <ValidatorForm className="form-container"
                                       onSubmit={handleSubmit}
                                       instantValidate={false}>


                            <div className={"fields-wrapper"}>


                                <ValidatorInput
                                    ref={nameRef}
                                    placeholder={"Name"}
                                    value={formData.fullName}
                                    // onChange={handleParent}
                                    onChange={(event) => setFormData({...formData, fullName: event.target.value})}
                                    className="custom-input input-login "
                                    type='text'
                                    name='name'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <ValidatorInput
                                    ref={emailRef}
                                    placeholder={"Email"}
                                    value={formData.email}
                                    // onChange={handleParent}
                                    onChange={(event) => setFormData({...formData, email: event.target.value})}
                                    className="custom-input input-login "
                                    type='email'
                                    name='email'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required", 'isEmail']}
                                    errorMessages={["this field is required"]}
                                />


                                <ValidatorInput
                                    ref={passwordRef}
                                    placeholder={"Password"}
                                    value={formData.password}
                                    onChange={(event) => setFormData({...formData, password: event.target.value})}
                                    className="custom-input input-login "
                                    type='password'
                                    name='password'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={administratorToEdit ? [] : ["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Organisation</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.organisationID}
                                        onChange={handleOrganisation}
                                    >
                                        {
                                            listOrganisations && listOrganisations.map(({organisation, organisationID}) =>
                                                <MenuItem className="menu-item-status"
                                                          value={organisationID}>{organisation}</MenuItem>
                                            )
                                        }


                                    </Select>
                                </div>


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Active</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.active}
                                        onChange={handleStatus}
                                    >
                                        <MenuItem className="menu-item-status" value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </div>


                                <hr/>

                            </div>


                            <div className="form-footer-container">

                                <button className="btn btn-secondary"
                                        onClick={() => props.setOpen(false)}>CANCEL
                                </button>
                                <button className="btn btn-primary"
                                        type='submit'>{(administratorToEdit) ? 'UPDATE' : 'ADD'}</button>

                            </div>

                        </ValidatorForm>

                    </div>

                </Fade>
            </Modal>
        </div>


    );
}


const mapStateToProps = (state) => {

    const administratorToEdit = state.administrators.administratorToEdit;
    const listOrganisations = getListOrganisation(state);
    return {administratorToEdit, listOrganisations};

};


export default connect(mapStateToProps,
    {addAdministrator, updateAdministrator})(AddEditModal);


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));