import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {closeModal, cvsFile, docFile} from "../../../utils/icons";
import {ValidatorInput} from "../../../components";
import {ValidatorForm} from "react-material-ui-form-validator";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import {addOntology, updateOntology} from "../../../redux/actions";


const initDate = {
    parent: '',
    children: '',
    active: true,
    created: "",
}

function AddEditModal(props) {
    const classes = useStyles();
    const {open, setOpen, addOntology, ontologyToEdit, updateOntology} = props;


    const handleClose = () => {
        setOpen(false);
    };


    const [formData, setFormData] = useState(ontologyToEdit ? ontologyToEdit : initDate);

    const parentRef = useRef();
    const childrenRef = useRef();


    const handleSubmit = () => {

        if (ontologyToEdit)
            updateOntology(formData)
        else
            addOntology(formData);

        setOpen(false);

    }

    const handleParent = (event) => {
        setFormData({...formData, parent: event.target.value});
    }

    const handleChildren = (event) => {
        setFormData({...formData, children: event.target.value});
    }

    const handleStatus = (event) => {
        setFormData({...formData, active: event.target.value});
    }


    const handleBlur = event => {
        const {name, value} = event.target;
        if (name == "parent") {
            parentRef.current.validate(value, true);
        }

        if (name == "children") {
            childrenRef.current.validate(value, true);
        }
    }


    return (


        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className="form-create-edit-Wrapper">

                        <button className="icon-menu-modal" onClick={() => setOpen(false)}>
                            <img src={closeModal} alt="menu"/>
                        </button>


                        <ValidatorForm className="form-container"
                                       onSubmit={handleSubmit}
                                       instantValidate={false}>


                            <div className={"fields-wrapper"}>


                                <ValidatorInput
                                    ref={parentRef}
                                    placeholder={"Parent"}
                                    value={formData.parent}
                                    onChange={handleParent}
                                    className="custom-input input-login "
                                    type='parent'
                                    name='parent'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <ValidatorInput
                                    ref={childrenRef}
                                    placeholder={"Children"}
                                    value={formData.children}
                                    onChange={handleChildren}
                                    className="custom-input input-login"
                                    type='parent'
                                    name='parent'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Status</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"

                                        value={formData.active}
                                        onChange={handleStatus}
                                    >
                                        <MenuItem className="menu-item-status" value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </div>

                                <hr/>

                            </div>


                            <div className="form-footer-container">

                                <button className="btn btn-secondary"
                                        onClick={() => props.setOpen(false)}>CANCEL
                                </button>
                                <button className="btn btn-primary"
                                        type='submit'>{(ontologyToEdit) ? 'UPDATE' : 'ADD'}</button>

                            </div>

                        </ValidatorForm>

                    </div>

                </Fade>
            </Modal>
        </div>


    );
}


const mapStateToProps = (state) => {

    const ontologyToEdit = state.ontologies.ontologyToEdit;
    return {ontologyToEdit,};

};


export default connect(mapStateToProps,
    {addOntology, updateOntology})(AddEditModal);


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));