import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "react-redux";
import {removeAdministrator} from "../../../redux/actions";

function DialogBox(props) {

    const {open, setOpen, removeAdministrator} = props;
    // constant open store the id to remove

    const handleClose = () => {
        setOpen(0);
    };

    const handleRemove = () => {
        removeAdministrator(open)
        setOpen(0);
    };


    return (
        <div>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="dialogBox4354"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure?"}</DialogTitle>
                <DialogContent className="dialog-box-container">
                    <DialogContentText id="alert-dialog-description">
                        Do you really want to delete this admin? This process cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClose} className="btn btn-secondary">
                        Cancel
                    </button>
                    <button onClick={handleRemove} className="btn btn-primary">
                        Confirm
                    </button>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default connect(null, {removeAdministrator})(DialogBox);


