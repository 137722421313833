import React from 'react';
import {
    iconDoc,
    iconPdf,
    iconXls,
    iconsSolidCheckGray,
    iconsSolidCheckGreen,
    iconsSolidCheckOrange,
    iconUpload
} from "../../../utils/icons";
import {Icon} from "../../../components";


const UploadDocumentSection = (props) => {


    return (

        <div className="upload-document-section">
            <h2 className={"section-title"}>Upload Document Format Needs</h2>

            <div className={"upload-document-container"}>

                <div className={"upload-document-item"}>
                    <Icon path={iconDoc} alt="time-icon" iconclass={"icon-check"}/>
                    <div className={"title orange"}>WORKS WELL</div>
                    <div>
                        <Icon path={iconsSolidCheckOrange} alt="time-icon" iconclass={"icon-check"}/>
                        <Icon path={iconsSolidCheckOrange} alt="time-icon" iconclass={"icon-check"}/>
                    </div>
                </div>

                <div className={"upload-document-item correct"}>
                    <div className={"oval"}>
                        <Icon path={iconUpload} alt="time-icon" iconclass={"icon-check"}/>
                    </div>
                    <Icon path={iconPdf} alt="time-icon" iconclass={"icon-check"}/>
                    <div className={"title green"}>THE PERFECT FORMAT</div>
                    <div>
                        <Icon path={iconsSolidCheckGreen} alt="time-icon" iconclass={"icon-check"}/>
                        <Icon path={iconsSolidCheckGreen} alt="time-icon" iconclass={"icon-check"}/>
                        <Icon path={iconsSolidCheckGreen} alt="time-icon" iconclass={"icon-check"}/>
                    </div>
                </div>

                <div className={"upload-document-item"}>
                    <Icon path={iconXls} alt="time-icon" iconclass={"icon-check"}/>
                    <div className={"title gray"}>TRY TO AVOID</div>
                    <div>
                        <Icon path={iconsSolidCheckGray} alt="time-icon" iconclass={"icon-check"}/>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadDocumentSection;

