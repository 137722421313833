import {
    DASHBOARD_GET_TOTAL,
    DASHBOARD_GET_TOTAL__COMPLETED,
    DASHBOARD_GET_TOTAL__ERROR,

} from "../constants";
import axios from "../../utils/axios-aurora";

import {handleError} from './handleHttpError';


export function getTotalDashboard() {

    return (dispatch, getState) => {
        const {email} = getState().auth;
        dispatch(dashboardRequest())


        axios.get(`admin/total?username=${email}`)
            .then(res => {
                dispatch(dashboardSuccessfully(res.data));
            })
            .catch(function (error) {
                dispatch(dashboardError());
                dispatch(handleError(error));
            });


    }

}

//use for test
export function getProjects() {


    return (dispatch, getState) => {
        const {email} = getState().auth;


        axios.get(`projects?username=${email}`)
            .then(res => {
                // console.log("projects")

            })
            .catch(function (error) {
                // console.log(error);
                // alert(342)

            });
    }

}

export function dashboardRequest() {
    return {
        type: DASHBOARD_GET_TOTAL,
    }
}

export function dashboardSuccessfully(value) {
    return {
        type: DASHBOARD_GET_TOTAL__COMPLETED,
        payload: value
    }
}

export function dashboardError() {
    return {
        type: DASHBOARD_GET_TOTAL__ERROR,
    }
}

