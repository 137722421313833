const gen4 = () =>  Math.random().toString(16).slice(-4);


const  generateUniqueId = (prefix)  =>{
    (prefix || '').concat([
        gen4(),
        gen4(),
        gen4(),
        gen4(),
    ].join(''))
}

const arrayToObject=(list, key) => Object.assign({}, ...list.map(item => ({ [item[key]]: item })));




//   // array is sorted by band, in ascending order by default
// singers.sort(compareValues('band'));
// // array is sorted by band in descending order
// singers.sort(compareValues('band', 'desc'));
// // array is sorted by name in ascending order
// singers.sort(compareValues('name'));
// // array is sorted by date if birth in descending order
// singers.sort(compareValues('born', 'desc'));

const compareValues=(key, order = 'asc') => {
    return function innerSort(a, b) {
        if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
            // property doesn't exist on either object
            return 0;
        }

        const varA = (typeof a[key] === 'string')
            ? a[key].toUpperCase() : a[key];
        const varB = (typeof b[key] === 'string')
            ? b[key].toUpperCase() : b[key];

        let comparison = 0;
        if (varA > varB) {
            comparison = 1;
        } else if (varA < varB) {
            comparison = -1;
        }
        return (
            (order === 'desc') ? (comparison * -1) : comparison
        );
    };
}

const userRole ={
    author:"ROLE_AUTHOR",
    admin:"ROLE_ADMIN",
    approver:"ROLE_APPROVER",
    ontology:"ROLE_ONTOLOGY",
}


function generateString(a){return a?(a^Math.random()*16>>a/4).toString(16):([1e16]+1e16).replace(/[01]/g,generateString)};




export  {generateUniqueId, generateString, arrayToObject, compareValues, userRole };