import {
    USERS_INIT,
    USERS_SET_SEARCH,
    USERS_SET_ROW_PER_PAGE,
    USERS_SET_FILTER,
    USERS_EDIT, USERS_REMOVE,
    USERS_UPDATE, USERS_ADD,
    USERS_SORT, USER_SET_CLIENTS,
    USERS_SET_PAGE, USER_REQUEST, USER_REQUEST_COMPLETED, USER_REQUEST_ERROR,
} from "../constants";
import {toastr} from "react-redux-toastr";
import axios from "../../utils/axios-aurora";
import {handleError} from "./handleHttpError";
import {arrayToObject} from "../../utils/funcAux";
import message from "../../utils/messages";

export function startRequest() {
    return {
        type: USER_REQUEST,
    }
}


export function startUsers() {

    return (dispatch, getState) => {

        const {auth} = getState();

        dispatch(startRequest());

        axios.get(`admin/users?username=${auth.email}`)
            .then(res => {
                dispatch(requestUsersSuccessfully(res.data));
                dispatch(getClients())

            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


export function getClients() {

    return (dispatch, getState) => {

        const {auth} = getState();

        dispatch(startRequest());

        axios.get(`admin/client/clients?username=${auth.email}`)
            .then(res => {

                dispatch(setListClients(res.data));

            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}

const setListClients = (value) => {
    return {
        type: USER_SET_CLIENTS,
        payload: value,
    }
}

const requestUsersSuccessfully = (value) => {

    const val = arrayToObject(value, 'userId');

    return {
        type: USER_REQUEST_COMPLETED,
        payload: val,
    }
}


export function initUsers() {
    return {
        type: USERS_INIT,
    }
}


export function addUser(data) {


    return (dispatch, getState) => {

        const {auth} = getState();
        dispatch(startRequest());

        axios.post(`admin/user/add/?username=${auth.email}&clientId=${data.clientID}`,
            {
                "email": data.email,
                "password": data.password,
                "fullName": data.fullName,
                "roleID": data.roleId,
                "clientId": data.clientID,
                "active": data.active,
            }
        )
            .then(res => {
                dispatch(addUserSuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                toastr.warning('User already exist!');

                // dispatch(handleError(error));
            });
    }
}


export function addUserSuccessfully(data) {


    toastr.success(message.addUserSuccess);

    return {
        type: USERS_ADD,
        payload: data
    }
}


export function editUser(id) {

    return {
        type: USERS_EDIT,
        payload: id
    }
}


export function removeUser(id) {


    return (dispatch, getState) => {

        const {auth} = getState();
        dispatch(startRequest());

        axios.delete(`admin/user/delete/${id}?username=${auth.email}`)
            .then(res => {
                dispatch(removeUserSuccessfully(id));
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


export function removeUserSuccessfully(id) {

    toastr.success(message.removeUserSuccess);

    return {
        type: USERS_REMOVE,
        payload: id
    }
}


export function updateUser(data) {


    return (dispatch, getState) => {

        const {auth} = getState();
        dispatch(startRequest());

        let body = {
            "email": data.email,
            "password": data.password,
            "fullName": data.fullName,
            "roleID": data.roleId,
            "clientId": data.clientID,
            "active": data.active,

        }

        if (!data.password || data.password.trim() == '')
            delete body.password;


        axios.patch(`admin/patchUser/${data.userId}?username=${auth.email}`, body)
            .then(res => {
                dispatch(updateUserSuccessfully(data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.updateUserError);
                dispatch(requestError());
                // dispatch(handleError(error));
            });
    }

}


export function updateUserSuccessfully(data) {

    toastr.success(message.updateUserSuccess)
    return {
        type: USERS_UPDATE,
        payload: data
    }
}


export function setUserSearch(value) {
    return {
        type: USERS_SET_SEARCH,
        payload: {textSearch: value}
    }
}

export function setUserRowsPerPage(value) {

    return {
        type: USERS_SET_ROW_PER_PAGE,
        payload: {rowsPerPage: value}
    }
}

export function setUserPage(value) {

    return {
        type: USERS_SET_PAGE,
        payload: {currentPage: value}
    }
}

export function setUserFilter(type, value) {

    const _value = value === 'All' ? 'All' : value;

    if (type === 'fullName')
        return {
            type: USERS_SET_FILTER,
            payload: {filterName: _value}
        }

    if (type === 'client')
        return {
            type: USERS_SET_FILTER,
            payload: {filterClient: _value}
        }


    return {
        type: USERS_SET_FILTER,
        payload: {filterRole: _value}
    }
}


export const setSortUser = (_column) => {

    return {
        type: USERS_SORT,
        payload: {
            sort: {
                column: _column,
            },
        }
    };
};


const requestError = () => {
    return {
        type: USER_REQUEST_ERROR,
    }
}



