import {
    ONTOLOGY_SET_SEARCH,
    ONTOLOGY_SET_ROW_PER_PAGE,
    ONTOLOGY_ADD,
    ONTOLOGY_UPDATE, ONTOLOGY_EDIT,
    ONTOLOGY_SET_PAGE, ONTOLOGY_REQUEST,
    ONTOLOGY_REMOVE, ONTOLOGY_SORT, ONTOLOGY_REQUEST_COMPLETED, ONTOLOGY_REQUEST_ERROR, ONTOLOGY_UPDATE_CHILDREN
} from "../constants";

import {toastr} from 'react-redux-toastr'
import axios from '../../utils/axios-aurora'
import {arrayToObject} from "../../utils/funcAux";
import message from "../../utils/messages";
import {handleError} from "./handleHttpError";


export function startOntologies() {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(startRequest())

        axios.get(`admin/ontology?username=${email}`,
        )
            .then(res => {
                dispatch(requestSuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });


    }

}


export function startRequest() {
    return {
        type: ONTOLOGY_REQUEST,
    }
}

const requestSuccessfully = (value) => {

    const val = arrayToObject(value, 'ontologyID');

    return {
        type: ONTOLOGY_REQUEST_COMPLETED,
        payload: val,
    }
}

const requestError = () => {
    return {
        type: ONTOLOGY_REQUEST_ERROR,
    }
}

export function addOntology(data) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(loadingData());

        axios.post(`admin/ontology/add?username=${email}`,
            {
                "parent": data.parent,
                "children": data.children,
                "active": data.active
            },
        )
            .then(res => {

                dispatch(addOntologySuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.addTermError);
                dispatch(requestError());
            });
    }
}


const addOntologySuccessfully = (data) => {

    toastr.success('Add', message.addTermSuccess);

    return {
        type: ONTOLOGY_ADD,
        payload: data
    }
}


export function editOntology(id) {

    return {
        type: ONTOLOGY_EDIT,
        payload: id
    }
}


export function updateOntology(data) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(loadingData());

        axios.put(`admin/ontology/update/${data.ontologyID}?username=${email}`,
            {
                "parent": data.parent,
                "children": data.children,
                "active": data.active
            },
        )
            .then(res => {

                dispatch(updateOntologySuccessfully(data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.updateOntologiesError);
                dispatch(requestError());
            });
    }


}


const loadingData = () => {

    return {
        type: ONTOLOGY_REQUEST,
    }
}


const updateOntologySuccessfully = (data) => {

    toastr.success('Update', message.updateOntologiesSuccess);
    return {
        type: ONTOLOGY_UPDATE,
        payload: data
    }
}


const updateOntologyChildrenSuccessfully = (id, children) => {

    toastr.success('Update', message.updateOntologiesChildrenSuccess);
    return {
        type: ONTOLOGY_UPDATE_CHILDREN,
        payload: {
            id: id,
            children: children
        }
    }
}


export function updateChildrenOntology(ontologyID, children) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(loadingData());

        axios.put(`admin/ontology/update/children/${ontologyID}?username=${email}`,
            {
                "children": children,
            }
        )
            .then(res => {
                // console.log(res.data)
                dispatch(updateOntologyChildrenSuccessfully(ontologyID, children));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.updateOntologiesChildrenError);
                dispatch(requestError());
            });

    }
}


export function removeOntology(id) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(loadingData());

        axios.delete(`admin/ontology/delete/${id}?username=${email}`)
            .then(res => {
                dispatch(removeOntologySuccessfully(id));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.removeOntologiesError);
                dispatch(requestError());
            });
    }

}

function removeOntologySuccessfully(id) {

    return {
        type: ONTOLOGY_REMOVE,
        payload: id
    }
}

export function setOntologySearch(value) {
    return {
        type: ONTOLOGY_SET_SEARCH,
        payload: {textSearch: value}
    }
}


export function setPage(value) {
    return {
        type: ONTOLOGY_SET_PAGE,
        payload: value
    }
}

export function setRowsPerPage(value) {
    return {
        type: ONTOLOGY_SET_ROW_PER_PAGE,
        payload: {rowsPerPage: value}
    }
}


export const setSortOntology = (_column) => {

    return {
        type: ONTOLOGY_SORT,
        payload: {
            sort: {
                column: _column,
            },
        }
    };
};
