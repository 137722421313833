import {createSelector} from 'reselect'
import {compareValues} from "../../utils/funcAux";
import {getClients} from "./clients";


export const getOntologies = state => state.ontologies.listOntology && Object.values(state.ontologies.listOntology);

export const getOntologyRowsPerPage = state => state.ontologies.rowsPerPage;

export const getOntologyCurrentPage = state => state.ontologies.currentPage;

export const getKeyword = state => state.ontologies.textSearch;

export const getOntologySort = state => state.ontologies.sort;


export const getVisibleOntologiesFilteredByKeyword = createSelector(
    [getOntologies, getKeyword],
    (listOntologies, keyword) =>
        listOntologies && listOntologies.filter(ontologies =>
        ontologies.parent.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        ontologies.children.toLowerCase().indexOf(keyword.toLowerCase()) > -1 ||
        ontologies.ontologyID.toString().indexOf(keyword.toLowerCase()) > -1)
)


export const getTotalPages = createSelector(
    [getVisibleOntologiesFilteredByKeyword, getOntologyRowsPerPage],
    (listOntologies, rowsPerPage) =>
        listOntologies && Math.ceil(listOntologies.length / rowsPerPage)
)


export const getListOntologySort = createSelector(
    [getVisibleOntologiesFilteredByKeyword, getOntologySort],
    (list, sort) => {
        if (!sort.column) {
            return list;
        }
        return list.sort(compareValues(sort.column, sort.order))
    }
)


export const getListOntology = createSelector(
    [getListOntologySort, getOntologyRowsPerPage, getOntologyCurrentPage],
    (list, rowsPerPage, page,) =>
        list && list.slice((page - 1) * rowsPerPage, page * rowsPerPage)
)


export const getNumberTotalOntology = createSelector(
    [getOntologies],
    (listOntologies) => {

        if (!listOntologies)
            return 0

        return listOntologies.length;
    }
)


