import React, {createRef, useState, useCallback} from 'react';

import {useDropzone} from "react-dropzone"
import {connect} from "react-redux";
import {changeNav} from "../../redux/actions";
import {closeModal, upload} from "../../utils/icons";
import {ProgressBar} from "../index";


const DropzoneUpload = (props) => {

    const {myFiles, setMyFiles, dropzoneRef} = props;
    const [progress, setProgress] = useState(0);


    const onDrop = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // Do whatever you want with the file contents
                const binaryStr = reader.result

                // reader.onprogress = (ev) => console.log(ev);
            }
            reader.readAsArrayBuffer(file);

            reader.onprogress = function (data) {
                if (data.lengthComputable) {
                    let progress = parseInt(((data.loaded / data.total) * 100), 10);
                    setProgress(progress);
                }
            }

        })

        setMyFiles([...myFiles, ...acceptedFiles]);
    }, [])


    const {getRootProps, getInputProps} = useDropzone({
        onDrop,
        maxFiles: 1,
        accept: ".csv, .doc, .docx, application/vnd.ms-excel, text/csv, .pdf"

    })


    const removeAll = () => {
        setMyFiles([])
        setProgress(0);
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';

        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const filesInfo = myFiles.map(file => (


        <span key={file.path}>
            {file.path}
            <span className="file-info-title-size">{formatBytes(file.size)}</span>
        </span>

    ))


    const openDialog = () => {
        // Note that the ref is set async,
        // so it might be null at some point
        if (dropzoneRef.current) {
            dropzoneRef.current.open()
        }
    };


    const {icon} = props;

    return (

        // Disable click and keydown behavior on the <Dropzone>


        <div className="container">
            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                <img src={upload} alt="upload-icon"/>
                <p className="drop-title">
                    <span>Drag and drop your</span>
                    <span>{props.title}</span>
                </p>
                <p className="or">or</p>
                <button
                    type="button"
                    className="btn-primary"
                    onClick={openDialog}
                >
                    SELECT FILE
                </button>
            </div>

            {myFiles.length > 0 ?

                <aside className="file-info-wrapper">

                    <img className="file-info-icon" src={icon} alt="upload-icon"/>


                    <div className="file-info-content">

                        <div className="file-info-title">

                            <div className="file-info-title-name-size">
                                            <span className="file-info-title-name">

                                                {filesInfo}

                                            </span>
                            </div>

                            <button onClick={() => removeAll()}>
                                <img className="file-info-title-close" src={closeModal} alt="menu"/>
                            </button>


                        </div>

                        <ProgressBar percentage={progress}/>

                        <div className="file-info-text">
                            Processing… {progress}%
                        </div>

                    </div>


                </aside>
                :
                <div style={{height: '130px'}}/>

            }

        </div>


    );
}


const mapStateToProps = ({navBar,}) => {
    const {menuOpen} = navBar;
    return {menuOpen};
};

export default connect(mapStateToProps, {changeNav})(DropzoneUpload);

