import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {closeModal, cvsFile, docFile} from "../../../utils/icons";
import {ValidatorInput} from "../../../components";
import {ValidatorForm} from "react-material-ui-form-validator";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import {addUser, updateUser} from "../../../redux/actions";
import {getListClient} from "../../../redux/selectors";


const initDate = {
    fullName: '',
    clientID: '',
    // organisation : '',
    email: '',
    password: '',
    roleId: '',
    active: 'true',
    created: "",
}

function AddEditModal(props) {
    const classes = useStyles();
    const {open, setOpen, addUser, userToEdit, updateUser, listClients} = props;


    const handleClose = () => {
        setOpen(false);
    };

    const [formData, setFormData] = useState(userToEdit ? userToEdit : initDate);

    const fullNameRef = useRef();
    const clientRef = useRef();
    const passwordRef = useRef();
    const emailRef = useRef();


    const handleSubmit = () => {


        if (userToEdit)
            updateUser(
                {
                    ...userToEdit,
                    ...formData
                }
            )
        else
            addUser(formData);

        setOpen(false);

    }

    const handleRole = (event) => {
        setFormData({...formData, roleId: event.target.value});
    }

    const handleClient = (event) => {
        setFormData({...formData, clientID: event.target.value});
    }

    const handleStatus = (event) => {
        setFormData({...formData, active: event.target.value});
    }


    const handleBlur = event => {
        const {name, value} = event.target;
        if (name == "fullName") {
            fullNameRef.current.validate(value, true);
        }

        if (name == "client") {
            clientRef.current.validate(value, true);
        }

        if (name == "password") {
            passwordRef.current.validate(value, true);
        }

        if (name == "email") {
            emailRef.current.validate(value, true);
        }
    }


    return (


        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className="form-create-edit-Wrapper">

                        <button className="icon-menu-modal" onClick={() => setOpen(false)}>
                            <img src={closeModal} alt="menu"/>
                        </button>


                        <ValidatorForm className="form-container"
                                       onSubmit={handleSubmit}
                                       instantValidate={false}>


                            <div className={"fields-wrapper"}>


                                <ValidatorInput
                                    ref={fullNameRef}
                                    placeholder={"Name"}
                                    value={formData.fullName}
                                    // onChange={handleParent}
                                    onChange={(event) => setFormData({...formData, fullName: event.target.value})}
                                    className="custom-input input-login "
                                    type='fullName'
                                    name='fullName'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Client</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.clientID}
                                        onChange={handleClient}
                                    >
                                        {
                                            listClients.map(({clientName, clientID}) =>
                                                <MenuItem className="menu-item-status"
                                                          value={clientID}>{clientName}</MenuItem>
                                            )
                                        }

                                    </Select>
                                </div>


                                <ValidatorInput
                                    ref={emailRef}
                                    placeholder={"Email"}
                                    value={formData.email}
                                    // onChange={handleParent}
                                    onChange={(event) => setFormData({...formData, email: event.target.value})}
                                    className="custom-input input-login "
                                    type='email'
                                    name='email'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required", 'isEmail']}
                                    errorMessages={["this field is required"]}
                                />


                                {!userToEdit && <ValidatorInput
                                    ref={passwordRef}
                                    placeholder={"Password"}
                                    value={formData.password}
                                    onChange={(event) => setFormData({...formData, password: event.target.value})}
                                    className="custom-input input-login "
                                    type='password'
                                    name='password'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={userToEdit ? [] : ["required"]}
                                    errorMessages={["this field is required"]}
                                />
                                }


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Role</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.roleId}
                                        onChange={handleRole}
                                    >
                                        <MenuItem className="menu-item-status" value={3}>Author</MenuItem>
                                        <MenuItem value={2}>Approver</MenuItem>
                                        {/*<MenuItem value={1}>Admin</MenuItem>*/}
                                        {/*<MenuItem value={4}>Ontology</MenuItem>*/}
                                    </Select>
                                </div>

                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Status</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.active}
                                        onChange={handleStatus}
                                    >
                                        <MenuItem className="menu-item-status" value={true}>Yes</MenuItem>
                                        <MenuItem value={false}>No</MenuItem>
                                    </Select>
                                </div>


                                <hr/>

                            </div>


                            <div className="form-footer-container">

                                <button className="btn btn-secondary"
                                        onClick={() => props.setOpen(false)}>CANCEL
                                </button>
                                <button className="btn btn-primary"
                                        type='submit'>{(userToEdit) ? 'UPDATE' : 'ADD'}</button>

                            </div>

                        </ValidatorForm>

                    </div>

                </Fade>
            </Modal>
        </div>


    );
}


const mapStateToProps = (state) => {
    const userToEdit = state.users.userToEdit;
    const listClients = getListClient(state);
    return {userToEdit, listClients};

};


export default connect(mapStateToProps,
    {addUser, updateUser})(AddEditModal);


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));