import React, {useRef, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {closeModal} from "../../../utils/icons";
import {ValidatorInput} from "../../../components";
import {ValidatorForm} from "react-material-ui-form-validator";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {connect} from "react-redux";
import {addClient, updateClient} from "../../../redux/actions";


const initDate = {
    clientName: '',
    status: 1,
}

function AddEditModal(props) {
    const classes = useStyles();
    const {open, setOpen, addClient, clientToEdit, updateClient,} = props;


    const handleClose = () => {
        setOpen(false);
    };


    const [formData, setFormData] = useState(clientToEdit ? clientToEdit : initDate);
    const clientNameRef = useRef();

    const handleSubmit = () => {


        if (clientToEdit) {

            updateClient(
                {
                    ...clientToEdit,
                    ...formData
                }
            )
        } else
            addClient(formData);

        setOpen(false);

    }


    const handleStatus = (event) => {
        setFormData({...formData, status: event.target.value});
    }


    const handleBlur = event => {
        const {name, value} = event.target;
        if (name == "clientName") {
            // set true as second parameter to onBlur required validation
            clientNameRef.current.validate(value, true);
        }


    }


    return (


        <div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>

                    <div className="form-create-edit-Wrapper">

                        <button className="icon-menu-modal" onClick={() => setOpen(false)}>
                            <img src={closeModal} alt="menu"/>
                        </button>


                        <ValidatorForm className="form-container"
                                       onSubmit={handleSubmit}
                                       instantValidate={false}>


                            <div className={"fields-wrapper"}>


                                <ValidatorInput
                                    ref={clientNameRef}
                                    placeholder={"Client Name"}
                                    value={formData.clientName}
                                    // onChange={handleParent}
                                    onChange={(event) => setFormData({...formData, clientName: event.target.value})}
                                    className="custom-input input-login "
                                    type='text'
                                    name='name'
                                    onBlur={handleBlur}
                                    // validators={ ['required']}
                                    validators={["required"]}
                                    errorMessages={["this field is required"]}
                                />


                                <div className="select-container">
                                    <InputLabel id="simple-select-label" className="label">Active</InputLabel>
                                    <Select
                                        labelId="simple-select-label"
                                        id="simple-select"
                                        className="select"
                                        value={formData.status}
                                        onChange={handleStatus}
                                    >
                                        <MenuItem className="menu-item-status" value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </div>


                                <hr/>

                            </div>


                            <div className="form-footer-container">

                                <button className="btn btn-secondary"
                                        onClick={() => props.setOpen(false)}>CANCEL
                                </button>
                                <button className="btn btn-primary"
                                        type='submit'>{(clientToEdit) ? 'UPDATE' : 'ADD'}</button>

                            </div>

                        </ValidatorForm>

                    </div>

                </Fade>
            </Modal>
        </div>


    );
}


const mapStateToProps = (state) => {

    const clientToEdit = state.clients.clientToEdit;

    return {clientToEdit};

};


export default connect(mapStateToProps,
    {addClient, updateClient})(AddEditModal);


const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));