import {
    ANNOTATION_ADD_SUCCESSFULLY,
    ANNOTATION_INIT,
    ANNOTATION_REMOVE_SUCCESSFULLY, ANNOTATION_REQUEST, ANNOTATION_REQUEST_ERROR,
    ANNOTATION_SET_SUCCESSFULLY, ANNOTATION_UPDATE_POSITION_SUCCESSFULLY,
    ANNOTATION_UPDATE_SUCCESSFULLY
} from "../constants";


export default function reducer(state = {

    "listAnnotation": null,
    "loading": false, // use in mitigation and risk upload


}, {type, payload}) {

    switch (type) {


        case ANNOTATION_INIT: {

            return {
                ...state,
                "listAnnotation": payload,
                loading: false
            }
        }

        case ANNOTATION_SET_SUCCESSFULLY: {


            return {
                ...state,
                listAnnotation: arrayToAnnotationObject(payload),
                loading: false,
            }
        }

        case ANNOTATION_ADD_SUCCESSFULLY: {

            return {
                ...state,
                listAnnotation: {[payload.id]: payload, ...state.listAnnotation},
                loading: false,
            }
        }

        case ANNOTATION_UPDATE_SUCCESSFULLY: {

            // let currentAnnotation = state.listAnnotation[payload.id];


            if (payload) {

                return {
                    ...state,
                    "listAnnotation": {...state.listAnnotation, [payload.id]: {...payload}},
                    loading: false,
                }
            } else
                return {
                    ...state,
                    loading: false,
                }

        }

        case ANNOTATION_UPDATE_POSITION_SUCCESSFULLY: {

            let currentAnnotation = state.listAnnotation[payload.highlightId];

            if (currentAnnotation)
                return {
                    ...state,
                    "listAnnotation": {
                        ...state.listAnnotation,
                        [payload.highlightId]: {
                            ...currentAnnotation,
                            position: {
                                ...currentAnnotation.position,
                                ...payload.position
                            },
                            content: {
                                ...currentAnnotation.content,
                                ...payload.content
                            },
                            createAt: new Date(),
                        }
                    },
                    loading: false,
                }
            else
                return {
                    ...state,
                    loading: false,
                }

        }

        case ANNOTATION_REMOVE_SUCCESSFULLY: {

            delete state.listAnnotation[payload];
            return {
                ...state,
                loading: false
            }
        }


        case ANNOTATION_REQUEST_ERROR: {

            return {
                ...state,
                loading: false,
            }
        }

        case ANNOTATION_REQUEST: {

            return {
                ...state,
                loading: true,
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}


const arrayToAnnotationObject = (list) => Object.assign({}, ...list.map(item => ({[item['id']]: item})));
