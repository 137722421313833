import React from 'react';
import logo from "../../../assets/imgs/intuety-logo.svg";
import {plus, menu, close} from "../../utils/icons";
import {Link} from 'react-router-dom';
import {connect} from "react-redux";
import {changeNav} from "../../redux/actions";
import cookie from "js-cookie";
import {userRole} from "../../utils/funcAux";


const Header = (props) => {


    const {changeNav, menuOpen, openModal, title, buttonRight, buttonRightText, buttonRightFunc} = props;

    const handleClick = (e) => {
        e.preventDefault();
        changeNav();

    }
    const role = cookie.get('role');

    return (

        <header id="nav" className="header-container">
            <nav className="bounds nav">
                <Link to="/">
                    <img src={logo} className="header-logo" alt="logo"/>
                </Link>
                <h2 className="header-title">{title}</h2>
                <div>

                    {(openModal) && role === userRole.author &&
                    <button
                        className="btn-primary btn-icon-left"
                        onClick={openModal}
                    >
                        <span>START A NEW ASSESSMENT</span>
                        <img src={plus} alt="icon-plus"/>
                    </button>
                    }


                    {buttonRight &&
                    <button
                        className="btn-primary save-progress"
                        onClick={buttonRightFunc}
                    >
                        <span>{buttonRightText}</span>

                    </button>
                    }


                    <button
                        className="btn-menu"
                        onClick={handleClick}
                    >
                        <img className="icon-menu" src={(menuOpen) ? close : menu} alt="menu"/>
                    </button>
                </div>
            </nav>

        </header>


    );
}


const mapStateToProps = ({navBar,}) => {
    const {menuOpen} = navBar;
    return {menuOpen};
};

export default connect(mapStateToProps, {changeNav})(Header);

