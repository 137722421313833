import React, {useEffect, useState} from 'react';
import {BackdropLoading, Header, Icon, PaginationTable} from "../../../components";
import {withRouter} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {check, search, times, editAdmin, remove} from "../../../utils/icons";
import TextareaAutosize from 'react-autosize-textarea';
import AddEditModal from "./addEdit";
import DialogBox from "./dialogBox";
import {connect} from "react-redux";
import {getOntologyRowsPerPage, getTotalPages, getListOntology, getNumberTotalOntology} from "../../../redux/selectors";
import {
    setOntologySearch, setRowsPerPage, addOntology, updateChildrenOntology,
    editOntology, setPage, setSortOntology, startOntologies
} from "../../../redux/actions";
import FormatDate from "../../../components/formatDate";

const Ontology = (props) => {


    const {
        listOntologies, rowsPerPage, setRowsPerPage, setSortOntology,
        totalPages, editOntology, page, setPage,
        startOntologies, isLoading, updateChildrenOntology, totalOntologies
    } = props;

    const [showModal, setShowModal] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(0);


    useEffect(() => {
        startOntologies();
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleAddTerm = () => {
        editOntology(null);
        setShowModal(true)
    }

    const updateChildren = (ontologyID, children) => {

        updateChildrenOntology(ontologyID, children);

    }


    return (


        <div className="dashboard-table">

            <Header
                title="Ontology"
                buttonRight
                buttonRightText="Add Term"
                buttonRightFunc={handleAddTerm}
            />

            {showModal && <AddEditModal open={showModal} setOpen={setShowModal}/>}
            {showDialogBox !== 0 && <DialogBox open={showDialogBox} setOpen={setShowDialogBox}/>}
            {<BackdropLoading isLoading={isLoading}/>}

            <div className="main-container fade-in">

                <div className="main-wrapper">


                    <div className="main-container fade-in">


                        <div className="container-table-filters ontology">
                            <div className="filters filter-by-ref">
                                <div className="filter-title">
                                    <span>SEARCH BY</span>
                                </div>
                                <div className="filter-content">
                                    <input type="text"
                                           placeholder={"Type to search"}
                                           onChange={(event) => props.setOntologySearch(event.target.value)}
                                    />
                                    <img className="search" src={search} alt="search-icons"/>

                                </div>

                            </div>

                        </div>


                        <div className="table-wrapper">

                            <Paper className="paper-container">
                                <TableContainer className={"table-container"}>
                                    <Table stickyHeader aria-label="sticky table">
                                        <TableHead>
                                            <TableRow className="table-row-header">
                                                {columns.map((column) => (
                                                    <TableCell
                                                        key={column.id}
                                                        align={column.align}
                                                        style={{minWidth: column.minWidth}}
                                                    >

                                                        {
                                                            column.isButton ?
                                                                <CustomButton
                                                                    _onClick={() => setSortOntology(column.id)}
                                                                    text={column.label}/> :
                                                                column.label

                                                        }

                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>

                                            {listOntologies && listOntologies.map(({ontologyID, parent, children, created, active}) => {

                                                let row = createData(ontologyID, parent, children, created, active);

                                                return (
                                                    <TableRow hover role="checkbox" tabIndex={-1}
                                                              key={`row-onto-${row.ontologyID}`}
                                                              className={!row.active ? 'row removed' : 'row'}>
                                                        {columns.map((column) => {
                                                            const value = row[column.id];
                                                            return (
                                                                <TableCell className={"tableCell-ontology"}
                                                                           key={column.id} align={column.align}>
                                                                    {/*{column.format && typeof value === 'number' ? column.format(value) : value}*/}
                                                                    {
                                                                        column.id === 'edit' ?
                                                                            <ActionsComponent id={row.ontologyID}
                                                                                              editOntology={editOntology}
                                                                                              setShowModal={setShowModal}
                                                                                              setShowDialogBox={setShowDialogBox}
                                                                                              key={`actions-${column.id}`}/> :
                                                                            column.id === 'children' ?
                                                                                <ChildrenComponent
                                                                                    key={`children-${ontologyID}-${value}-${active}`}
                                                                                    value={value}
                                                                                    active={active}
                                                                                    ontologyID={ontologyID}
                                                                                    updateChildren={updateChildren}
                                                                                /> :
                                                                                column.id === 'active' ?
                                                                                    <StatusComponent
                                                                                        key={`active-${column.id}`}
                                                                                        value={value}/> :
                                                                                    column.id === 'created' ?
                                                                                        <FormatDate
                                                                                            key={`created-${column.id}`}
                                                                                            date={value}/> : value
                                                                    }

                                                                </TableCell>
                                                            );
                                                        })}
                                                    </TableRow>
                                                );

                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>


                            </Paper>


                            <PaginationTable
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                totalPages={totalPages}
                                page={page}
                                handleChangePage={handleChangePage}
                                totalItem={totalOntologies}
                            />


                        </div>

                    </div>


                </div>

            </div>


        </div>


    );
}


const mapStateToProps = (state) => {

    const listOntologies = getListOntology(state);
    const rowsPerPage = getOntologyRowsPerPage(state);
    const totalOntologies = getNumberTotalOntology(state);
    const totalPages = getTotalPages(state);
    const ontologyToEdit = state.ontologies.ontologyToEdit;
    const page = state.ontologies.currentPage;
    const isLoading = state.ontologies.loading;
    return {listOntologies, rowsPerPage, totalPages, ontologyToEdit, page, totalOntologies, isLoading};

};


export default connect(mapStateToProps,
    {
        setOntologySearch, setRowsPerPage, addOntology, editOntology,
        setPage, setSortOntology, startOntologies, updateChildrenOntology
    })(withRouter(Ontology));


const columns = [
    {id: 'ontologyID', label: 'ID', minWidth: 100, isButton: true},

    {id: 'parent', label: 'Parent', minWidth: 170, isButton: true},

    {id: 'children', label: 'Children', minWidth: 350, isButton: false},

    {id: 'created', label: 'Created', minWidth: 150, isButton: false},

    {id: 'active', label: '', minWidth: 50, isButton: false},

    {
        id: 'edit',
        label: '',
        minWidth: 100,
        align: 'right',
    },
];

const createData = (ontologyID, parent, children, created, active) => {
    const edit = 4565;
    return {ontologyID, parent, children, created, active, edit};
}


const ActionsComponent = ({id, editOntology, setShowModal, setShowDialogBox}) => {

    const handleEdit = () => {

        editOntology(id);

        setShowModal(true);

    }
    return (
        <div className="actions-container">
            <button className="btn" onClick={handleEdit}>
                <Icon path={editAdmin} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
            <button className="btn" onClick={() => setShowDialogBox(id)}>
                <Icon path={remove} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
        </div>
    );
}


const ChildrenComponent = ({value, active, updateChildren, ontologyID}) => {

    const [text, setText] = useState(value);
    const textarea = React.createRef();

    return (
        //
        <div className="actions-container" style={{opacity: active ? 1 : 0.5}}>

            <TextareaAutosize
                ref={textarea}
                className="input-children"
                value={text}
                onChange={(val) => setText(val.target.value)}
                onBlur={() => updateChildren(ontologyID, text)}
                disabled={!active}
            />
        </div>
    );
}


const StatusComponent = ({value}) => {

    if (value)
        return (<Icon path={check} alt="checked-icon" iconclass={"icon-check"}/>);
    else
        return (<Icon path={times} alt="remove-icon" iconclass={"icon-times-removed"}/>);

}


const CustomButton = ({text, _onClick}) => {
    return (
        <button style={{color: 'white', fontWeight: 500}}
                onClick={_onClick}
        >{text}</button>
    )
}

