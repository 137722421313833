import {

    ADMIN_SET_SEARCH,
    ADMIN_SET_ROW_PER_PAGE,
    ADMIN_SET_FILTER,
    ADMIN_ADD, ADMIN_SET_PAGE,
    ADMIN_EDIT, ADMIN_REMOVE,
    ADMIN_UPDATE, ADMIN_SORT, ADMIN_REQUEST, ADMIN_REQUEST_COMPLETED, ADMIN_REQUEST_ERROR,
} from "../constants";

export default function reducer(state = {

    listAdministrators: null,
    textSearch: '',
    rowsPerPage: 10,
    currentPage: 1,
    administratorToEdit: null,
    names: [],
    organisations: [],
    status: status,
    filterName: '',
    filterOrganisation: 'All',
    filterStatus: 'All',
    loading: false,


    sort: {
        column: null,
        order: null,
    },


}, {type, payload}) {

    switch (type) {


        case ADMIN_REQUEST: {
            return {
                ...state,
                loading: true,
            }
        }
        case ADMIN_REQUEST_ERROR: {
            return {
                ...state,
                loading: false,
            }
        }

        case ADMIN_REQUEST_COMPLETED: {

            return {
                ...state,
                loading: false,
                listAdministrators: payload,
                names: getValue(payload, 'fullName', true),
                organisations: getValue(payload, 'organisation'),
            }
        }


        case ADMIN_ADD: {

            const list = {[payload.userId]: payload, ...state.listAdministrators};
            return {
                ...state,
                loading: false,
                listAdministrators: list,
                names: getValue(Object.values(list, true), 'fullName'),
            }
        }


        case ADMIN_EDIT: {

            return {
                ...state,
                administratorToEdit: state.listAdministrators[payload],
            }
        }

        case ADMIN_REMOVE: {

            delete state.listAdministrators[payload];

            return {
                ...state,
                loading: false,
                names: getValue(state.listAdministrators, 'fullName', true),
            }
        }

        case ADMIN_UPDATE: {

            return {
                ...state,
                listAdministrators: {...state.listAdministrators, [payload.userId]: payload},
                loading: false,
                administratorToEdit: null,
                names: getValue(Object.values({
                    ...state.listAdministrators,
                    [payload.userId]: payload
                }, true), 'fullName'),
                // listOntology:{ [payload.id]:payload, ...state.listOntology, loading: false, ontologyToEdit:null }
            }
        }

        case ADMIN_SET_SEARCH: {

            return {
                ...state,
                ...payload,
            }
        }

        case ADMIN_SET_FILTER: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }


        case ADMIN_SET_ROW_PER_PAGE: {

            return {
                ...state,
                ...payload,
                currentPage: 1,
            }
        }

        case ADMIN_SET_PAGE: {

            return {
                ...state,
                ...payload,
            }
        }


        case ADMIN_SORT:

            let order = 'asc';

            if (payload.sort.column === state.sort.column) {
                if (state.sort.order === 'asc')
                    order = 'desc';

            }

            return {
                ...state,
                sort: {
                    column: payload.sort.column,
                    order: order,
                },
            }

        default: {
            return {
                ...state
            };
        }
    }
}


const getValue = (listObject, field, noAll) => {


    let collection = noAll ? [] : ["All"];

    if (!listObject)
        return collection;

    const list = Object.values(listObject);

    list.forEach((item) => {

        if (collection.filter(value => value === item[field]).length === 0)
            collection.push(item[field]);

    });

    return collection;
}


const status = [
    {key: 'All', value: 'All'},
    // {key: 'all2', value: "'All'"},
    {key: 'ontology', value: 'Ontology'},

];