import React, {useState, useEffect, useCallback} from 'react';
import {DateRange} from 'react-date-range';
import {getShowDateRange, getDateRangeStart, getDateRangeEnd} from "../../redux/selectors";
import {connect} from "react-redux";
import {setShowDateRange, setDateRangeStart, setDateRangeEnd} from "../../redux/actions";

const DataRangeCalendar = (props) => {

    const {dateRangeStart, dateRangeEnd, setShowDateRange, setDateRangeStart, setDateRangeEnd} = props
    const [dateRangeState, setDateRangeState] = useState([
        {
            startDate: dateRangeStart,
            endDate: dateRangeEnd,
            key: 'selection',
        }
    ]);


    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setShowDateRange(false)
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);

        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);


    const handleDateRange = (item) => {
        setDateRangeState([item.selection])
        setDateRangeStart(item.selection.startDate)
        setDateRangeEnd(item.selection.endDate)

        if (item.selection.startDate != item.selection.endDate)
            setShowDateRange(false)
    }


    return (

        <div className={"data-range-container fade-in-500"}>
            <button onClick={() => setShowDateRange(false)}>
                <div className={"data-range-backdrop"}/>


            </button>
            <DateRange
                editableDateInputs={true}
                onChange={item => handleDateRange(item)}
                moveRangeOnFirstSelection={false}
                ranges={dateRangeState}
                showDateDisplay={false}
            />

            <button className={"btn-data-range-reset"}
                    onClick={() => {
                        console.log(dateRangeState);
                        setDateRangeStart(null);
                        const date = new Date();
                        setDateRangeEnd(date)
                        setDateRangeState(
                            [{
                                startDate: null,
                                endDate: date,
                                key: 'selection',
                            }]
                        )
                    }}>Reset
            </button>
        </div>
    );
}


const mapStateToProps = (state) => {

    const showDateRange = getShowDateRange(state);
    const dateRangeStart = getDateRangeStart(state);
    const dateRangeEnd = getDateRangeEnd(state);

    return {showDateRange, dateRangeStart, dateRangeEnd}
}

export default connect(mapStateToProps, {setShowDateRange, setDateRangeStart, setDateRangeEnd})(DataRangeCalendar);

