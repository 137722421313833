import React, {useState} from "react";
import {editAdmin, remove} from "../../utils/icons";
import {Icon} from "../index";
import Moment from 'react-moment';
import TextareaAutosize from 'react-autosize-textarea';
import {useDispatch, useSelector} from "react-redux";
import {removeAnnotation, updateAnnotation} from "../../redux/actions";


const updateHash = highlight => {
    document.location.hash = `highlight-${highlight.id}`;
};

const Sidebar = ({highlights, editable, showSideBar}) => {


    return (
        <div className="sidebar" style={{opacity: showSideBar ? 1 : 0}}>

            <div className="description">

                {(editable && highlights && highlights.length == 0) && <p className={"sidebar-default-text"}>
                    Select a section of text in the document and click the button "add comment" to add a comment.
                </p>
                }

                {editable &&
                <p>
                    <small>
                        To create area highlight hold ⌥ Option key (Alt), then click and
                        drag.
                    </small>
                </p>
                }
            </div>

            <ul className="sidebar__highlights">
                {highlights.map((highlight, index) => (
                    <SidebarItem key={'sidebarItem' + index} highlight={highlight} index={index} editable={editable}/>

                ))}
            </ul>

        </div>
    );

    return null;
}


const SidebarItem = ({highlight, index, editable}) => {

    const [edit, setEdit] = useState(null);
    const textarea = React.createRef();

    const dispatch = useDispatch();

    return (

        <li
            key={index}
            className="sidebar__highlight"
            onClick={() => {
                updateHash(highlight);
            }}
        >
            <div>
                {!edit && <strong>{highlight.comment.text}</strong>}


                {
                    edit && <TextareaAutosize
                        key={highlight.id}
                        autoFocus
                        ref={textarea}
                        className="textarea-edit-comment"
                        value={edit}
                        onChange={(val) => setEdit(val.target.value)}
                        // onBlur ={() =>  updateChildren(ontologyID,text)}
                        // onChange={(val)=>console.log(val)}
                        // disabled = {!active}
                        // onBlur ={() => toastr.success('User was update with success!' )}
                    />
                }


                {!edit && highlight.content.text ? (
                    <blockquote style={{marginTop: "0.5rem"}}>
                        {`${highlight.content.text.slice(0, 90).trim()}…`}
                    </blockquote>
                ) : null}


                {!edit && highlight.content.image ? (
                    <div
                        className="highlight__image"
                        style={{marginTop: "0.5rem"}}
                    >
                        <img src={highlight.content.image} alt={"Screenshot"}/>
                    </div>
                ) : null}


                {
                    <div className={"sidebar-card-footer"}>
                        <i>Updated {
                            <Moment format="DD/MM/YYYY HH:mm">{highlight.createAt}</Moment>
                        }</i> | <strong>{highlight.approvalName ?? ''}</strong>
                    </div>
                }

            </div>

            <div className="highlight-actions">
                <div className="highlight-action">

                    {(editable && !edit) && <>

                        <button
                            onClick={(event) => {
                                dispatch(removeAnnotation(highlight.id));
                                event.preventDefault();
                            }

                            }
                        >
                            <Icon path={remove} alt="checked-icon" iconclass={"icon-check"}/>
                        </button>

                        <button
                            className={"edit"}
                            onClick={(event) => {

                                event.preventDefault();

                                setEdit(edit ? null : highlight.comment.text);
                                // if(textarea)
                                //     textarea.focus();
                            }

                            }
                        >
                            <Icon path={editAdmin} alt="checked-icon" iconclass={"icon-check"}/>
                        </button>

                    </>
                    }


                    {(editable && edit) && <>

                        <button
                            className={"btn-secondary btn"}
                            onClick={(event) => {
                                event.preventDefault();
                                setEdit(null);
                            }

                            }
                        >
                            Cancel
                        </button>

                        <button
                            className={"edit btn-primary btn"}
                            onClick={(event) => {
                                dispatch(updateAnnotation(highlight, edit));
                                setEdit(null);
                                event.preventDefault();
                            }

                            }
                        >
                            Save
                        </button>

                    </>
                    }

                </div>
                <div className="highlight__location">
                    Page {highlight.position.pageNumber}
                </div>
            </div>
        </li>

    );
}


export default Sidebar;
