import React, {useState} from 'react';
import {BackdropLoading, Header} from "../../../components";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import {
    setAdministratorSearch, setAdministratorFilter, setAdministratorRowsPerPage,
    editAdministrator, setSortAdministrator
} from "../../../redux/actions";
import AddEditModal from "./addEdit";
import DialogBox from "./dialogBox";
import AdministratorsTable from "./administratorsTable";

const Administrators = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(0);

    const {editAdministrator, isLoading} = props;


    const handleAddTerm = () => {
        editAdministrator(null);
        setShowModal(true)
    }


    return (


        <div className="dashboard-table">

            <Header
                title="Administrators"
                buttonRight
                buttonRightText="Add Admin"
                buttonRightFunc={handleAddTerm}
            />

            {showModal && <AddEditModal open={showModal} setOpen={setShowModal}/>}
            {showDialogBox !== 0 && <DialogBox open={showDialogBox} setOpen={setShowDialogBox}/>}
            {<BackdropLoading isLoading={isLoading}/>}

            <AdministratorsTable setShowModal={setShowModal} setShowDialogBox={setShowDialogBox}
                                 administrators columns={columns}/>

        </div>


    );
}

const mapStateToProps = (state) => {
    const isLoading = state.administrators.loading;
    return {isLoading};

};

export default connect(mapStateToProps,
    {
        setAdministratorSearch, setAdministratorFilter, setAdministratorRowsPerPage,
        editAdministrator, setSortAdministrator,
    })(withRouter(Administrators));


const columns = [
    {id: 'userId', label: 'ID', minWidth: 100, isButton: true},

    {id: 'fullName', label: 'Name', minWidth: 170, isButton: true},

    {id: 'organisation', label: 'Organisation', minWidth: 170, isButton: true},


    {id: 'roleName', label: 'Role', minWidth: 120, isButton: true},


    {id: 'created', label: 'Created', minWidth: 150, isButton: false},


    {id: 'active', label: '', minWidth: 50, isButton: false},


    {
        id: 'edit',
        label: '',
        minWidth: 100,
        align: 'right',
        // format: (value) => value.toFixed(2),
    },
];

