import React, {useEffect, useState, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import {BackdropLoading, Card, Header, Icon, ProgressAnimated} from "../../components";
import {easeQuadInOut} from "d3-ease";
import {CircleProgress} from "../../components/circleProgress";
import {arrowAltCircleRight, download, edit, editAdmin, remove} from "../../utils/icons";
import UseWindowDimensions from "../../utils/useWindowDimensions";
import CircularProgress from '@material-ui/core/CircularProgress';
import {useDispatch, useSelector} from "react-redux";
import {
    getAnnotations, getActivitiesToPrint,
    getAssessmentSelected,
    getAuthRole, getLoadingAnnotation, getMethodStatementPdfURL, getQualityScore,
    getStateID, isDownloadingFile, isLoadingAssessment, getListRecommendationToDownload,
    isMainLoading, getSafetyBriefingsDownloading
} from "../../redux/selectors";
import {userRole} from "../../utils/funcAux";
import {
    downloadAssessment,
    setAnnotation,
    getAssessment,
    updateStatus, getSafetyBriefing,
    getActivity, getFilesURL, downloadFileSafetyBriefing
} from "../../redux/actions";
import DownloadRecommendationsReport from "../activities/dowloadRecommendationsReport";

import Moment from 'react-moment';
import PDFHighlighter from "../../components/pdfHighlighter/pdfHighlighter";


const MethodStatement = (props) => {


    const [update, setUpdate] = useState(false);
    const dispatch = useDispatch();
    const _userRole = useSelector(state => getAuthRole(state));
    const _isLoadingActivity = useSelector(state => isMainLoading(state));
    const _isLoadingAssessment = useSelector(state => isLoadingAssessment(state));
    const _isLoadingAnnotation = useSelector(state => getLoadingAnnotation(state));
    const _isDownloadingFile = useSelector(state => isDownloadingFile(state));
    const _safetyBriefingsDownloading = useSelector(state => getSafetyBriefingsDownloading(state));


    const {width} = UseWindowDimensions();
    const smallDevice = 585;

    const _assessmentSelected = useSelector(state => getAssessmentSelected(state));
    const _stateID = useSelector(state => getStateID(state));
    const _qualityScore = useSelector(state => getQualityScore(state));
    const _methodStatementPdfURL = useSelector(state => getMethodStatementPdfURL(state));

    const highlighters = useSelector(state => getAnnotations(state));
    const [highlightModal, setHighlightModal] = useState(false);

    const activities = useSelector(state => getActivitiesToPrint(state));

    const listRecommendationBriefings = useSelector(state => getListRecommendationToDownload(state));


    const DownloadPdf = () => {

        return useMemo(
            () => (
                (<DownloadRecommendationsReport/>)
            ),
            [],
        )
    }

    useEffect(() => {

        //riskAssessmentID
        dispatch(getAssessment());
        dispatch(setAnnotation());
        dispatch(getActivity());
        dispatch(getFilesURL());
        dispatch(getSafetyBriefing());

        // return  () => {
        // };


    }, []);


    useEffect(() => {

    }, [update]);


    const isApprover = () => _userRole === userRole.approver;

    const downloadFile = () => {
        if (_assessmentSelected) {
            dispatch(downloadAssessment(_assessmentSelected.upload_riskAssessment, _assessmentSelected.riskAssessmentID));
        }
    }


    const checkLoadingBackdrop = () => _isLoadingActivity || _isLoadingAssessment || _isLoadingAnnotation || _isDownloadingFile;

    const checkLoading = () => _isLoadingActivity || _isLoadingAssessment || _isLoadingAnnotation;


    return (

        <div className="method-statement fade-in">

            <Header title="Method Statement"/>
            <BackdropLoading isLoading={checkLoadingBackdrop()}/>

            {highlighters && <PDFHighlighter highlights={highlighters} highlightModal={highlightModal}
                                             setHighlightModal={setHighlightModal}/>}
            {/*{highlighters && <PDFHighlighter highlights ={highlighters} highlightModal={highlightModal} setHighlightModal ={setHighlightModal} />}*/}


            <div className="method-statement-wrapper ">
                <div className="method-statement-container">
                    <div className="method-statement-header">

                        {/*<h2>Removal of redundant scum plant</h2>*/}

                        <div className={"circle-progress-wrapper"}>
                            <div></div>

                            <div className={"circle-progress-container"}>
                                <ProgressAnimated
                                    valueStart={0}
                                    valueEnd={(checkLoading()) ? 0 : _qualityScore}
                                    duration={1.4}
                                    easingFunction={easeQuadInOut}
                                >
                                    {percentage => (

                                        <CircleProgress
                                            percentage={percentage}
                                            strokeWidth={(width < smallDevice) ? 10 : 13}
                                            width={(width < smallDevice) ? width / 3.5 : 210}
                                            primaryColor={['#14b29e', '#4DAAF1']}
                                            secondaryColor={"#e3e4e2"}

                                            circleElement={(
                                                <MainCircleElement
                                                    percentage={percentage.toFixed()}
                                                    activities={0}
                                                    qualityScore={0}
                                                    mobile={width < smallDevice}
                                                />)}

                                        />

                                    )}
                                </ProgressAnimated>
                                {(width < smallDevice) && <span>Rating Score</span>}
                            </div>
                            <div className={"download-wrapper"}>
                                <div className={"download-container"}>

                                    <button className={"download-item"}
                                            onClick={() => downloadFile()}
                                        // fileName, assessmentId _assessmentSelected.riskAssessmentID
                                    >
                                        <Icon alt={"icon-download"} path={download}/>
                                        <span>Original Risk Assessment</span>
                                    </button>
                                    <div className={"download-item"}>
                                        <Icon alt={"icon-download"} path={download}/>
                                        {(activities) ? <DownloadPdf/> : <span>Loading document...</span>}

                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>

                    <div className="method-statement-body">
                        <Card className={"card-our-analysis"}>
                            <h4>Our Analysis</h4>

                            {(_assessmentSelected && _assessmentSelected.ra_not_ms && _assessmentSelected.ms_not_ra)
                                ?
                                <>
                                    {getListLi(_assessmentSelected.ra_not_ms, "Included in Risk Assessment, but not in Method Statement:")}
                                    {getListLi(_assessmentSelected.ms_not_ra, "Included in Method Statement, but not in Risk Assessment:")}
                                    <div className="ra"/>
                                    <div className="risk-assessment-box"/>
                                </>
                                :
                                <div>{'There is no analysis to display'}</div>
                            }

                        </Card>


                        <Card className={"card-comments"}>
                            <h4>Method Statement Comments</h4>

                            <button
                                className={'btn view-method'}
                                onClick={
                                    () => {

                                        if (_methodStatementPdfURL)
                                            setHighlightModal(true)
                                    }
                                }>
                                <Icon alt={isApprover() ? "icon-edit" : "arrow-al-circle-right"}
                                      path={isApprover() ? edit : arrowAltCircleRight}/>
                                <span>{isApprover() ? 'ADD A COMMENT' : 'VIEW METHOD STATEMENT'}</span>
                            </button>

                            {(!highlighters || highlighters.length == 0) &&
                            <p className={"no-comment"}>No Comments Added</p>}

                            <div className={"list-highlighters"}>
                                {highlighters && highlighters.map((item, index) =>

                                    <HighlightsItem key={'highlightsItem' + index} highlight={item} index={index}/>
                                )}
                            </div>

                        </Card>


                        <Card className={"card-our-analysis"}>
                            <h4>Safety Briefings</h4>

                            {
                                listRecommendationBriefings && listRecommendationBriefings.length > 0 ?
                                    <div className={'block-recommendation-briefings'}>
                                        <ul>{listRecommendationBriefings.map(item =>
                                            <li>
                                                <button className={"download-item"}
                                                        onClick={() => dispatch(downloadFileSafetyBriefing(item.ID, item.Title))}
                                                >
                                                    {
                                                        _safetyBriefingsDownloading === item.ID ?
                                                            <CircularProgress size={18} value={75}
                                                                              className={"circle-progress"}/> :
                                                            <Icon alt={"icon-download"} path={download}
                                                                  className={"icon-download"}/>
                                                    }
                                                    <span className={"btn-title"}>{item.Title}</span>
                                                </button>
                                            </li>)
                                        }
                                        </ul>
                                    </div> :
                                    <div>{'There are no documents to display'}</div>
                            }

                        </Card>

                    </div>

                </div>
            </div>

            <div className="method-statement-footer">

                {_assessmentSelected && isApprover() &&
                <>

                    {(_stateID == "50") && <h4>Would you like to approve this RAMS?</h4>}

                    < div className={"btn-container"}>

                        {(_stateID == "50") ?
                            <>
                                <button className={"btn btn-secondary"}
                                        onClick={
                                            () => dispatch(updateStatus(80, true))
                                        }
                                >REJECT
                                </button>

                                <button className={"btn btn-primary"}
                                        onClick={
                                            () => dispatch(updateStatus(70, true))
                                        }
                                >APPROVE
                                </button>
                            </> :

                            <button className={"btn btn-secondary"}
                                    onClick={
                                        () => props.history.push('/')
                                    }
                            >BACK TO ASSESSMENT LIST
                            </button>
                        }

                    </div>
                </>
                }


                {(_assessmentSelected && _userRole === userRole.author) &&
                <>
                    < div className={"btn-container btn-container-author"}>

                        <button className={"btn btn-secondary"}
                                onClick={
                                    () => props.history.push('/')
                                }
                        >BACK TO ASSESSMENT LIST
                        </button>

                        {_stateID == "90" && <button className={"btn btn-primary"}
                                                     onClick={
                                                         () => {
                                                             dispatch(updateStatus(50, true));
                                                             setUpdate(!update)
                                                         }
                                                     }
                        >SUBMIT FOR APPROVAL
                        </button>}

                    </div>
                </>
                }


            </div>


        </div>

    );

}

export default withRouter(MethodStatement);


const MainCircleElement = ({percentage, activities, qualityScore, mobile, subTitleBold,}) => {

    return (
        <div className={"circle-element"}>

            {percentage && <h1 className={"method-statement"}>{`${percentage}%`}</h1>}
            {!mobile && <span className={"title method"}>Rating Score</span>}

        </div>
    )


}


const HighlightsItem = ({highlight, index}) => {


    return (

        <div
            key={index}
            className="highlight-item"
        >
            <p className={"item-title"}>{highlight.comment.text}</p>

            {highlight.content.text ? (
                <blockquote className={"item-subtitle"}>
                    {`${highlight.content.text.slice(0, 250).trim()}…`}
                </blockquote>
            ) : null}


            {highlight.content.image ? (
                <div
                    className="highlight__image"
                    style={{marginTop: "0.5rem"}}
                >
                    <img src={highlight.content.image} alt={"Screenshot"}/>
                </div>
            ) : null}


            {
                <div className={"sidebar-card-footer"}>
                    <i>Updated {<Moment format="DD/MM/YYYY HH:mm A">{highlight.createAt}</Moment>}</i>
                    <span>{' ' + highlight.approvalName ?? ''}</span>
                </div>
            }


        </div>

    );
}


const getListLi = (listText, title) => {

    let listJson = [];

    try {

        if (!listText)
            return [];

        listJson = JSON.parse(listText);


        const result = (<div className={'our-analysis-list-block'}>
            <div>{title}</div>
            {
                <ul>{listJson.map(item =>
                    <li>{item}</li>)
                }
                </ul>
            }
        </div>);

        return result;
    } catch (e) {
        console.log("error in convert string");
    }

    return <span/>;
}