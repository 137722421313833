import {
    AUTH_START, AUTH_SUCCESS, AUTH_FAIL, AUTH_INITIATE_LOGOUT,
    AUTH_LOGOUT, AUTH_SET_EMAIL, AUTH_RESET_PASSWORD,
} from "../constants";


const initialState = {
    token: null,
    userId: null, //remove later
    error: null,
    fullName: null,
    role: null,
    loading: false,
    authRedirectPath: '/',
    isAuthenticated: null,
    email: null
};


const reducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case AUTH_START:
            return {
                ...state,
                error: null,
                loading: true,
            };

        case AUTH_SUCCESS:

            return {
                ...state,
                ...payload,
                error: null,
                loading: false,
                isAuthenticated: true,
            };

        case AUTH_FAIL:
            return {
                ...state,
                ...payload,
                loading: false
            };

        case AUTH_RESET_PASSWORD:
            return {
                ...state,
                loading: false
            };
        case AUTH_SET_EMAIL:
            return {
                ...state,
                ...payload,

            };

        case AUTH_INITIATE_LOGOUT:
            return {
                ...state,
                loading: true,
            };

        case AUTH_LOGOUT:
            return {
                ...initialState,
                isAuthenticated: false,
            };

        default:
            return state;
    }
};

export default reducer;