import React from 'react';
import PropTypes from 'prop-types';


const Icon = ({path, iconclass, style, alt}) => {

    return (
        <img src={path} className={iconclass} style={style} alt={alt}/>
    );
}

export default Icon;



Icon.propTypes = {
    path: PropTypes.string.isRequired,
    alt : PropTypes.string.isRequired,
    iconclass : PropTypes.string,
    style : PropTypes.object,
}