import {CHANGE_SHOW_NAV} from "../constants";

export function changeNav() {

    return function (dispatch) {

        dispatch(
            {
                type: CHANGE_SHOW_NAV,
            });


    }
}