import {AUTH_LOGOUT, SET_ERROR} from "../constants";
import {toastr} from "react-redux-toastr";
import axios from "../../utils/axios-aurora";
import message from "../../utils/messages";
import {logoutUser} from "./auth";

export function handleError(error) {

    return (dispatch) => {

        if(error.response && error.response.status === 404)
            toastr.error('Error','Url not found');


        if(error.response && error.response.status === 401){
            toastr.error(message.updateOntologiesChildrenError);
            dispatch(logout());
            dispatch(logoutUser())
        }

        if(error.response && error.response.status === 403){
            toastr.error('Session expired please login again!');
            dispatch(logout())
            dispatch(logoutUser());
        }


    }
}


 const logout = () => {
    localStorage.removeItem('token');
    delete axios.defaults.headers.common['Authorization'] ;
    return {
        type: AUTH_LOGOUT
    };
};