import React, {useState} from 'react';
import {BackdropLoading, Header} from "../../../components";
import {withRouter} from 'react-router-dom';
import {connect} from "react-redux";
import { editClient } from "../../../redux/actions";
import AddEditModal from "./addEdit";
import DialogBox from "./dialogBox";
import ClientsTable from "./clientsTable";

const Clients = (props) => {


    const [showModal, setShowModal] = useState(false);
    const [showDialogBox, setShowDialogBox] = useState(0);

    const {editClient, isLoading} = props;

    const handleAddTerm = () => {
        editClient(null);
        setShowModal(true)
    }


    return (


        <div className="dashboard-table">

            <Header
                title="Clients"
                buttonRight
                buttonRightText="Add Client"
                buttonRightFunc={handleAddTerm}
            />

            {showModal && <AddEditModal open={showModal} setOpen={setShowModal}/>}
            {showDialogBox !== 0 && <DialogBox open={showDialogBox} setOpen={setShowDialogBox}/>}
            {<BackdropLoading isLoading={isLoading}/>}

            <ClientsTable setShowModal={setShowModal} setShowDialogBox={setShowDialogBox}
                          administrators columns={columns}/>

        </div>


    );
}

const mapStateToProps = (state) => {
    const isLoading = state.administrators.loading;
    return {isLoading};

};

export default connect(mapStateToProps,
    {
        editClient,
    })(withRouter(Clients));


const columns = [
    {id: 'clientID', label: 'ID', minWidth: 100, isButton: true},

    {id: 'clientName', label: 'Client Name', minWidth: 170, isButton: true},

    // {id: 'organisation', label: 'Organisation', minWidth: 170, isButton: true},


    // {id: 'teamID', label: 'Team', minWidth: 120, isButton: true},


    {id: 'created', label: 'Created', minWidth: 150, isButton: false},


    {id: 'status', label: '', minWidth: 50, isButton: false},


    {
        id: 'edit',
        label: '',
        minWidth: 100,
        align: 'right',
        // format: (value) => value.toFixed(2),
    },
];

