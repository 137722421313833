import {
    ADMIN_INIT,
    ADMIN_SET_SEARCH,
    ADMIN_SET_ROW_PER_PAGE,
    ADMIN_SET_FILTER,
    ADMIN_EDIT, ADMIN_REMOVE,
    ADMIN_UPDATE, ADMIN_ADD,
    ADMIN_SORT,
    ADMIN_SET_PAGE,
    ADMIN_REQUEST, ADMIN_REQUEST_ERROR, ADMIN_REQUEST_COMPLETED,
} from "../constants";
import {toastr} from "react-redux-toastr";
import axios from "../../utils/axios-aurora";
import {arrayToObject} from "../../utils/funcAux";
import {handleError} from './handleHttpError';
import message from "../../utils/messages";

export function startRequest() {
    return {
        type: ADMIN_REQUEST,
    }
}


export function startAdmins() {

    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(startRequest())

        axios.get(`admin/administrators?username=${email}`)
            .then(res => {

                dispatch(requestAdminsSuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


const requestAdminsSuccessfully = (value) => {

    const val = arrayToObject(value, 'userId');

    return {
        type: ADMIN_REQUEST_COMPLETED,
        payload: val,
    }
}


export function addAdministrator(data) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(startRequest());

        axios.post(`admin/administrator/add?username=${email}&organisationID=${data.organisationID}`,
            {
                "email": data.email,
                "password": data.password,
                "fullName": data.fullName,
                "roleID": 1,
                "active": data.active,
                "organisationID": data.organisationID,
            },
        )
            .then(res => {

                dispatch(addAdministratorSuccessfully(res.data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.addAdminError);
                dispatch(requestError());
                dispatch(handleError(error));
            });


    }
}


export function addAdministratorSuccessfully(data) {

    toastr.success(message.addAdminSuccess);

    return {
        type: ADMIN_ADD,
        payload: data,
    }

}


export function editAdministrator(id) {


    return {
        type: ADMIN_EDIT,
        payload: id
    }
}


export function removeAdministrator(id) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(startRequest());

        axios.delete(`admin/administrator/delete/${id}?username=${email}`)
            .then(res => {
                dispatch(removeAdministratorSuccessfully(id));
            })
            .catch(function (error) {
                console.log(error);
                // toastr.error('Error','Error on remove Admin!' );
                dispatch(requestError());
                dispatch(handleError(error));
            });
    }

}


export function removeAdministratorSuccessfully(id) {

    toastr.success(message.removeUserSuccess);

    return {
        type: ADMIN_REMOVE,
        payload: id
    }
}


export function updateAdministrator(data) {


    return (dispatch, getState) => {

        const {email} = getState().auth;
        dispatch(startRequest());

        let body = {
            "email": data.email,
            "password": data.password,
            "fullName": data.fullName,
            "roleID": 1,
            "active": data.active,
        }

        if (!data.password || data.password.trim() == '')
            delete body.password;

        axios.patch(`admin/patchAdministrator/${data.userId}?username=${email}`, body)
            .then(res => {
                dispatch(updateAdministratorSuccessfully(data));
            })
            .catch(function (error) {
                console.log(error);
                toastr.error('Error', message.updateUserError);
                dispatch(requestError());
                // dispatch(handleError(error));
            });
    }

}

export function updateAdministratorSuccessfully(data) {

    toastr.success(message.updateUserSuccess);
    return {
        type: ADMIN_UPDATE,
        payload: data
    }
}


export function setAdministratorSearch(value) {
    return {
        type: ADMIN_SET_SEARCH,
        payload: {textSearch: value}
    }
}

export function setAdministratorRowsPerPage(value) {

    return {
        type: ADMIN_SET_ROW_PER_PAGE,
        payload: {rowsPerPage: value}
    }
}

export function setAdministratorPage(value) {

    return {
        type: ADMIN_SET_PAGE,
        payload: {currentPage: value}
    }
}

export function setAdministratorFilter(type, value) {

    const _value = value === 'All' ? 'All' : value;

    if (type === 'fullName')
        return {
            type: ADMIN_SET_FILTER,
            payload: {filterName: _value}
        }

    if (type === 'organisation')
        return {
            type: ADMIN_SET_FILTER,
            payload: {filterOrganisation: _value}
        }


    return {
        type: ADMIN_SET_FILTER,
        payload: {filterStatus: _value}
    }
}


export const setSortAdministrator = (_column) => {

    return {
        type: ADMIN_SORT,
        payload: {
            sort: {
                column: _column,
            },
        }
    };
};


const requestError = () => {
    return {
        type: ADMIN_REQUEST_ERROR,
    }
}



