export default function reducer(state = {
    data: [],
    navigation: [],
    menuOpen: false,
    loading: true,
    loaded: false,
    pageLoading: true,
    pageLoaded: false,
    error: null,
}, action) {

    switch (action.type) {

        case "SETTING_PAGE": {
            return {
                ...state,
                page: action.payload
            }
        }

        case "GETTING_NAV": {
            return {...state, loading: true}
        }
        case "GOT_NAV": {
            return {
                ...state,
                loading: false,
                loaded: true,
                navigation: action.payload,
            }
        }
        case "SHOW_NAVIGATION": {
            return {...state, menuOpen: true}
        }
        case "HIDE_NAVIGATION": {
            return {...state, menuOpen: false}
        }
        default: {
            return {
                ...state
            };
        }
    }
}