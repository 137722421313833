import {
    ACTIVITY_INIT, ACTIVITY_REQUEST, ACTIVITY_REQUEST_ERROR,
    ACTIVITY_REQUIRE_APPROVED,
    ACTIVITY_SAVED, ACTIVITY_SET,
    ACTIVITY_SET_ACTIVITY_INDEX,
    ACTIVITY_SET_INDEX, ACTIVITY_SET_JOBID,
    ACTIVITY_SET_MITIGATION_INDEX,
    ACTIVITY_SET_RISK_INDEX, ACTIVITY_SET_RISK_USER_ACTION,
    ACTIVITY_SET_USER_ACTION,
    ACTIVITY_UPDATED_LOADING,
    ACTIVITY_UPDATED_SUCCESSFULLY
} from "../constants";


export default function reducer(state = {

    "listActivities": null,
    "riskAssessmentQualityScore": 0,
    "riskAssessmentQualityScoreFinal": 0,
    "activitiesIdentified": 0,
    "activitiesWithSuggestions": 0,
    "approversComments": "",
    "methodStatement": "",
    "author": "Antony Barker",
    "approvers": "",

    "assessmentIndex": 0,
    "riskIndex": 0,
    "index": -1,
    "progressBar": 0,
    "requireApproved": 0,

    "lastActivityIndex": false,
    "lastRiskIndex": false,
    "lastMitigationsIndex": false,

    "updateId": null,
    "updateError": null,
    "loading": false, // use in mitigation and risk upload
    "mainLoading": false, //use in others request like activities request
    "jobIdSelected": null,
    "isProcessed": false,
    "jobIdRequested": null,


}, {type, payload}) {

    switch (type) {

        case ACTIVITY_INIT: {

            const data = localStorage.getItem('listActivities');
            const dataSelected = data ? JSON.parse(data) : {};


            return {
                ...state,
                "listActivities": dataSelected,
                "riskAssessmentQualityScore": 76,
                "riskAssessmentQualityScoreFinal": 96,
                "activitiesIdentified": {},
                "activitiesWithSuggestions": 4,
                "approversComments": "",
                "methodStatement": "",
                "author": "Antony Barker",
                "approvers": "",
                ...getListElement(dataSelected)
            }
        }

        case ACTIVITY_SET: {

            const dataSelected = payload.Activities;

            return {
                ...state,
                "listActivities": dataSelected,
                "riskAssessmentQualityScore": 76,
                "riskAssessmentQualityScoreFinal": 96,
                "activitiesIdentified": dataSelected ? dataSelected.length : 0,
                "activitiesWithSuggestions": payload.numOfActivitiesWhichRequireApproval,
                "approversComments": "",
                "methodStatement": "",
                "author": "Antony Barker",
                "approvers": "",
                "mainLoading": false,
                "loading": false,
                ...getListElement(dataSelected)
            }
        }


        case ACTIVITY_SET_INDEX: {

            return {
                ...state,
                ...payload
            }
        }


        case ACTIVITY_SET_ACTIVITY_INDEX: {


            return {
                ...state,
                ...payload
            }
        }


        case ACTIVITY_SET_RISK_INDEX: {


            return {
                ...state,
                riskIndex: payload.riskIndex
            }
        }

        case ACTIVITY_REQUIRE_APPROVED: {


            return {
                ...state,
                requireApproved: state.requireApproved + 1
            }
        }


        case ACTIVITY_SAVED: {

            return {
                ...state,
                mainLoading: false,

            }
        }

        case ACTIVITY_SET_RISK_USER_ACTION: {

            state.listActivities[state.assessmentIndex].Risks[state.riskIndex].riskUserAction = payload.action;

            return {
                ...state,
            }
        }

        case ACTIVITY_SET_USER_ACTION: {


            if (state.index === -1) {
                const listMitigations = state.listActivities[state.assessmentIndex].Risks[state.riskIndex].Mitigations;

                for (let i = 0; i < listMitigations.length; i++) {
                    listMitigations[i].userAction = payload.action
                }


            } else
                state.listActivities[state.assessmentIndex].Risks[state.riskIndex].Mitigations[state.index].userAction = payload.action;

            return {
                ...state,
            }
        }

        case ACTIVITY_SET_MITIGATION_INDEX: {


            return {
                ...state,
                ...payload
            }
        }


        case ACTIVITY_UPDATED_SUCCESSFULLY: {

            return {
                ...state,
                ...payload
            }
        }

        case ACTIVITY_UPDATED_LOADING: {

            return {
                ...state,
                ...payload
            }
        }

        case ACTIVITY_SET_JOBID: {

            return {
                ...state,
                ...payload
            }
        }

        case ACTIVITY_REQUEST_ERROR: {

            return {
                ...state,
                mainLoading: false,
                loading: false,
            }
        }

        case ACTIVITY_REQUEST: {

            return {
                ...state,
                mainLoading: true,
            }
        }

        default: {
            return {
                ...state
            };
        }
    }
}


const getListElement = (listActivities) => {


    let total = 0;
    let _totalRiskApproved = 0;
    let i, j, k;

    for (i = 0; i < listActivities.length; i++) {
        let _currentActivity = listActivities[i];

        if (!_currentActivity.intuetyToProcess)
            // if(!_currentActivity.intuetyToProcess || _currentActivity.numOfRisksWhichRequireApproval ==0)
            continue;
        // if(i!=_currentActivity)
        //     return{
        //         assessmentIndex:i,
        //         riskIndex:0,
        //         index:-1,
        //         lastActivityIndex:isLastElement(listActivities, i),
        //     }


        _totalRiskApproved = 0;
        for (j = 0; j < _currentActivity.Risks.length; j++) {
            let _currentRisk = _currentActivity.Risks[j];
            let _currentMitigations = _currentActivity.Risks[j].Mitigations;

            // if(_currentRisk.intuetySuggestion==="none")
            if (_currentRisk.numOfMitigationsWhichRequireApproval === 0)
                continue;


            // add to fix multi activities
            if (_currentRisk.riskUserAction == "" && _currentRisk.intuetySuggestion !== "none")
                return {
                    assessmentIndex: i,
                    riskIndex: j,
                    index: -1,
                    lastMitigationsIndex: -1,
                    lastActivityIndex: isLastElement(listActivities, i),
                    lastRiskIndex: isLastElementRisk(_currentActivity.Risks, j),
                    // requireApproved: total,
                    requireApproved: total + _totalRiskApproved,
                    isProcessed: false,
                }


            for (k = 0; k < _currentMitigations.length; k++) {
                let _currentMitigation = _currentMitigations[k];
                if (k == -1)
                    continue;

                if (_currentMitigation.intuetySuggestion === "none")
                    continue;


                if (_currentMitigation.userAction === "") {

                    return {
                        assessmentIndex: i,
                        riskIndex: j,
                        index: (k === 0 && _currentRisk.intuetySuggestion != "none") ? -1 : k,
                        lastMitigationsIndex: isLastElement(_currentMitigations, k),
                        lastActivityIndex: isLastElement(listActivities, i),
                        lastRiskIndex: isLastElementRisk(_currentActivity.Risks, j),
                        requireApproved: total + _totalRiskApproved + ((k === 0 || _currentRisk.intuetySuggestion === "none") ? 0 : 1),
                        isProcessed: false,
                    }
                }

                total += 1;
            }

            _totalRiskApproved += (_currentRisk.intuetySuggestion === "none") ? 0 : 1;

        }

        total += _currentActivity.numOfRisksWhichRequireApproval;

    }


    return {
        assessmentIndex: i,
        riskIndex: j,
        index: k,
        lastMitigationsIndex: true,
        lastActivityIndex: true,
        lastRiskIndex: true,
        isProcessed: true,
        requireApproved: total,

    }

}


const isLastElement = (list, index) => {

    if (!list || list.length == 0 || index === list.length - 1)
        return true;

    for (let i = index + 1; i < list.length; i++) {
        if (list[i].intuetySuggestion != "none")
            return false;
    }
    return true;
}


const isLastElementRisk = (list, index) => {

    if (!list || list.length == 0 || index === list.length - 1)
        return true;

    for (let i = index + 1; i < list.length; i++) {
        if (list[i].numOfMitigationsWhichRequireApproval !== 0)
            return false;
    }

    return true;
}
