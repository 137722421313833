import React, {useState, useEffect, useMemo} from 'react';
import {withRouter} from 'react-router-dom';
import {getActivities, getAssessmentSelected} from "../../redux/selectors";
import {useSelector} from 'react-redux';
import {PDFDownloadLink, Document, Page, View, Text, Image, Font, StyleSheet} from '@react-pdf/renderer';
import logo from "../../../assets/imgs/intuety-logo.png";
import fontRoboto from "../../../assets/fonts/Roboto-Regular.ttf";

const DownloadRecommendationsReport = () => {

    const [show, setShow] = useState(false);
    const activities = useSelector(state => getActivities(state));
    const _assessmentSelected = useSelector(state => getAssessmentSelected(state));


    let timer;

    useEffect(() => {
        timer = setTimeout(() => setShow(true), 500);
        return () => clearTimeout(timer);

    }, []);


    const getAuthorName = () => _assessmentSelected && _assessmentSelected.authorDetails && _assessmentSelected.authorDetails.fullName;

    const getTaskName = () => _assessmentSelected && _assessmentSelected.taskDetails && _assessmentSelected.taskDetails.task;

    const DownloadPdf = () => {


        return useMemo(
            () => (
                (
                    show ?
                        <PDFDownloadLink style={styles.btnDownload} document={<MyDoc/>}
                                         fileName="RecommendationsReport.pdf">
                            {({loading,}) => (loading ? 'Recommendations Report' : 'Recommendations Report')}
                        </PDFDownloadLink>
                        : <span>Recommendations Report</span>
                )
            ),
            [],
        )
    }

    Font.register({family: 'Roboto', src: fontRoboto});

    const MyDoc = () => (

        <Document>
            <Page size="A4" style={{
                flexDirection: 'column',
                backgroundColor: '#f2f3f4',
                paddingTop: 30,
                paddingBottom: 30
            }}>


                {activities && <View>

                    <View style={styles.headerLogo}>
                        {<Image src={logo} style={styles.logoStyle}/>}
                    </View>


                    <View

                        style={
                            styles.headerSection
                        }>

                        <Text style={styles.titleH1}>Recommendations Report</Text>

                        <Text
                            style={styles.titleAuthor}
                        >Author: {getAuthorName()}</Text>
                        <Text
                            style={styles.titleProject}
                        >Task name: {getTaskName()}</Text>
                    </View>


                    {


                        activities.map((activity, index) =>
                            <View
                                style={styles.activitiesColumns}
                                key={'activity' + index}
                            >


                                <View
                                    // wrap={false}
                                    style={styles.cardActivity}
                                >
                                    <Text style={styles.title}>DESCRIPTION OF ACTIVITY</Text>
                                    <Text style={styles.text}>{activity.activity}</Text>
                                </View>


                                <View
                                    style={{width: '100%'}}
                                >

                                    {
                                        activity && activity.Risks.map(({risk, Mitigations, numOfMitigationsWhichRequireApproval}, index) =>
                                            <View
                                                wrap={false}
                                                style={styles.cardRisk}
                                                key={'risk' + index}
                                            >

                                                <View
                                                    style={styles.cardHalf}
                                                >

                                                    <Text style={styles.title}>HAZARD & ASSOCIATED RISK</Text>

                                                    <View
                                                        style={{flexDirection: 'row'}}>
                                                        <View
                                                            style={numOfMitigationsWhichRequireApproval != 0 ? styles.circle : styles.circleRemoved}/>
                                                        <Text style={styles.text}> {risk}</Text>
                                                    </View>

                                                </View>


                                                <View
                                                    style={styles.cardHalf}
                                                >

                                                    <Text style={styles.title}>CONTROL MEASURE</Text>

                                                    {
                                                        Mitigations && Mitigations.map(({description, userAction, intuetySuggestion}, index) =>

                                                                <View
                                                                    key={'mitigation' + index}
                                                                    style={{flexDirection: 'row', marginBottom: 3}}>
                                                                    <View
                                                                        style={(intuetySuggestion != "none" ? (intuetySuggestion === "remove" && userAction === "accept") ? styles.circleRemoved : styles.circle : styles.circleRemoved)}/>
                                                                    <Text
                                                                        style={(intuetySuggestion == "remove" && userAction == "accept") ? styles.textRemoved : styles.text}>{description}{(intuetySuggestion == "remove" && userAction == "accept") ? ' (removed)' : ''}</Text>

                                                                </View>


                                                            // <Text  style={text}>-{description}</Text>
                                                        )
                                                    }
                                                </View>

                                            </View>
                                        )
                                    }

                                </View>
                            </View>)

                    }
                </View>}


            </Page>
        </Document>

    )

    return (

        <div className="results-containere">

            {

                // show ?
                //     <PDFDownloadLink style={styles.btnDownload} document={<MyDoc />} fileName="RecommendationsReport.pdf">
                //         {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Recommendations Report')}
                //     </PDFDownloadLink>
                // : <span>Loading document...</span>
                <DownloadPdf/>

            }

        </div>
    );
}


const styles = StyleSheet.create({
    title: {
        fontSize: 11,
        display: 'block',
        color: '#c86995',
        paddingBottom: 2,

    },
    text: {
        fontSize: 11,
        display: 'block',
        lineHeight: 1.31,
        color: '#2F2E2F',
        fontFamily: 'Roboto'
    },

    textRemoved: {
        fontSize: 11,
        display: 'block',
        lineHeight: 1.31,
        color: '#a3a3a4',
        fontFamily: 'Roboto'
    },
    circle: {
        width: 6,
        height: 6,
        borderRadius: 6,
        backgroundColor: '#5EBFB2',
        // backgroundColor: '#c86995',
        marginTop: 3,
        marginRight: 3,

    },
    circleRemoved: {
        width: 6,
        height: 6,
        borderRadius: 6,
        backgroundColor: '#a3a3a4',
        marginTop: 3,
        marginRight: 3,
    },

    logoStyle: {
        width: 120,
        height: 40,
    },

    headerLogo: {
        paddingLeft: 20,
        paddingTop: 20,
        marginTop: -30,
        backgroundColor: '#ffffff',
        flexDirection: 'row',
        justifyContent: "start",
        alignItems: "center",
    },
    headerSection: {
        paddingRight: 20,
        paddingLeft: 20,
        paddingBottom: 20,
        backgroundColor: '#ffffff',
        justifyContent: "center",
        alignItems: "center",
    },

    titleH1: {
        color: '#4A4A4A',
        fontFamily: 'Roboto',
        paddingBottom: 10
    },

    titleAuthor: {
        color: '#2F2E2F',
        fontSize: 11,
        paddingBottom: 5,
        fontFamily: 'Roboto'
    },

    titleProject: {
        color: '#2F2E2F',
        fontSize: 11,
        fontFamily: 'Roboto'
    },

    cardHalf: {

        marginBottom: 10,
        padding: 8,
        paddingRight: 14,
        width: '49%',
        backgroundColor: '#ffffff',
        border: 'solid 1px #e3e4e2',

    },

    cardRisk: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: "space-between",
    },

    cardActivity: {
        marginBottom: 8,
        padding: 8,
        width: '100%',
        backgroundColor: '#ffffff',
        border: 'solid 1px #e3e4e2',
        // flexGrow: 1,
    },

    activitiesColumns: {
        margin: 10,
        padding: 10,
        flexDirection: 'column',
        // flexGrow: 1,
    },

    btnDownload: {
        marginLeft: 20, fontSize: 15,

        letterSpacing: 0.14,
        color: '#2F2E2F',
        fontFamily: 'Roboto',
        outline: 0,
    }


});


export default withRouter(DownloadRecommendationsReport);






