import React, {useCallback, useEffect, useState} from 'react';
import {DataRangeCalendar, Header, Icon, PaginationTable, Select} from "../../../components";
import {withRouter} from 'react-router-dom';
import {
    getTotalPagesUsers,
    getListUsers,
    getNumberTotalUsers,
} from "../../../redux/selectors";
import {connect} from "react-redux";
import {
    initUsers, setUserSearch, setUserFilter, setUserRowsPerPage,
    editUser, setSortUser, setUserPage, startUsers
} from "../../../redux/actions";

import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {check, search, user, times, editAdmin, remove, client, role} from "../../../utils/icons";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import FormatDate from "../../../components/formatDate";


const UsersTable = (props) => {

    const {
        listUsers, listStatus, listNames, totalPages, rowsPerPage, page,
        setUserRowsPerPage, listClients, editUser, setSortUser, setUserPage,
        setShowDialogBox, setShowModal, startUsers,totalUsers,
    } = props;


    const handleAdministratorFetch = useCallback(async () => {
        await startUsers();
    }, []);


    useEffect(() => {
        handleAdministratorFetch();
    }, []);


    const handleChangePage = (event, newPage) => {
        setUserPage(newPage);
    };

    return (


        <div className="main-container fade-in">

            <div className="container-table-filters ">
                <div className="filters filter-by-ref">
                    <div className="filter-title">
                        <span>SEARCH BY</span>
                    </div>
                    <div className="filter-content">
                        <input type="text"
                               placeholder={"Type to search"}
                               onChange={(event) => props.setUserSearch(event.target.value)}
                        />
                        <img className="search" src={search} alt="search-icons"/>

                    </div>

                </div>

                <div className="filters filter-by-name">

                    <div className="filter-title">
                        <img className="search" src={user} alt="user-icons"/>
                        <span>name</span>
                    </div>
                    <div className="filter-content">

                        <Autocomplete
                            id="free-solo-filter"
                            className={"autocomplete-filter"}
                            freeSolo
                            options={listNames && listNames.map((option) => option)}
                            onChange={(event, value) => props.setUserFilter('fullName', value)}

                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Name"
                                    className={"textField-filter"}
                                    size={"small"}
                                    margin="normal"
                                    variant="outlined"
                                    // onChange={(event)=>props.setAdministratorFilter('name', event.target.value)}
                                />
                            )}
                        />

                    </div>

                </div>

                <div className="filters filter-by-status">

                    <div className="filter-title">
                        <img className="search" src={client} alt="client-icons"/>
                        <span>client</span>
                    </div>
                    <div className="filter-content">
                        <Select list={listClients}
                                value={props.filterClient}
                                type="client"
                                handleChange={props.setUserFilter}
                        />

                    </div>


                </div>


                <div className="filters filter-by-date">

                    <div className="filter-title">
                        <img className="search" src={role} alt="arrowsAlt-icons"/>
                        <span>role</span>
                    </div>
                    <div className="filter-content">
                        {/*<span>All</span>*/}
                        {/*<img src={arrowDown} alt="search-icons"/>*/}
                        <Select list={listStatus}
                                value={props.filterRole}
                                type="status"
                                handleChange={props.setUserFilter}
                                keyValue
                        />

                    </div>


                </div>

            </div>


            <div className="table-wrapper">

                <Paper className="paper-container">
                    <TableContainer className={"table-container"}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow className="table-row-header">
                                    {columns.map((column) => (
                                        <TableCell
                                            key={column.id}
                                            align={column.align}
                                            style={{minWidth: column.minWidth}}
                                        >

                                            {
                                                column.isButton ?
                                                    <CustomButton _onClick={() => setSortUser(column.id)}
                                                                  text={column.label}/> :
                                                    column.label

                                            }
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {/*{listUsers && listUsers.slice( (page -1) * rowsPerPage, page * rowsPerPage ).map(({id, name, client, role, created, status}) => {*/}
                                {listUsers && listUsers.map(({userId, fullName, clientName, roleName, created, active}) => {


                                    let row = createData(userId, fullName, clientName, roleName, created, active);
                                    return (
                                        <TableRow hover role="checkbox" tabIndex={-1} key={row.userId}
                                                  className={row.active ? 'row' : 'row removed'}>
                                            {columns.map((column) => {
                                                const value = row[column.id];
                                                return (
                                                    <TableCell key={column.id} align={column.align}>
                                                        {/*{column.format && typeof value === 'number' ? column.format(value) : value}*/}
                                                        {column.id === 'edit' ?
                                                            <ActionsComponent id={row.userId} editUser={editUser}
                                                                              setShowModal={setShowModal}
                                                                              setShowDialogBox={setShowDialogBox}
                                                                              key={`actions-${column.id}`}/> :
                                                            column.id === 'active' ?
                                                                <StatusComponent
                                                                    key={`status-${column.id}`}
                                                                    value={value}/> :

                                                                column.id === 'created' ?
                                                                    <FormatDate
                                                                        key={`created-${column.id}`}
                                                                        date={value}/> :

                                                                    value}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </Paper>

                <PaginationTable
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setUserRowsPerPage}
                    totalPages={totalPages}
                    page={page}
                    totalItem={totalUsers}
                    handleChangePage={handleChangePage}
                />


            </div>

        </div>


    );
}


const mapStateToProps = (state) => {
    const listUsers = getListUsers(state);
    const listStatus = state.users.status;
    const listClients = state.users.clientsListName;
    const listNames = state.users.names;
    const filterRole = state.users.filterRole;
    const filterName = state.users.filterName;
    const filterClient = state.users.filterClient;
    const rowsPerPage = state.users.rowsPerPage;
    const page = state.users.currentPage;
    const totalPages = getTotalPagesUsers(state);
    const totalUsers = getNumberTotalUsers(state);


    return {
        listUsers, listStatus, filterRole, listNames, filterName, filterClient,
        rowsPerPage, page, totalPages, listClients, totalUsers
    };
};


export default connect(mapStateToProps,
    {
        initUsers, setUserSearch, setUserFilter, setUserRowsPerPage,
        editUser, setSortUser, setUserPage, startUsers
    })(withRouter(UsersTable));


function createData(userId, fullName, clientName, roleName, created, active) {
    const edit = 4565;


    return {userId, fullName, clientName, roleName, created, active, edit};
}


const CustomButton = ({text, _onClick}) => {
    return (
        <button style={{color: 'white', fontWeight: 500}}
                onClick={_onClick}
        >{text}</button>
    )
}

const ActionsComponent = ({id, editUser, setShowModal, setShowDialogBox}) => {

    const handleEdit = () => {
        editUser(id);
        setShowModal(true);
    }

    return (
        <div className="actions-container">
            <button className="btn" onClick={handleEdit}>
                <Icon path={editAdmin} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
            <button className="btn" onClick={() => setShowDialogBox(id)}>
                <Icon path={remove} alt="checked-icon" iconclass={"icon-check"}/>
            </button>
        </div>
    );
}


const StatusComponent = ({value}) => {

    if (value)
        return (<Icon path={check} alt="checked-icon" iconclass={"icon-check"}/>);
    else
        return (<Icon path={times} alt="remove-icon" iconclass={"icon-times-removed"}/>);

}


const columns = [
    {id: 'userId', label: 'ID', minWidth: 100, isButton: true},

    {id: 'fullName', label: 'Name', minWidth: 170, isButton: true},

    {id: 'clientName', label: 'Client', minWidth: 170, isButton: true},


    {id: 'roleName', label: 'Role', minWidth: 120, isButton: true},


    {id: 'created', label: 'Created', minWidth: 150, isButton: false},


    {id: 'active', label: '', minWidth: 50, isButton: false},


    {
        id: 'edit',
        label: '',
        minWidth: 100,
        align: 'right',
        // format: (value) => value.toFixed(2),
    },
];
