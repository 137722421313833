import {
    ONTOLOGY_INIT,
    ONTOLOGY_SET_SEARCH,
    ONTOLOGY_SET_ROW_PER_PAGE,
    ONTOLOGY_ADD,
    ONTOLOGY_UPDATE,
    ONTOLOGY_EDIT,
    ONTOLOGY_SET_PAGE,
    ONTOLOGY_REMOVE, ONTOLOGY_SORT,
    ONTOLOGY_REQUEST,
    ONTOLOGY_REQUEST_COMPLETED,
    ONTOLOGY_REQUEST_ERROR,
    ONTOLOGY_UPDATE_CHILDREN
} from "../constants";

import dataJson from "../../utils/ontologies";

export default function reducer(state = {

    listOntology: null,
    textSearch: '',
    rowsPerPage: 10,
    ontologyToEdit: null,
    loading: false,
    currentPage: 1,
    sort: {
        column: null,
        order: null,
    },


}, {type, payload}) {

    switch (type) {

        case ONTOLOGY_INIT: {

            return {
                ...state,
                listOntology: dataJson,
            }
        }

        case ONTOLOGY_REQUEST: {

            return {
                ...state,
                loading: true,

            }
        }

        case ONTOLOGY_REQUEST_COMPLETED: {

            return {
                ...state,
                loading: false,
                listOntology: payload,
            }
        }

        case ONTOLOGY_REQUEST_ERROR: {

            return {
                ...state,
                loading: false,

            }
        }


        case ONTOLOGY_ADD: {

            // let id=genId();
            // payload.ontologyID=id;

            return {
                ...state,
                listOntology: {[payload.ontologyID]: payload, ...state.listOntology,},
                loading: false,
            }
        }

        case ONTOLOGY_EDIT: {

            return {
                ...state,
                ontologyToEdit: state.listOntology[payload]
            }
        }

        case ONTOLOGY_REMOVE: {

            delete state.listOntology[payload];

            return {
                ...state,
                loading: false,
            }
        }

        case ONTOLOGY_UPDATE: {

            return {
                ...state,
                listOntology: {...state.listOntology, [payload.ontologyID]: payload},
                loading: false,
                ontologyToEdit: null
                // listOntology:{ [payload.id]:payload, ...state.listOntology, loading: false, ontologyToEdit:null }
            }
        }

        case ONTOLOGY_UPDATE_CHILDREN: {

            return {
                ...state,
                // listOntology:{ ...state.listOntology, [payload.ontologyID]:payload  },
                loading: false, ontologyToEdit: null
            }
        }

        case ONTOLOGY_SET_SEARCH: {

            return {
                ...state,
                ...payload,
            }
        }

        case ONTOLOGY_SET_PAGE: {

            return {
                ...state,
                currentPage: payload,
            }
        }

        case ONTOLOGY_SET_ROW_PER_PAGE: {

            return {
                ...state,
                ...payload,
            }
        }

        case ONTOLOGY_SORT:

            let order = 'asc';

            if (payload.sort.column === state.sort.column) {
                if (state.sort.order === 'asc')
                    order = 'desc';

            }

            return {
                ...state,
                sort: {
                    column: payload.sort.column,
                    order: order,
                },
            }

        default: {
            return {
                ...state
            };
        }
    }
}


const genId = () => Math.ceil(Math.random() * 1000000);

const gen4 = () => Math.random().toString(16).slice(-4);
