import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export default class Input extends React.Component {


    render() {
        /* eslint-disable no-unused-vars */
        const {
            onBlur,
            placeholder,
            className,
            value,
            type,
            handle,
            ...rest
        } = this.props;


        return (
            <TextField
                {...rest}

                label={placeholder}
                type={type ?? 'text'}
                variant="filled"
                // onChange={handle}
                fullWidth
                size={"small"}
                className={`custom-input ${className}`}
                onBlur={onBlur}
            />
        );
    }
}

Input.propTypes = {
    type: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    className: PropTypes.string,
    handle: PropTypes.func,

}