import React from 'react';
import './App.scss';
import {Provider} from 'react-redux'
import store from "./js/redux/store";
import AppWrapper from "./AppWrapper";

function App() {
  return (


          <Provider store={store}>
              <AppWrapper/>
          </Provider>

  );
}


export default App;


